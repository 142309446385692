import * as React from 'react'
import cx from 'classnames'
import { ExpandMoreIcon } from '../../Icon'
import { IconProps } from '../../IconWrapper'
import { t, loadStrings } from '../../defaultStrings'

export interface ExpandIconProps extends IconProps {
  isExpanded?: boolean
}

export const ExpandIcon = React.forwardRef(function ExpandIcon(
  { isExpanded, className, ...props }: ExpandIconProps,
  ref?: React.ForwardedRef<HTMLElement>
) {
  loadStrings()
  return (
    <ExpandMoreIcon
      ref={ref}
      className={cx(
        'transform transition-transform',
        'duration-300 ease-out-quart',
        {
          'rotate-180': isExpanded
        },
        className
      )}
      aria-label={
        props.accessibility === 'decorative' ? undefined : t('open-close')
      }
      {...props}
    />
  )
})
