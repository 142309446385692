import * as React from 'react'
import { useState } from 'react'
import orderBy from 'lodash/orderBy'
import { Button } from '@toasttab/buffet-pui-buttons'
import { Modal } from '@toasttab/buffet-pui-modal'
import { useTranslation } from '@local/translations'
import { DividingLine } from '@toasttab/buffet-pui-config-templates'
import { CheckboxGroup } from '@toasttab/buffet-pui-checkbox'
import { useAddEmployeeContext } from '../../hooks'
import { useFormikContext } from 'formik'
import { EmployeeValues } from '../combined-values'
import { OnboardingDocument } from './onboarding-types'

type Props = {
  allDocuments: OnboardingDocument[]
  openModalButtonLabel: String
  modifySelectedDocuments: (documents: OnboardingDocument[]) => void
}

const OnboardingDocumentsModal = (props: Props) => {
  const { allDocuments, openModalButtonLabel, modifySelectedDocuments } = props
  const { t } = useTranslation()
  const { displayName } = useAddEmployeeContext()
  const [isOpen, setIsOpen] = useState(false)

  const { values } = useFormikContext<EmployeeValues>()
  const { selectedDocuments } = values

  const [shownDocuments, setShownDocuments] =
    useState<OnboardingDocument[]>(allDocuments)

  const closeModal = () => setIsOpen(false)
  const openModal = () => {
    //Prepopulate the checked list with the values in the default documents list
    const initializedList = allDocuments.map((d) => {
      const selected = selectedDocuments.find((sd) => sd.id === d.id)
      if (selected) {
        return { ...selected }
      }
      return { ...d }
    })
    setShownDocuments(initializedList)
    setIsOpen(true)
  }

  const onClickDocument = (e: React.ChangeEvent<HTMLInputElement>) => {
    const clickedId = e.target.value
    const clickedCheck = e.target.checked
    setShownDocuments(
      shownDocuments.map((d) => {
        if (d.id === clickedId) {
          return {
            ...d,
            checked: clickedCheck
          }
        }
        return { ...d }
      })
    )
  }

  const onSubmitDocument = () => {
    modifySelectedDocuments(
      shownDocuments.map((d) => {
        return {
          ...d,
          touched: d.touched ? d.touched : d.checked
        }
      })
    )
    closeModal()
  }

  const checkboxGroupOptions = orderBy(
    shownDocuments,
    ['assignedByPosition', 'name'],
    ['asc', 'asc']
  ).map((doc) => {
    const value = doc.id
    const label = doc.name
    const checked = doc.checked
    return { value: `${value}`, label, checked }
  })

  return (
    <>
      <Modal
        isOpen={isOpen}
        onRequestClose={closeModal}
        size='xl'
        testId='modify-documents-modal'
      >
        <Modal.Header headingId='modal_heading'>
          <p>{t('selectOnboardingDocuments')}</p>
          <p className='pt-2 font-normal type-subhead text-secondary'>
            {t('onboardingDocumentsSubHeaderModal', { name: displayName })}
          </p>
        </Modal.Header>
        <Modal.Body>
          <DividingLine />
          <CheckboxGroup
            testId='modifiedDocuments'
            multiple
            className='mb-4 ml-2 checkbox-font-normal'
            onChange={onClickDocument}
            options={checkboxGroupOptions}
          />
          <DividingLine />
        </Modal.Body>
        <Modal.Footer>
          <span className='w-full text-secondary'>
            {t('numberSelected', {
              number: shownDocuments.filter((d) => d.checked).length
            })}
          </span>
          <Button
            className='flex-grow sm:flex-none'
            variant='link'
            onClick={closeModal}
          >
            {t('cancel')}
          </Button>
          <Button
            className='flex-grow sm:flex-none'
            onClick={() => onSubmitDocument()}
          >
            {t('save')}
          </Button>
        </Modal.Footer>
      </Modal>

      <Button
        testId='modify-documents'
        as='a'
        variant='text-link'
        className={'mb-4'}
        data-toast-track-id='add-employee-modify-document'
        onClick={openModal}
      >
        {openModalButtonLabel}
      </Button>
    </>
  )
}

export { OnboardingDocumentsModal }
