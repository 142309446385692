import * as React from 'react'
import { useTranslation } from '@local/translations'
import { SentIllustration } from '@toasttab/buffet-pui-illustrations'
import { NewHireModal } from '../components'
import { useTracking } from '@local/track'

type Props = {
  onSubmit: () => void
  onClose: () => void
  firstName: string
}

const RehireSuccessModal = (props: Props) => {
  const { onSubmit, onClose, firstName } = props
  const { t } = useTranslation()
  const { track } = useTracking()

  return (
    <NewHireModal
      onSubmit={() => {
        track('add-employee-modal.rehire-submit')
        onSubmit()
      }}
      onClose={() => {
        track('add-employee-modal.rehire-close')
        onClose()
      }}
      illustration={<SentIllustration resizeToContainer className='w-full' />}
      header={t('employeeAddedHeader', { name: firstName })}
      body={t('employeeAddedEmailSubtext', { name: firstName })}
      secondaryButton={t('viewEmployeeProfile', { name: firstName })}
      testId='rehireSuccessModal'
    />
  )
}

export type { Props }
export { RehireSuccessModal }
