import * as React from 'react'
import {
  CheckboxUnselectedIcon,
  CheckboxSelectedIcon,
  CheckboxIndeterminateIcon
} from '@toasttab/buffet-pui-icons'
import {
  SelectionInput,
  SelectionInputProps
} from '@toasttab/buffet-pui-input-base'
import { useUniqueId } from '@toasttab/buffet-utils'
import { Labelled } from '@toasttab/buffet-shared-types'

export type CheckboxProps = Omit<
  SelectionInputProps,
  'type' | 'checkedIcon' | 'uncheckedIcon'
> &
  Labelled & {
    indeterminate?: boolean
  }

export const Checkbox = React.forwardRef<HTMLInputElement, CheckboxProps>(
  ({ testId, checked, indeterminate = false, ...otherProps }, ref) => {
    testId = useUniqueId(testId, 'checkbox-')

    const defaultRef = React.useRef(null)
    const combinedRef = useCombinedRefs(ref, defaultRef)

    React.useEffect(() => {
      if (combinedRef?.current) {
        combinedRef.current.indeterminate = indeterminate ?? false
      }
    }, [combinedRef, indeterminate])

    return (
      <SelectionInput
        testId={testId}
        {...otherProps}
        ref={combinedRef}
        type='checkbox'
        checked={checked}
        uncheckedIcon={
          indeterminate ? (
            <CheckboxIndeterminateIcon
              className='p-2 text-primary-75'
              accessibility='decorative'
            />
          ) : (
            <CheckboxUnselectedIcon accessibility='decorative' />
          )
        }
        checkedIcon={
          indeterminate ? (
            <CheckboxIndeterminateIcon accessibility='decorative' />
          ) : (
            <CheckboxSelectedIcon accessibility='decorative' />
          )
        }
      />
    )
  }
)

const useCombinedRefs = (...refs: any): React.MutableRefObject<any> => {
  const targetRef = React.useRef()

  React.useEffect(() => {
    refs.forEach((ref: any) => {
      if (!ref) return

      if (typeof ref === 'function') {
        ref(targetRef.current)
      } else {
        ref.current = targetRef.current
      }
    })
  }, [refs])

  return targetRef
}

Checkbox.displayName = 'Checkbox'
