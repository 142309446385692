import * as React from 'react'
import { Legend } from '@toasttab/buffet-pui-text-base'

// this label emulates the label from TextInput
// but is a separate component to facilitate form alignment

type LegendProps = React.ComponentProps<typeof Legend>

type Props = {
  name?: string
  required?: boolean
  disabled?: boolean
  helperIconButton?: LegendProps['helperIconButton']
  subLabel?: React.ReactNode
  testId?: string
  children: React.ReactNode
}

const StandaloneLegend = (props: Props) => {
  const {
    disabled, //
    helperIconButton,
    required,
    subLabel,
    testId,
    children
  } = props

  return (
    <Legend
      disabled={disabled}
      helperIconButton={helperIconButton}
      required={required}
      subLabel={subLabel}
      data-testid={testId}
      className='m-0'
    >
      {children}
    </Legend>
  )
}

export { StandaloneLegend }
