import * as React from 'react'
import { ReadonlyText as BuffetReadOnlyText } from '@toasttab/buffet-pui-readonly'

type Props = {
  label: string | React.JSX.Element
  value: string | React.JSX.Element
  children?: any
}

export const ReadonlyText = (props: Props) => {
  const { label, value, children } = props
  return (
    <div>
      <BuffetReadOnlyText
        label={label}
        value={value}
        className={value === '' ? 'mb-4' : ''}
        testId={
          typeof label === 'string'
            ? `${label.replace(/ /g, '').toLowerCase()}-readonly-text`
            : 'documents'
        }
      />
      {children}
    </div>
  )
}
