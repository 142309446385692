import * as React from 'react'
import {
  IllustrationWrapper,
  IllustrationProps
} from '../../IllustrationWrapper'
export const SentIllustration = React.forwardRef(function SentIllustration(
  props: IllustrationProps,
  ref?: React.ForwardedRef<HTMLDivElement>
) {
  const resizeToContainer = props.resizeToContainer
  return (
    <IllustrationWrapper ref={ref} {...props}>
      <>
        {
          <svg
            width={resizeToContainer ? '100%' : '264'}
            height={resizeToContainer ? '100%' : '187'}
            viewBox='0 0 264 187'
            fill='none'
            className='inline-block'
          >
            <path
              d='M191.666 98.643s0 17.67 17.649 17.67c0 0-17.649 0-17.649 17.669 0 0 0-17.669-17.65-17.669.088 0 17.65 0 17.65-17.67zM97.272 3s0 17.67 17.649 17.67c0 0-17.649 0-17.649 17.669 0 0 0-17.67-17.65-17.67 0 0 17.65 0 17.65-17.669z'
              stroke='#FF4C00'
              strokeWidth={4}
              strokeLinecap='round'
              strokeLinejoin='round'
            />
            <path
              d='M91.125 66.909l36.265 14.68 17.737 36.042'
              stroke='#E5E5E5'
              strokeWidth={4}
              strokeLinecap='round'
              strokeLinejoin='round'
            />
            <path
              d='M167.87 39.657L74.09 72.8l44.344 17.933 21.689 44.217 27.747-95.292zM118.434 90.732l49.436-51.075'
              stroke='silver'
              strokeWidth={4}
              strokeLinecap='round'
              strokeLinejoin='round'
            />
            <path
              d='M262 75.26c-.615-5.01-4.829-8.879-10.01-8.879a9.936 9.936 0 00-5.269 1.495c-.878-11.516-10.449-20.57-22.127-20.57-10.537 0-19.318 7.384-21.601 17.23-2.195-1.056-4.566-1.67-7.113-1.67-7.639 0-14.137 5.273-15.981 12.306H262v.088zM123.877 184.089c.615-5.01 4.83-8.878 10.011-8.878 1.931 0 3.688.527 5.268 1.494.878-11.516 10.449-20.57 22.128-20.57 10.537 0 19.317 7.384 21.601 17.23 2.195-1.055 4.566-1.67 7.112-1.67 7.639 0 14.137 5.274 15.981 12.307h-82.101v.087zM2 56.975h67.7c-1.844-7.12-8.342-12.395-16.069-12.395-2.546 0-5.005.616-7.2 1.67-2.283-10.02-11.152-17.405-21.776-17.405-6.147 0-12.118 2.55-16.333 7.032C2.966 41.68 2.44 49.415 2 56.975zM87.788 102.423s-20.108 10.549-38.81.264M106.931 160.179s-10.537-20.131-.264-38.856M99.994 109.104L62.148 147.52'
              stroke='#E5E5E5'
              strokeWidth={4}
              strokeLinecap='round'
              strokeLinejoin='round'
            />
          </svg>
        }
      </>
    </IllustrationWrapper>
  )
})
