import { Currency } from '@toasttab/buffet-pui-types'

export { Currency }

export const currencies = {
  CA: Currency.CAD,
  IE: Currency.EUR,
  GB: Currency.GBP,
  US: Currency.USD,
  JP: Currency.JPY
}
