/*
  This validation function and regex is based on research into various solutions and some of our production data:
  https://toasttab.atlassian.net/wiki/spaces/GCLW/pages/1439367841/Email+validation
*/
export const EMAIL_REGEX: RegExp =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

export function isValidEmail(email?: string): boolean {
  if (!email || email.length > 256 || !EMAIL_REGEX.test(email)) return false

  // Further checking of some things regex can't handle
  const [account, domain] = email.split('@')
  if (account.length > 64) return false

  const hasBadDomain = domain.split('.').some((p) => p.length > 63)
  return !hasBadDomain
}
