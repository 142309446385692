import * as React from 'react'
import { DatePicker as BuffetDatePicker } from '@toasttab/buffet-pui-date-picker'
import { isToday } from 'date-fns'
import { useField } from 'formik'

type Props = Omit<React.ComponentProps<typeof BuffetDatePicker>, 'onChange'> & {
  fieldName: string
  dateLegendLabel: string
  onChange?: (date: string | undefined) => void
}

const DatePicker = (props: Props) => {
  const {
    dateLegendLabel,
    fieldName,
    testId,
    errorText,
    invalid,
    name,
    onChange,
    ...rest
  } = props

  const [, meta, helpers] = useField(fieldName || '')

  const { error, value, touched } = meta

  const displayError = errorText ?? touched ? error : undefined

  const displayInvalid = invalid ?? touched ? !!error : false

  return (
    <BuffetDatePicker
      {...rest}
      testId={testId || fieldName}
      containerClassName={'w-full mb-4 md:w-80 md:mb-6'}
      modifiersClassNames={{
        today: isToday(new Date(value))
          ? ''
          : 'border border-primary-75 border-dashed'
      }}
      name={name || fieldName || ''}
      invalid={displayInvalid}
      errorText={displayError}
      value={new Date(value)}
      components={{
        Legend: () => (
          <div className='flex flex-row flex-wrap type-caption mx-6 mt-3.5 pb-4 mb-4 border-b'>
            <div className='inline-block w-4 h-4 align-middle border border-dashed rounded-full border-primary-75' />
            <div className='text-secondary ml-1.5'>{dateLegendLabel}</div>
          </div>
        )
      }}
      onChange={(dateValue) => {
        if (fieldName) helpers.setValue(dateValue?.toString())
        if (onChange) onChange(dateValue?.toString())
      }}
      size={'lg'}
    />
  )
}

export default DatePicker
