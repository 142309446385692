import * as React from 'react'
import cx from 'classnames'
import { IconWrapper, IconProps, sizes } from '../../IconWrapper'
export const CopyIcon = React.forwardRef(function CopyIcon(
  props: IconProps,
  ref?: React.ForwardedRef<HTMLElement>
) {
  return (
    <IconWrapper ref={ref} {...props}>
      <>
        {
          <svg
            width={24}
            height={24}
            viewBox='0 0 24 24'
            fill='none'
            className={cx(
              'inline-block align-middle',
              sizes[props.size || 'sm'].className
            )}
          >
            <path
              d='M19.096 19.15h-.75v.037l.004.038.746-.075zm-14.95-3.8a.75.75 0 001.5 0h-1.5zm9.25-11.55a.75.75 0 000-1.5v1.5zm3.9 16.5h-6.8v1.5h6.8v-1.5zm-6.8 0c-.66 0-1.15-.538-1.15-1.15h-1.5c0 1.388 1.11 2.65 2.65 2.65v-1.5zm-1.15-1.15V8.85h-1.5v10.3h1.5zm0-10.3c0-.66.538-1.15 1.15-1.15V6.2c-1.388 0-2.65 1.11-2.65 2.65h1.5zm1.15-1.15h6.7V6.2h-6.7v1.5zm6.7 0c.66 0 1.15.538 1.15 1.15h1.5c0-1.388-1.11-2.65-2.65-2.65v1.5zm1.15 1.15v10.3h1.5V8.85h-1.5zm.004 10.375c.05.512-.45 1.075-1.054 1.075v1.5c1.396 0 2.695-1.237 2.546-2.725l-1.492.15zM5.646 15.35V4.95h-1.5v10.4h1.5zm0-10.4c0-.66.538-1.15 1.15-1.15V2.3c-1.388 0-2.65 1.11-2.65 2.65h1.5zm1.15-1.15h6.6V2.3h-6.6v1.5z'
              fill='currentColor'
            />
          </svg>
        }
      </>
    </IconWrapper>
  )
})
