import * as React from 'react'
import cx from 'classnames'
import { Checkbox } from '../Checkbox'
import { useUniqueId } from '@toasttab/buffet-utils'
import { TestIdentifiable } from '@toasttab/buffet-shared-types'
import {
  HelperTextProps,
  LabeledHelperTextProps
} from '@toasttab/buffet-pui-text-base'
import {
  SelectionGroup,
  SelectionGroupProps,
  inlineSelectionItemsClasses
} from '@toasttab/buffet-pui-input-base'

export interface CheckboxGroupProps
  extends TestIdentifiable,
    HelperTextProps,
    LabeledHelperTextProps,
    Omit<SelectionGroupProps, 'children' | 'onChange'> {
  onChange?: React.ChangeEventHandler<HTMLInputElement>
  inline?: boolean
  options?: Array<{
    label: React.ReactNode
    subLabel?: string
    value: string
    checked?: boolean
    disabled?: boolean
    /** Deprecated: Please instead ensure that you are using unique values (we use the value as key by default) */
    key?: string
  }>
}

export const CheckboxGroup = (props: CheckboxGroupProps) => {
  const {
    options = [],
    disabled,
    testId,
    onChange,
    inline,
    itemsContainerClassName,
    ...restProps
  } = props
  const uniqueTestId = useUniqueId(testId, 'checkbox-group-')
  const derivedDisabled = options.every((option) => option.disabled) || disabled
  return (
    <SelectionGroup
      disabled={derivedDisabled}
      multiple={true}
      {...restProps}
      itemsContainerClassName={cx(
        'my-2',
        inline && inlineSelectionItemsClasses,
        itemsContainerClassName
      )}
      testId={uniqueTestId}
    >
      {options.map((op) => (
        <Checkbox
          label={op.label}
          subLabel={op.subLabel}
          value={op.value}
          checked={op.checked}
          disabled={op.disabled || disabled}
          key={op.key ?? op.value}
          onChange={onChange}
          containerClassName={cx(inline && 'mr-8')}
        />
      ))}
    </SelectionGroup>
  )
}
