import { RoundingMode, BuffetFormatOptions } from '../'

const countDecimals = (value: number) => {
  if (Math.floor(value) === value) return 0
  return value.toString().split('.')[1].length || 0
}

export const roundNumber = (number: number, options: BuffetFormatOptions) => {
  const { maximumFractionDigits, roundingMode, style } = options
  if (isNaN(number) || style === 'percent' || !roundingMode) {
    return number
  }

  const precision =
    maximumFractionDigits !== undefined
      ? maximumFractionDigits
      : countDecimals(number)

  // roundOff function is necessary to support rounding methods on big decimals
  // It should be deleted when browsers support rounding formatting options
  return Number(roundOff(number, precision, roundingMode))
}

/**
 *
 * @param input the number to round
 * @param n precision
 * @param mode Rounding Mode
 */
const roundOff = (
  input: number | string | bigint,
  n: number = 0,
  mode: RoundingMode = 'halfExpand'
) => {
  if (typeof input == 'number' || typeof input == 'bigint')
    input = input.toString()

  let neg = false
  if (input[0] === '-') {
    neg = true
    input = input.substring(1)
  }

  let parts = input.split('.'),
    partInt = parts[0],
    partDec = parts[1]

  //handle case of -ve n: roundOff(12564,-2)=12600
  if (n < 0) {
    n = -n
    if (partInt.length <= n) return '0'
    else {
      let prefix = partInt.substring(0, partInt.length - n)
      input = prefix + '.' + partInt.substring(partInt.length - n) + partDec
      prefix = roundOff(input, 0, mode)
      return (neg ? '-' : '') + prefix + new Array(n + 1).join('0')
    }
  }

  // handle case when integer output is desired
  if (n === 0) {
    if (greaterThanFive(parts[1], partInt, neg, mode)) {
      partInt = increment(partInt)
    }
    return (neg && parseInt(partInt) ? '-' : '') + partInt
  }

  // handle case when n>0
  if (!parts[1]) {
    return (neg ? '-' : '') + partInt + '.' + new Array(n + 1).join('0')
  } else if (parts[1].length < n) {
    return (
      (neg ? '-' : '') +
      partInt +
      '.' +
      parts[1] +
      new Array(n - parts[1].length + 1).join('0')
    )
  }

  partDec = parts[1].substring(0, n)
  let rem = parts[1].substring(n)

  if (rem && greaterThanFive(rem, partDec, neg, mode)) {
    partDec = increment(partDec)
    if (partDec.length > n) {
      return (
        (neg ? '-' : '') +
        increment(partInt, parseInt(partDec[0])) +
        '.' +
        partDec.substring(1)
      )
    }
  }
  return (
    (neg && (parseInt(partInt) || parseInt(partDec)) ? '-' : '') +
    partInt +
    '.' +
    partDec
  )
}

const greaterThanFive = (
  part: string,
  pre: string,
  neg: boolean,
  mode: RoundingMode
) => {
  if (!part || part === new Array(part.length + 1).join('0')) return false

  // #region expand, trunc, ceil, floor
  if (
    mode === 'trunc' ||
    (!neg && mode === 'floor') ||
    (neg && mode === 'ceil')
  )
    return false

  if (
    mode === 'expand' ||
    (neg && mode === 'floor') ||
    (!neg && mode === 'ceil')
  )
    return true
  // #endregion

  // case when part !== five
  let five = '5' + new Array(part.length).join('0')
  if (part > five) return true
  else if (part < five) return false

  // case when part === five
  switch (mode) {
    case 'halfTrunc':
      return false
    case 'halfExpand':
      return true
    case 'halfEven':
    default:
      return parseInt(pre[pre.length - 1]) % 2 === 1
  }
}

const increment = (part: number | string, c: number = 0) => {
  const part1 = typeof part == 'number' ? part.toString() : part
  if (!c) c = 1

  let l = part1.length - 1,
    s = ''

  for (let i = l; i >= 0; i--) {
    let x = parseInt(part1[i]) + c
    if (x === 10) {
      c = 1
      x = 0
    } else {
      c = 0
    }
    s += x
  }
  if (c) s += c

  return s.split('').reverse().join('')
}
