import * as React from 'react'
import { useTranslation } from '@local/translations'
import { Modal } from '@toasttab/buffet-pui-modal'
import { Button, ButtonGroup } from '@toasttab/buffet-pui-buttons'
import { Error500MobileIllustration } from '@toasttab/buffet-pui-illustrations'
import { trackModalClicks } from '@local/track'
import { useTracking } from '@local/track'

type Props = {
  onResume: () => void
  onExitAndDiscard: () => void
  isOpen: boolean
}

const ExitModal = (props: Props) => {
  const { onExitAndDiscard, onResume, isOpen } = props

  const { t } = useTranslation()
  const { track } = useTracking()

  return (
    <Modal
      size='sm'
      isOpen={isOpen}
      onRequestClose={() => {
        track('add-employee-modal.exit-resume')
        onResume()
      }}
      className='text-center'
      overflowBehavior='modal'
      testId='exitModal'
    >
      <div className='flex justify-center mb-4'>
        <Error500MobileIllustration resizeToContainer className='w-full' />
      </div>

      <Modal.Header />

      <Modal.Body>
        <div className='mb-3 font-bold type-large text-normal text-default'>
          {t('exitingAddEmployeeHeader')}
        </div>

        {t('exitingAddEmployeeBody')}
      </Modal.Body>
      <Modal.Footer>
        <ButtonGroup
          direction={ButtonGroup.direction.vertical}
          buttonSize='lg'
          className='w-full mt-4'
        >
          <Button
            testId={'status-modal-secondary-button'}
            variant='secondary'
            onClick={onExitAndDiscard}
            {...trackModalClicks('discard-and-close')}
          >
            {t('discardExit')}
          </Button>

          <Button
            testId={'status-modal-primary-button'}
            type='submit'
            onClick={onResume}
            {...trackModalClicks('exit-resume')}
          >
            {t('continue')}
          </Button>
        </ButtonGroup>
      </Modal.Footer>
    </Modal>
  )
}

export type { Props }
export { ExitModal }
