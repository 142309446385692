import * as React from 'react'
import { NumberInput as BuffetNumberInput } from '@toasttab/buffet-pui-text-input'
import { IconButton } from '@toasttab/buffet-pui-buttons'
import { Suffix } from '@toasttab/buffet-pui-text-base'

import { useField } from 'formik'

type IconType = React.ComponentProps<typeof IconButton>['icon']

type BaseProps = Omit<
  React.ComponentProps<typeof BuffetNumberInput>,
  'onChange'
>

type Props = BaseProps & {
  testId?: string
  icon?: IconType
  onIconClick?: () => void
  fieldName: string
  onChange?: (date: string) => void
  maxLength?: number
}

const NumberInput = (props: Props) => {
  const {
    fieldName,
    testId,
    icon,
    onIconClick,
    errorText,
    invalid,
    name,
    prefix,
    currency = false,
    onChange,
    maxLength,
    readOnly,
    ...rest
  } = props

  const [, meta, helpers] = useField(fieldName || '')

  const { error, value, touched } = meta

  const suffix = icon ? (
    <IconButton
      onClick={onIconClick}
      icon={icon}
      contained
      data-testid={`${fieldName}-icon-button`}
    />
  ) : undefined

  const suffixVariant = icon ? Suffix.Variant.iconButton : undefined

  const displayError = errorText ?? touched ? error : undefined

  const displayInvalid = invalid ?? touched ? !!error : false
  const getDisplayValue = (value: string): string => {
    return value && currency ? Number(value).toFixed(2) : value
  }
  return (
    <BuffetNumberInput
      {...rest}
      allowNegative={false}
      readOnly={readOnly}
      testId={testId || fieldName}
      containerClassName={'w-full mb-4 md:w-80 md:mb-6'}
      name={name || fieldName || ''}
      invalid={displayInvalid}
      errorText={displayError}
      suffix={suffix}
      suffixVariant={suffixVariant}
      prefix={prefix}
      value={getDisplayValue(value || '')}
      maxLength={maxLength}
      onChange={(val) => {
        const value = val.value
        if (fieldName) helpers.setValue(value)
        if (onChange) onChange(value)
      }}
      size='lg'
      data-toast-track-id={`add-employee-${fieldName}-input`}
    />
  )
}
export default NumberInput
