import * as React from 'react'
import cx from 'classnames'
import { IconWrapper, IconProps, sizes } from '../../IconWrapper'
export const VisibilityOnIcon = React.forwardRef(function VisibilityOnIcon(
  props: IconProps,
  ref?: React.ForwardedRef<HTMLElement>
) {
  return (
    <IconWrapper ref={ref} {...props}>
      <>
        {
          <svg
            width={24}
            height={24}
            viewBox='0 0 24 24'
            fill='none'
            className={cx(
              'inline-block align-middle',
              sizes[props.size || 'sm'].className
            )}
          >
            <path
              fillRule='evenodd'
              clipRule='evenodd'
              d='M7.245 6.745A10.131 10.131 0 0112 5.56a10.131 10.131 0 018.399 4.46 3.555 3.555 0 010 3.958 10.138 10.138 0 01-16.798 0 3.555 3.555 0 010-3.958c.928-1.374 2.18-2.499 3.644-3.276zM9.687 12a2.313 2.313 0 114.626 0 2.313 2.313 0 01-4.626 0zM12 8.187a3.813 3.813 0 100 7.626 3.813 3.813 0 000-7.626z'
              fill='currentColor'
            />
          </svg>
        }
      </>
    </IconWrapper>
  )
})
