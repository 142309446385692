import { useState } from 'react'

import type { EmployeeValues } from '../pages'

type Args = {
  defaultValues: EmployeeValues
}

const useEmployeeDraft = ({ defaultValues }: Args) => {
  // keep a copy of the parsed employee in react state
  const [
    currentDraftEmployeeValues, //
    setCurrentDraftEmployeeValues
  ] = useState<EmployeeValues | null>(defaultValues)

  const setEmployeeValues = (values: EmployeeValues | null) => {
    setCurrentDraftEmployeeValues(values)
    // const newDraft = convertEmployeeToDraft(values)
  }

  const updateEmployeeValues = (values: Partial<EmployeeValues>) => {
    if (!values) {
      return null
    }

    setCurrentDraftEmployeeValues((prevValues) => {
      const newValues = { ...defaultValues, ...prevValues, ...values }
      return newValues
    })
  }

  return {
    employeeValues: currentDraftEmployeeValues,
    setEmployeeValues,
    updateEmployeeValues
  }
}

export { useEmployeeDraft }
