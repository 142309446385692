import * as React from 'react'
import { useEffect, useState } from 'react'
import { useApplyStyles } from '../FullPageOverlay'

type Props = {
  testId?: string
  isOpen: boolean
  children?: React.ReactNode
}

// transition timings of 0.2s in this file are paired with the WAIT time from `userIsOpen.ts`

const WizardLayoutAnimationLayer = (props: Props) => {
  const { testId, isOpen, children } = props

  useApplyStyles('body', { overflow: 'hidden' })

  const [isMounted, setIsMounted] = useState(false)

  useEffect(() => {
    if (isOpen) {
      setTimeout(() => setIsMounted(true), 0)
    } else {
      setTimeout(() => setIsMounted(false), 200)
    }
  }, [isOpen])

  const isVisible = isOpen && isMounted

  return <>{children}</>
}

export default WizardLayoutAnimationLayer
