import * as React from 'react'
import cx from 'classnames'
import { IconWrapper, IconProps, sizes } from '../../IconWrapper'
export const EmployeeBadgeIcon = React.forwardRef(function EmployeeBadgeIcon(
  props: IconProps,
  ref?: React.ForwardedRef<HTMLElement>
) {
  return (
    <IconWrapper ref={ref} {...props}>
      <>
        {
          <svg
            width={24}
            height={24}
            viewBox='0 0 24 24'
            fill='none'
            className={cx(
              'inline-block align-middle',
              sizes[props.size || 'sm'].className
            )}
          >
            <path
              d='M14 4.637a.75.75 0 000 1.5v-1.5zm-4 1.5a.75.75 0 000-1.5v1.5zm4 1.636v.75a.75.75 0 00.75-.75H14zm-4 0h-.75c0 .415.336.75.75.75v-.75zm4.5 4.024a.75.75 0 100 1.5v-1.5zm3.2 1.5a.75.75 0 100-1.5v1.5zm-3.2 1.284a.75.75 0 000 1.5v-1.5zm4.5 1.5a.75.75 0 100-1.5v1.5zm-5-9.944h6v-1.5h-6v1.5zm6 0c.69 0 1.25.563 1.25 1.239h1.5A2.75 2.75 0 0020 4.636v1.5zm1.25 1.239v11.536h1.5V7.376h-1.5zm0 11.536A1.25 1.25 0 0120 20.15v1.5a2.75 2.75 0 002.75-2.74h-1.5zM20 20.15H4v1.5h16v-1.5zm-16 0a1.25 1.25 0 01-1.25-1.24h-1.5A2.75 2.75 0 004 21.652v-1.5zm-1.25-1.24V7.377h-1.5v11.536h1.5zm0-11.535c0-.676.56-1.24 1.25-1.24v-1.5a2.75 2.75 0 00-2.75 2.74h1.5zM4 6.136h6v-1.5H4v1.5zm10 .887h-4v1.5h4v-1.5zm-3.25.75V4.99h-1.5v2.784h1.5zm0-2.784c0-.676.56-1.239 1.25-1.239v-1.5a2.75 2.75 0 00-2.75 2.739h1.5zM12 3.75c.69 0 1.25.563 1.25 1.239h1.5A2.75 2.75 0 0012 2.25v1.5zm1.25 1.239v2.784h1.5V4.99h-1.5zm-2.3 8.652c0 1.065-.87 1.935-1.95 1.935v1.5a3.443 3.443 0 003.45-3.435h-1.5zM9 15.576c-1.08 0-1.95-.87-1.95-1.935h-1.5A3.443 3.443 0 009 17.076v-1.5zm-1.95-1.935c0-1.065.87-1.935 1.95-1.935v-1.5a3.443 3.443 0 00-3.45 3.435h1.5zM9 11.706c1.08 0 1.95.87 1.95 1.935h1.5A3.443 3.443 0 009 10.206v1.5zM5.15 20.9c0 .035-.014.064.011-.01.025-.075.089-.254.089-.488h-1.5c0-.035.014-.064-.011.01-.025.075-.09.254-.09.488h1.5zm.077-.215c.416-1.654 2.015-2.914 3.773-2.914v-1.5c-2.442 0-4.643 1.723-5.227 4.048l1.454.366zM9 17.772c1.938 0 3.607 1.425 3.856 3.326l1.488-.195c-.351-2.675-2.682-4.63-5.344-4.63v1.5zm5.5-4.475h3.2v-1.5h-3.2v1.5zm0 2.784H19v-1.5h-4.5v1.5z'
              fill='currentColor'
            />
          </svg>
        }
      </>
    </IconWrapper>
  )
})
