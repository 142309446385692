import * as React from 'react'
import { Button, ButtonProps } from '@toasttab/buffet-pui-buttons'
import { ArrowBackIcon, ArrowForwardIcon } from '@toasttab/buffet-pui-icons'

const defaultElement = 'button'
/**
 * Remember to use NavigationContainer when you want to use navigation buttons (BackButton and ContinueButton).
 * It provides consistent styles for navigation bar.
 */
export interface NavigationContainerProps {
  children: React.ReactNode
}

export const NavigationContainer = ({ children }: NavigationContainerProps) => {
  return (
    <div
      className='flex flex-row items-center w-full px-4 py-4 space-x-4 bg-white justify-end'
      data-testid='wizard-navigation-container'
    >
      {children}
    </div>
  )
}

export function BackButton<
  TElementType extends React.ElementType = typeof defaultElement
>({
  testId,
  disabled = false,
  children = 'Back',
  ...props
}: ButtonProps<TElementType>) {
  return (
    <Button
      as={defaultElement}
      testId={testId ? `button-back-${testId}` : 'button-back'}
      className='justify-center w-1/2 md:w-auto'
      disabled={disabled}
      variant={Button.Variant?.link}
      iconLeft={<ArrowBackIcon accessibility='decorative' />}
      {...(props as ButtonProps<TElementType>)}
    >
      {children}
    </Button>
  )
}

export function ContinueButton<
  TElementType extends React.ElementType = typeof defaultElement
>({
  testId,
  disabled = false,
  type = 'button',
  children = 'Continue',
  onClick = undefined,
  ...props
}: ButtonProps<TElementType>) {
  return (
    <Button
      as={defaultElement}
      testId={testId ? `button-continue-${testId}` : 'button-continue'}
      className='w-1/2 md:w-auto'
      type={type}
      disabled={disabled}
      onClick={onClick}
      variant={Button.Variant?.primary}
      iconRight={<ArrowForwardIcon accessibility='decorative' />}
      {...(props as ButtonProps<TElementType>)}
    >
      {children}
    </Button>
  )
}

/**
 * Just a simple wrapper that has the same auto-sizing behavior as BackButton and ContinueButton
 */
export function CompleteButton<
  TElementType extends React.ElementType = typeof defaultElement
>(props: ButtonProps<TElementType>) {
  return (
    <Button
      as={defaultElement}
      className='w-1/2 md:w-auto'
      {...(props as ButtonProps<TElementType>)}
    />
  )
}

export function SkipThisStepButton<
  TElementType extends React.ElementType = typeof defaultElement
>({
  onClick,
  testId = 'button-skip-this-step',
  className,
  children = 'Skip this step',
  ...props
}: ButtonProps<TElementType>) {
  return (
    <Button
      as={defaultElement}
      className={className}
      onClick={onClick}
      size='sm'
      testId={testId}
      variant={Button.Variant?.link}
      iconRight={<ArrowForwardIcon accessibility='decorative' />}
      {...(props as ButtonProps<TElementType>)}
    >
      {children}
    </Button>
  )
}
