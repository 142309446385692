import {
  DefaultStrings,
  DefaultStateStrings,
  CountryIsoCode,
  Currency,
  currencies,
  countryIsoCodes,
  diallingCodes,
  countryNames,
  flags,
  CA_STATES,
  US_STATES,
  checkCountryIsoCode
} from './'

export interface CountryInfo {
  countryIsoCode: CountryIsoCode
  currency: Currency
  diallingCode: string
  Flag: (typeof flags)['US']
  name: string
}

export interface State {
  label: string
  value: string
}

type FlattenedStrings = Record<keyof DefaultStrings, string>

type CountryInfoFunction = (
  strings: FlattenedStrings | DefaultStrings
) => CountryInfo[]

/**
 * countryInfo - Get array of all countries we support with info about each
 * @param {DefaultStrings} strings - A set of country name strings
 * @returns {CountryInfo[]} Array of all country info objects including name, dialling code and flag
 */
export const countryInfo: CountryInfoFunction = (strings) => {
  return countryIsoCodes.map((countryIsoCode) => {
    const countryName = strings[countryNames[countryIsoCode]]
    return {
      countryIsoCode,
      currency: currencies[countryIsoCode],
      diallingCode: diallingCodes[countryIsoCode],
      Flag: flags[countryIsoCode],
      name: typeof countryName === 'string' ? countryName : countryName.message
    }
  })
}

/**
 * getCountry - get a specific country information
 * @param {CountryIsoCode} countryIsoCode - A 2-digit country code
 * @param {DefaultStrings} strings - A set of country name strings
 * @returns {CountryInfo} Country info object including name, dialling code and flag
 */
export const getCountry = (
  countryIsoCode: CountryIsoCode,
  strings: FlattenedStrings | DefaultStrings
) =>
  countryInfo(strings).find(
    (country) => country.countryIsoCode === checkCountryIsoCode(countryIsoCode)
  )!

/**
 * getStates - get an array of states for a given country
 * @param {CountryIsoCode} countryIsoCode - A 2-digit country code
 * @param {DefaultStateStrings} strings - A set of state strings
 * @returns {State[]} An array of the given country's states with label and value
 */
export const getStates = (
  countryIsoCode: CountryIsoCode,
  strings: DefaultStateStrings
) => {
  const statesObjToArray = (statesObj: Record<string, string>) =>
    Object.entries(statesObj)
      .map(([value, labelKey]) => {
        const countryStrings = strings[countryIsoCode]
        return {
          value,
          label: countryStrings ? countryStrings[labelKey] : ''
        }
      })
      .sort((a, b) => (a.label > b.label ? 1 : -1))

  switch (countryIsoCode) {
    case 'CA':
      return statesObjToArray(CA_STATES)
    case 'US':
      return statesObjToArray(US_STATES)
    default:
      return []
  }
}
