import * as React from 'react'
import { Label } from '@toasttab/buffet-pui-text-base'

// this label emulates the label from TextInput
// but is a separate component to facilitate form alignment

type LabelProps = React.ComponentProps<typeof Label>

type Props = {
  name?: string
  required?: boolean
  disabled?: boolean
  helperIconButton?: LabelProps['helperIconButton']
  children: React.ReactNode
}

const StandaloneLabel = (props: Props) => {
  const { name, disabled, required, helperIconButton, children } = props
  return (
    <Label
      name={name}
      disabled={disabled}
      required={required}
      helperIconButton={helperIconButton}
    >
      {children}
    </Label>
  )
}

export { StandaloneLabel }
