import { useEffect } from 'react'
import { useDebounceValue } from '../hooks'
import { EmployeeValues } from '../pages'

type Args = {
  values: Partial<EmployeeValues>
  onChange: (values: Partial<EmployeeValues>) => void
}

const useOnDraftChange = (args: Args) => {
  const { values, onChange } = args
  const debouncedValues = useDebounceValue(values, 300)

  useEffect(() => {
    onChange(values)
  }, [debouncedValues]) // eslint-disable-line react-hooks/exhaustive-deps
}

export default useOnDraftChange
