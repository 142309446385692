import { ApolloClient, HttpLink, InMemoryCache } from '@apollo/client'
import generatedIntrospection from '@local/api/generated-api-code'
import fetch from 'cross-fetch'

const apolloClient = new ApolloClient({
  link: new HttpLink({ uri: '/graphql', fetch }),

  cache: new InMemoryCache({
    possibleTypes: generatedIntrospection.possibleTypes,
    typePolicies: {
      Navigation: {
        keyFields: ['path']
      }
    }
  })
  // connectToDevTools: true
})

export default apolloClient
