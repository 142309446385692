import { useEffect, useState } from 'react'

function useDebounceValue<T>(value: T, delay?: number): T {
  const [debouncedValue, setDebouncedValue] = useState<T>(value)

  useEffect(() => {
    const id = setTimeout(() => {
      setDebouncedValue(value)
    }, delay || 300)

    return () => {
      clearTimeout(id)
    }
  }, [value, delay])

  return debouncedValue
}

export default useDebounceValue
