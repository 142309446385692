import React from 'react'
import { useField } from 'formik'
import {
  PhoneInput,
  PhoneNumber,
  PhoneInputProps,
  defaultStrings,
  validationSchema
} from '@toasttab/buffet-pui-phone-input'
import type { Errors } from '@toasttab/buffet-pui-phone-input'

export interface PhoneInputFieldProps
  extends Omit<PhoneInputProps, 'value' | 'errors' | 'errorText' | 'onChange'>,
    Partial<Pick<PhoneInputProps, 'onChange'>> {}

export const PhoneInputField = ({
  name,
  onChange,
  onBlur,
  ...props
}: PhoneInputFieldProps) => {
  const [field, meta, helpers] = useField<PhoneNumber>({ name })

  const errors = meta.touched ? meta.error : meta.initialError

  return (
    <PhoneInput
      errors={errors as Errors | undefined}
      name={field.name}
      value={field.value}
      onChange={(value) => {
        helpers.setValue(value)
        if (onChange) {
          onChange(value)
        }
      }}
      onBlur={() => helpers.setTouched(true)}
      {...props}
    />
  )
}

// Export some dependencies from the non-Field version
PhoneInputField.defaultStrings = defaultStrings
PhoneInputField.validationSchema = validationSchema
export { defaultStrings, validationSchema, Errors }
