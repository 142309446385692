import { DashBorderButton } from '@toasttab/buffet-pui-dash-border-button'
import { AddIcon } from '@toasttab/buffet-pui-icons'
import React from 'react'
import {
  InvalidFile,
  useFileSelector
} from '../FileSelectorProvider/FileSelectorProvider'
import { FileUtils, getFileKey } from '../utils/FileUtils'
import { t } from '../defaultStrings'

export interface FilesChangeEvent {
  files: File[]
  invalidFiles: InvalidFile[]
}

/**
 * File selector component properties
 */
export interface FileSelectorButtonProps {
  name?: string
  testId?: string
  icon?: React.ReactNode
  helperText?: string
}

export function FileSelectorButton({
  name = 'fileUpload',
  testId = 'file-selector',
  icon = <AddIcon accessibility='decorative' />,
  helperText,
  children
}: React.PropsWithChildren<FileSelectorButtonProps>): JSX.Element {
  const {
    accept,
    maxSize,
    multiple,
    disabled,
    files: previousFiles,
    setFiles,
    setInvalidFiles,
    onChange: onChangeFromProvider,
    inputElementRef: fileInput
  } = useFileSelector()

  const filesSelected = (files: FileList) => {
    const validationResult = FileUtils.validateFiles(
      files,
      accept,
      maxSize,
      multiple
    )

    let newFiles
    if (multiple) {
      const filesDedup: Record<string, File> = {}
      previousFiles.forEach((file) => (filesDedup[getFileKey(file)] = file))
      validationResult.files.forEach(
        (file) => (filesDedup[getFileKey(file)] = file)
      )
      newFiles = Object.values(filesDedup)
    } else {
      newFiles = validationResult.files
    }

    setFiles(newFiles)
    setInvalidFiles(validationResult.invalidFiles)
    onChangeFromProvider?.({
      files: newFiles,
      invalidFiles: validationResult.invalidFiles
    })
  }

  return (
    <div>
      <input
        ref={fileInput}
        data-testid={testId + '-input'}
        name={name}
        accept={accept}
        hidden
        disabled={disabled}
        type='file'
        className='sr-only'
        multiple={multiple}
        onChange={(event) => {
          event.target.files && filesSelected(event.target.files)
        }}
      />

      <DashBorderButton
        testId={testId + '-button'}
        iconLeft={icon}
        helperText={helperText}
        className='w-full'
        disabled={disabled}
        onClick={() => fileInput?.current?.click()}
      >
        {children || t('upload-file', { count: multiple ? 2 : 1 })}
      </DashBorderButton>
    </div>
  )
}
