import { Currency, formatCurrency } from '@toasttab/buffet-pui-number-utilities'
import { Benefit, T } from '../../types'
import { BenefitsValues } from '../Benefits/benefits-types'
import { EmployeeValues } from '../index'

export const formatPay = (suffix: string, pay: number): string | undefined => {
  const transformedMoney = {
    amount: Number(pay),
    currency: Currency.USD
  }
  const currency = formatCurrency(transformedMoney, 'en-US')
  return `${currency}/${suffix}`
}

export const findValueLabel = (
  benefitKey: keyof BenefitsValues,
  benefits: Benefit[],
  employeeValues: EmployeeValues
) => {
  const benefit = benefits.find((b) => b.key === benefitKey)
  if (benefit !== undefined) {
    const options = benefit.options
    const labelObj = options.find((o) => o.value === employeeValues[benefitKey])
    if (labelObj !== undefined) {
      return labelObj.label
    }
  }
  console.warn('Cannot find benefit based on key passed in', {
    benefitKey,
    benefits
  })
  return 'unknown'
}

export const findWliFieldLabel = (benefits: Benefit[], t: T) => {
  const benefit = benefits.find((b) => b.key === 'wli')
  if (benefit !== undefined) {
    return benefit.label
  }
  return t('wli')
}
