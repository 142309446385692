type StepConfig = {
  key: string
  titleKey: string // to eventually be (keyof Translations)
}

const STEPS: readonly StepConfig[] = [
  {
    key: 'basic',
    titleKey: 'basicInfo'
  },
  {
    key: 'jobsAndPay',
    titleKey: 'jobsAndPay'
  },
  {
    key: 'benefits',
    titleKey: 'benefits'
  },
  {
    key: 'documents',
    titleKey: 'documents'
  },
  {
    key: 'review',
    titleKey: 'review'
  }
] as const

type Steps = typeof STEPS

type Step = Steps[number]

type StepKey = Step['key']

type State = 'active' | 'complete' | 'inactive'

export type { Step, StepKey, State }

export { STEPS }
