import * as React from 'react'
import { NumberInput as BuffetNumberInput } from '@toasttab/buffet-pui-text-input'
import { PhoneInputField } from '@toasttab/buffet-pui-forms'
import { defaultStrings as defaultCountryNameStrings } from '@toasttab/buffet-pui-country-utilities'
import { IconButton } from '@toasttab/buffet-pui-buttons'

import { useField } from 'formik'
import { Size } from '@toasttab/buffet-pui-text-base'

type IconType = React.ComponentProps<typeof IconButton>['icon']

type BaseProps = Omit<
  React.ComponentProps<typeof BuffetNumberInput>,
  'onChange'
>

type Props = BaseProps & {
  testId?: string
  icon?: IconType
  fieldName: string
  onChange?: (date: string) => void
}

const PhoneNumberInput = (props: Props) => {
  const {
    fieldName,
    testId,
    icon,
    errorText,
    invalid,
    name,
    onChange,
    ...rest
  } = props

  const [, meta, helpers] = useField(fieldName || '')

  const { error, touched } = meta

  const displayInvalid = invalid ?? touched ? !!error : false

  return (
    <PhoneInputField
      {...rest}
      containerClassName={'w-full mb-4 md:w-80 md:mb-6'}
      name={name || fieldName || ''}
      countryNameStrings={defaultCountryNameStrings}
      strings={PhoneInputField.defaultStrings}
      invalid={displayInvalid}
      testId={testId || fieldName}
      onBlur={() => helpers.setTouched(true)}
      size={Size.lg}
      onChange={() => helpers.setTouched(false)}
      data-toast-track-id='add-employee-phone-input'
    />
  )
}
export default PhoneNumberInput
