import * as React from 'react'
import { createContext, useContext, useMemo } from 'react'
import { getDisplayName } from '../helpers'
import type { EmployeeValues } from '../pages'

type Value = {
  employeeValues: EmployeeValues
  onBack: () => void
  onNext: () => void
  onChange: (values: Partial<EmployeeValues>) => void
  displayName: string
}

const AddEmployeeContext = createContext<Value | null>(null)

type Props = {
  employeeValues: any
  onBack: () => void
  onNext: () => void
  onChange: (values: Partial<EmployeeValues>) => void
  children: React.ReactNode
}

const AddEmployeeProvider = (props: Props) => {
  const { employeeValues, onNext, onChange, onBack, children } = props

  const fallbackName = 'Your Employee' /// todo: translate

  const value = useMemo(() => {
    const displayName = getDisplayName(employeeValues, fallbackName)

    return {
      employeeValues,
      onBack,
      onNext,
      onChange,
      displayName
    }
  }, [employeeValues, fallbackName, onBack, onNext, onChange])

  return (
    <AddEmployeeContext.Provider value={value}>
      {children}
    </AddEmployeeContext.Provider>
  )
}

const useAddEmployeeContext = () => {
  const value = useContext(AddEmployeeContext)

  if (!value) {
    throw new Error('missing employeeValues')
  }

  return value
}

export { AddEmployeeProvider, useAddEmployeeContext }
