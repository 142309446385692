import * as React from 'react'
import { CardSelectorGroup } from '@toasttab/buffet-pui-card-selector'
import { useField } from 'formik'

import { useTranslation } from '../hooks'
import cx from 'classnames'

export const YES_NO_RAW_OPTIONS = [
  {
    contentsKey: 'yes',
    value: 'yes'
  },
  {
    contentsKey: 'no',
    value: 'no'
  }
] as const

export const YES_NO_SSN_OPTIONS = [
  {
    contentsKey: 'willProvideYesSsnToggle',
    value: 'yes'
  },
  {
    contentsKey: 'willProvideNoSsnToggle',
    value: 'no'
  }
] as const

type Options = readonly [
  {
    readonly contentsKey: string
    readonly value: 'yes'
  },
  {
    readonly contentsKey: string
    readonly value: 'no'
  }
]

type Value = Options[number]['value']

type BaseProps = Omit<
  React.ComponentProps<typeof CardSelectorGroup>,
  'options' | 'onChange'
>

type Props = BaseProps & {
  fieldName: string
  onChange?: (date: boolean) => void
  defaultNo?: boolean
  options: Options
  stacked: boolean
  employmentType?: 'W2' | '1099'
  ssnRequired?: boolean
}

const YesNoToggle = (props: Props) => {
  const {
    fieldName,
    testId,
    errorText,
    invalid,
    name,
    defaultNo,
    stacked,
    employmentType,
    ssnRequired,
    onChange,
    ...rest
  } = props

  const { t } = useTranslation()

  const [, meta, helpers] = useField(fieldName || '')

  const { error, value, touched } = meta

  const options = props.options.map((option) => {
    // treat the yes/no value as a boolean while still using "yes"/"no" string values in the card options
    const convertedValue = value ? 'yes' : 'no'
    return {
      testId: `${option.value}-${fieldName}`,
      value: option.value,
      contents: t(option.contentsKey as any), // todo: fix
      checked: convertedValue === option.value,
      disabled: option.value === 'no' && employmentType === 'W2' && ssnRequired
    }
  })

  const displayInvalid = invalid ?? touched ? !!error : false

  const displayError = errorText ?? touched ? error : undefined

  return (
    <CardSelectorGroup
      {...rest}
      itemsContainerClassName={cx(
        'text-secondary',
        'w-full mb-4 md:mb-6',
        !stacked && 'grid-cols-2'
      )}
      options={options}
      testId={testId || fieldName}
      name={name || fieldName || ''}
      invalid={displayInvalid}
      errorText={displayError}
      defaultValue={defaultNo ? options[0].value : options[1].value} // "Yes"
      onChange={(event) => {
        const newValue = event.target.value === ('yes' as Value)
        if (fieldName) helpers.setValue(newValue)
        if (onChange) onChange(newValue)
      }}
      data-toast-track-id={`add-employee-${fieldName}`}
    />
  )
}
export default YesNoToggle
