import * as React from 'react'
import cx from 'classnames'
import {
  RenderItemProps,
  Select,
  SelectOption,
  SelectItemContainer,
  SelectProps
} from '@toasttab/buffet-pui-select'
import {
  CountryInfo,
  CountryIsoCode,
  DefaultStrings as DefaultCountryNameStrings,
  getCountry
} from '@toasttab/buffet-pui-country-utilities'

interface FlagSelectOption extends SelectOption<string> {
  countryInfo: CountryInfo
}

type CountrySelectProps = SelectProps & {
  options: FlagSelectOption[]
  value: string
}

export const buildCountryOptions = (
  countryIsoCodes: any[], // Setting any so that it can be iterated over
  preferredCountryIsoCodes: CountryIsoCode[],
  countryNameStrings: Record<keyof DefaultCountryNameStrings, string>
) =>
  Array.from(new Set([...preferredCountryIsoCodes, ...countryIsoCodes])).map(
    (countryIsoCode: CountryIsoCode) => {
      const countryInfo = getCountry(countryIsoCode, countryNameStrings)
      const { Flag } = countryInfo
      return {
        label: `+${countryInfo.diallingCode}`,
        iconLeft: <Flag title={countryInfo.name} className='w-6' />,
        value: countryIsoCode,
        countryInfo
      }
    }
  )

export const CountrySelect = ({
  testId,
  options,
  value,
  onChange,
  invalid,
  disabled,
  placement = 'bottom-start',
  size,
  transparentBackground,
  required
}: CountrySelectProps) => {
  const [isCountryOpen, setIsCountryOpen] = React.useState(false)
  const renderItem = ({
    item,
    index,
    itemProps,
    highlightedIndex,
    listRef,
    listContentRef
  }: RenderItemProps<string, FlagSelectOption>) => {
    const { label, countryInfo } = item
    const { Flag, name } = countryInfo
    return (
      <SelectItemContainer
        index={index}
        itemProps={itemProps}
        highlightedIndex={highlightedIndex}
        key={`${label}${index}`}
        className='flex flex-nowrap items-center'
        listRef={listRef}
        listContentRef={listContentRef}
      >
        <div>{<Flag title={name} className='w-6' />}</div>
        <div className='text-secondary w-16 px-4'>{label}</div>
        <div className='text-default w-44 truncate'>{name}</div>
      </SelectItemContainer>
    )
  }

  // elevation: Note that the z-index classes used here are to manage the focus ring for a compound component (combined dropdown and text input)

  return (
    <div
      className={cx('focus-within:z-5', {
        'hover:z-5': !disabled,
        'z-5': isCountryOpen && !disabled
      })}
    >
      <Select
        name={`${testId}-country`}
        label='Select country'
        hideLabel
        testId={testId}
        disabled={disabled}
        inlineBlock={true}
        options={options}
        renderItem={renderItem}
        value={value}
        onChange={onChange}
        invalid={invalid}
        placement={placement}
        size={size}
        transparentBackground={transparentBackground}
        required={required}
        borderRadiusClassName='rounded-r-none'
        onOpenChange={setIsCountryOpen}
      />
    </div>
  )
}
