import { useQuery } from '@tanstack/react-query'

type Resp = { number: string }
const getSsn = async (employeeId: string | null, companyCode: string) => {
  const DEFAULT_HEADERS = {
    'Content-Type': 'application/com.toasttab.payroll.v1+json',
    Accept: 'application/com.toasttab.payroll.v1+json'
  }

  const options = {
    method: 'GET',
    headers: { ...DEFAULT_HEADERS }
  } as const

  const fetchResp = await fetch(
    `/${companyCode}/employees/${employeeId}/profile/social-security?confirm=true`,
    options
  )
  if (fetchResp.ok) {
    const res = (await fetchResp.json()) as Resp
    return res?.number.replace(/-/g, '')
  }
  throw new Error(fetchResp.statusText || 'Unknown Error')
}

type SsnArgs = {
  employeeId: string
  companyCode: string
  enabled: boolean
}

const useSsnQuery = (args: SsnArgs) => {
  const { employeeId, companyCode, enabled } = args

  return useQuery({
    queryFn: async () => getSsn(employeeId, companyCode),

    queryKey: ['employee', employeeId, companyCode, 'ssn'],

    enabled,

    staleTime: Infinity,

    cacheTime: 0
  })
}

export { useSsnQuery }
