import * as Yup from 'yup'
import { isValidNumber } from 'libphonenumber-js'
import { getPhoneInfo } from './getPhoneInfo'
import {
  phoneNumberMinLength,
  makeIntlPhoneNumberString
} from '@toasttab/buffet-pui-phone-utilities'

/**
 * Defines a simple, lightweight validation schema for a phone number.
 */
export const validationSchema = Yup.object().shape({
  countryCode: Yup.string()
    .required('error.required.phone')
    .test({
      name: 'isCountryValid',
      exclusive: false,
      params: {},
      message: 'error.match.country-and-phone',
      test: (value, context) => {
        const { countryIsoCode, nationalNumber } = context.parent
        const phoneInfo = getPhoneInfo(`+${value}${nationalNumber || ''}`)
        return phoneInfo.countryIsoCode && countryIsoCode
          ? phoneInfo.countryIsoCode === countryIsoCode
          : true
      }
    }),
  nationalNumber: Yup.string()
    .required('error.required.phone')
    .test({
      name: 'min',
      exclusive: false,
      params: {},
      message: 'error.min.phone',
      test: (value, context) => {
        if (!value) {
          return true
        }
        const { countryIsoCode } = context.parent
        return value?.length >= phoneNumberMinLength(countryIsoCode, value)
      }
    })
})

/**
 * Defines the strict validation schema for a phone number.
 * This schema makes use of isValidNumber from libphonenumber-js.
 * If this validation is not required, consider using validationSchema instead.
 */
export const strictPhoneNumberValidation = Yup.object().shape({
  countryCode: Yup.string()
    .required('error.required.phone')
    .test({
      name: 'isCountryValid',
      exclusive: false,
      params: {},
      message: 'error.match.country-and-phone',
      test: (value, context) => {
        const { countryIsoCode, nationalNumber } = context.parent
        const phoneInfo = getPhoneInfo(`+${value}${nationalNumber || ''}`)
        return phoneInfo.countryIsoCode && countryIsoCode
          ? phoneInfo.countryIsoCode === countryIsoCode
          : true
      }
    }),
  nationalNumber: Yup.string()
    .required('error.required.phone')
    .test({
      name: 'min',
      exclusive: false,
      params: {},
      message: 'error.min.phone',
      test: (value, context) => {
        if (!value) {
          return true
        }
        const { countryIsoCode } = context.parent
        return value?.length >= phoneNumberMinLength(countryIsoCode, value)
      }
    })
    .test({
      name: 'validate',
      exclusive: false,
      params: {},
      message: 'error.valid.phone',
      test: (value, context) => {
        if (!value) {
          return true
        }
        const { countryCode } = context.parent
        const intlPhoneNumber = makeIntlPhoneNumberString({
          nationalNumber: value,
          countryCode
        })
        return isValidNumber(intlPhoneNumber)
      }
    })
})
