import * as React from 'react'
import { useTranslation } from '@local/translations'
import { Error500MobileIllustration } from '@toasttab/buffet-pui-illustrations'
import { NewHireModal } from '../components'
import { useTracking } from '@local/track'
import { Alert } from '@toasttab/buffet-pui-alerts'
type Props = {
  onSubmit: () => void
  onClose: () => void
  errors?: boolean
  errorMesssage?: string
}

const ErrorModal = (props: Props) => {
  const { onSubmit, onClose, errors, errorMesssage } = props
  const { t } = useTranslation()
  const { track } = useTracking()
  return (
    <NewHireModal
      onSubmit={() => {
        track('add-employee-modal.error-submit')
        onSubmit()
      }}
      onClose={() => {
        track('add-employee-modal.error-close')
        onClose()
      }}
      illustration={
        <Error500MobileIllustration resizeToContainer className='w-full' />
      }
      header={t('somethingIsWrong')}
      body={
        <>
          {errors ? (
            <Alert variant='error' testId='errorOnSubmit' className='text-left'>
              <div className={'mb-2'}>{t('networkEror')}</div>
              <div className={'font-medium type-subhead'}>{errorMesssage}</div>
            </Alert>
          ) : (
            <div className={'mb-4'}>{t('somethingIsWrongSubtext')}</div>
          )}
        </>
      }
      secondaryButton={t('tryAgain')}
      testId='errorModal'
    />
  )
}

export type { Props }
export { ErrorModal }
