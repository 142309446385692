import { AddEmployeeModalController } from './AddEmployeeModalController'
import { useDebounceValue } from './hooks'
import { LoadingPage } from './LoadingPage'
import { ErrorPage } from './components/ErrorPage'
import { getInitialValues } from './pages'
import { CustomerConfigurations } from './CustomerConfigurations'
import { BasicInfoValues } from './pages/BasicInfo/basic-info-types'
import { JobsAndPay } from './types'
import type { Job } from './pages/JobsAndPay/jobs-and-pay-types'
import uniqueId from 'lodash/uniqueId'
import { RestaurantUser, useGetPosUserV2Query } from '@local/api'
import { clearExampleEmail } from './helpers/loadEmployeeInitialValues'
import { getInitializeJobsValue } from './pages/JobsAndPay/jobs-and-pay-types'

type Props = {
  onClose: () => void
  onSuccess?: (employeeId: string | null) => void
  locationGuid: string
  toastUserGuid: string
}

const buildJobFromLocation = (
  restaurantUser: Array<RestaurantUser>,
  jobsAndPay: JobsAndPay
): Array<Job> => {
  const newJob: Array<Job> = []
  const { workTaxLocationId, payGroupId, customerPositionId } =
    getInitializeJobsValue(jobsAndPay)

  restaurantUser.forEach((ru) => {
    const foundLocation = jobsAndPay.locations.find(
      (jl) => jl.exportId === ru.locationGuid
    )
    if (foundLocation) {
      ru.jobs.forEach((j) => {
        const foundJob = foundLocation.jobs.find((fj) => fj.exportId === j)
        if (foundJob) {
          const buildJob: Job = {
            id: uniqueId('job-'),
            jobId: foundJob.id,
            workTaxLocationId: workTaxLocationId,
            wage: null,
            locationId: foundLocation.id,
            customerPositionId: customerPositionId,
            payGroupId: payGroupId
          }
          newJob.push(buildJob)
        } else {
          const buildJob: Job = {
            id: uniqueId('job-'),
            jobId: '',
            workTaxLocationId: workTaxLocationId,
            wage: null,
            locationId: foundLocation.id,
            customerPositionId: customerPositionId,
            payGroupId: payGroupId
          }
          newJob.push(buildJob)
        }
      })
      if (newJob.length === 0) {
        // this happens when a user has a location but no jobs assigned at that location
        const buildJob: Job = {
          id: uniqueId('job-'),
          jobId: '',
          workTaxLocationId: workTaxLocationId,
          wage: null,
          locationId: foundLocation.id,
          customerPositionId: customerPositionId,
          payGroupId: payGroupId
        }
        newJob.push(buildJob)
      }
    }
  })
  return newJob
}

const PosEmployeeRequirementsManager = (props: Props) => {
  const { onClose, onSuccess, toastUserGuid } = props

  const {
    customerData,
    customerLoading,
    customerError,
    refetch,
    eeoEnabled,
    ssnRequired,
    filteredJobsAndPay,
    activeBenefits,
    numRunPayrollsInThePastYear
  } = CustomerConfigurations()

  const {
    data: posEmployees,
    error: posUserError,
    loading: posUserLoading
  } = useGetPosUserV2Query({
    variables: {
      userGuid: toastUserGuid
    }
  })

  const showLoading =
    useDebounceValue(customerLoading, 200) || customerLoading || posUserLoading

  const isError =
    !!customerError || !customerData || !!posUserError || !posEmployees

  if (showLoading) {
    return <LoadingPage onClose={onClose} />
  }
  if (isError || filteredJobsAndPay.defaultEarningsIds === null) {
    return <ErrorPage callback={() => refetch()} onClose={onClose} />
  }

  const getPosUser = posEmployees.getPosUserV2

  const basicInfo = {
    email: getPosUser ? clearExampleEmail(getPosUser.email) : '',
    firstName: getPosUser ? getPosUser.userName.first : '',
    lastName: getPosUser ? getPosUser.userName.last : ''
  } as BasicInfoValues

  const mappings = getPosUser?.restaurantUsers.map((data) => ({
    locationGuid: data.locationGuid,
    restaurantUserGuid: data.restaurantUserGuid
  }))

  const defaultValues = getInitialValues({
    basicInfo: basicInfo,
    benefits: activeBenefits,
    jobsAndPay: filteredJobsAndPay
  })

  // if we get restaurantUsers back from graph call, add the existing jobs to the list
  const defaults = getPosUser?.restaurantUsers
    ? {
        ...defaultValues,
        jobs: buildJobFromLocation(
          getPosUser.restaurantUsers,
          filteredJobsAndPay
        ),
        posAccessCode: ''
      }
    : { ...defaultValues, posAccessCode: '' }

  return (
    <AddEmployeeModalController
      eeoEnabled={eeoEnabled}
      ssnRequired={ssnRequired}
      defaultValues={defaults}
      benefits={activeBenefits}
      jobsAndPay={filteredJobsAndPay}
      onClose={onClose}
      onSuccess={onSuccess}
      employeeId={null}
      hasInitialSsn={false}
      isNewHire={true}
      isPOS={true}
      showErrorState={isError}
      numRunPayrollsInThePastYear={numRunPayrollsInThePastYear}
      mappings={mappings ?? []}
    />
  )
}

export { PosEmployeeRequirementsManager }
