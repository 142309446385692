import * as React from 'react'
import { Modal } from '@toasttab/buffet-pui-modal'
import { Button, ButtonGroup } from '@toasttab/buffet-pui-buttons'

import { UnderReviewIllustration } from '@toasttab/buffet-pui-illustrations'

const ILLUSTRATIONS = {
  'under-review': (
    <UnderReviewIllustration resizeToContainer className='w-full' />
  )
} as const

type ButtonProps = {
  onClick: () => void
  children: React.ReactNode
  testId?: string
}

const SecondaryButton = (props: ButtonProps) => {
  const { onClick, testId, children } = props
  return (
    <Button
      testId={testId || 'status-modal-secondary-button'}
      variant='secondary'
      onClick={onClick}
    >
      {children}
    </Button>
  )
}

const PrimaryButton = (props: ButtonProps) => {
  const { onClick, testId, children } = props
  return (
    <Button
      testId={testId || 'status-modal-primary-button'}
      type='submit'
      onClick={onClick}
    >
      {children}
    </Button>
  )
}

type Props = {
  illustration?: 'under-review' | React.ReactNode
  header: string
  body: string
  onCancel?: () => void
  //
  children: React.ReactNode
  //
  testId?: string
}

const StatusModal = (props: Props) => {
  const {
    illustration = 'under-review',
    header,
    body,
    onCancel,
    children,
    testId
  } = props

  const displayIllusation =
    typeof illustration === 'string'
      ? ILLUSTRATIONS[illustration as keyof typeof ILLUSTRATIONS]
      : illustration

  return (
    <Modal
      size='sm'
      isOpen
      onRequestClose={onCancel}
      className='text-center'
      overflowBehavior='none'
      testId={testId}
    >
      <Modal.Header />
      <Modal.Body>
        {displayIllusation && (
          <div className='flex justify-center mb-9'>{displayIllusation}</div>
        )}

        <div className='mb-3 font-bold type-large text-normal text-default'>
          {header}
        </div>

        {body}

        <ButtonGroup
          direction={ButtonGroup.direction.vertical}
          buttonSize='lg'
          className='mt-5'
        >
          {children}
        </ButtonGroup>
      </Modal.Body>
    </Modal>
  )
}

StatusModal.PrimaryButton = PrimaryButton
StatusModal.SecondaryButton = SecondaryButton

export { StatusModal }
