import * as React from 'react'
import cx from 'classnames'
import { DoneIcon } from '@toasttab/buffet-pui-icons'
import { t, loadStrings } from '../defaultStrings'
import { StepperTheme, useStepperContext } from '../StepperContext'
import { StepProps } from './Step'

const getStyles = (state: StepProps['state'], theme: StepperTheme) => {
  const { complete, active } = theme

  switch (state) {
    default:
    case 'complete':
      return cx(complete.bg, 'rounded-full text-white')
    case 'active':
      return cx('border-2', active.border, 'rounded-full', active.text)
    case 'inactive':
      return 'border-2 border-color-default rounded-full text-disabled'
    case 'error':
      return 'border-2 border-error rounded-full text-error'
  }
}

export const CircleContent = ({
  content,
  state,
  size,
  stepTestId
}: {
  state: StepProps['state']
  size: StepProps['size']
  content: StepProps['circleContent']
  stepTestId: StepProps['testId']
}) => {
  const {
    theme: { active }
  } = useStepperContext()

  const testId = `${stepTestId}-${state}`
  loadStrings()

  if (content === 'icon') {
    switch (state) {
      default:
      case 'complete':
        return (
          <DoneIcon
            aria-label={t('done')}
            testId={testId}
            className='!inline-flex'
            size={size === 'default' ? 'sm' : 'xs'}
          />
        )
      case 'active':
        return (
          <span
            className={cx(
              size === 'default' ? 'w-2 h-2' : 'w-1.5 h-1.5',
              'rounded-full',
              active.bg
            )}
            data-testid={testId}
          >
            <span className='sr-only'>{t('active')}</span>
          </span>
        )
      case 'inactive':
        return (
          <span className='hidden' data-testid={testId}>
            <span className='sr-only'>{t('inactive')}</span>
          </span>
        )
      case 'error':
        return (
          <span
            className='w-2 h-2 rounded-full bg-error-75'
            data-testid={`${stepTestId}-error`}
          >
            <span className='sr-only'>{t('error')}</span>
          </span>
        )
    }
  }

  return (
    <span className='-mt-px' data-testid={testId}>
      {content}
    </span>
  )
}

export const CircleAndLine = ({
  testId,
  labelOn,
  state,
  size,
  isFirst,
  isLast,
  alignCircleToTop,
  children
}: {
  testId: StepProps['testId']
  labelOn: StepProps['labelOn']
  state: StepProps['state']
  size: StepProps['size']
  isFirst: StepProps['isFirst']
  isLast: StepProps['isLast']
  alignCircleToTop?: boolean
  children: React.ReactNode
}) => {
  const { theme } = useStepperContext()
  const styles = getStyles(state, theme)

  // When aligning the circle to the top, we show the last line unless the step is complete
  const showLastDividingLine = alignCircleToTop && state !== 'complete'

  return (
    <div
      className={cx(
        'flex justify-center',
        labelOn === 'side-vertical'
          ? 'flex-col space-y-2'
          : 'flex-row space-x-2',
        {
          'items-center': labelOn === 'side-vertical' || labelOn === 'below'
        }
      )}
      style={{
        maxHeight:
          labelOn === 'side-horizontal'
            ? size === 'default'
              ? '2.75rem'
              : '2.25rem'
            : undefined
      }}
    >
      {labelOn !== 'side-horizontal' && !alignCircleToTop && (
        <StepContentDivider
          testId={`${testId}-divider-1`}
          state={state}
          isStart
          isInvisible={isFirst}
        />
      )}
      <div
        data-testid={`${testId}-circle`}
        className={cx(
          'flex items-center justify-center',
          'font-semibold',
          styles,
          size === 'default' ? 'type-large' : 'type-default',
          size === 'default' ? 'w-8 h-8' : 'w-6 h-6',
          {
            'my-auto': labelOn === 'side-horizontal',
            'mt-2': alignCircleToTop
          }
        )}
        style={{
          minWidth: size === 'default' ? '2rem' : '1.5rem',
          minHeight: size === 'default' ? '2rem' : '1.5rem'
        }}
      >
        {children}
      </div>
      {labelOn !== 'side-horizontal' && (
        <StepContentDivider
          testId={`${testId}-divider-2`}
          state={state}
          isInvisible={isLast && !showLastDividingLine}
        />
      )}
    </div>
  )
}

export const StepContentDivider = ({
  testId,
  state,
  isStart = false,
  isInvisible
}: {
  testId: string | number
  state: StepProps['state']
  isStart?: boolean
  isInvisible?: boolean
}) => {
  const {
    theme: { complete, active }
  } = useStepperContext()

  let dividerColor: string
  switch (state) {
    case 'complete':
      dividerColor = complete.border
      break
    case 'active':
    case 'error':
      dividerColor = isStart ? active.border : 'border-gray-30'
      break
    default:
      dividerColor = 'border-gray-30'
      break
  }
  if (isInvisible) {
    dividerColor = 'border-transparent'
  }

  return (
    <div
      data-testid={testId}
      className={cx(dividerColor, {
        'flex-grow border': true
      })}
    />
  )
}

type StepContentContainerProps = StepProps & {
  children: React.ReactNode
  alignCircleToTop?: boolean
}

export const StepContentContainer = ({
  testId,
  state,
  size,
  circleContent,
  labelOn,
  isFirst,
  isLast,
  alignCircleToTop,
  children
}: StepContentContainerProps) => {
  return (
    <>
      <CircleAndLine
        testId={testId}
        labelOn={labelOn}
        state={state}
        size={size}
        isFirst={isFirst}
        isLast={isLast}
        alignCircleToTop={alignCircleToTop}
      >
        <CircleContent
          content={circleContent}
          state={state}
          size={size}
          stepTestId={testId}
        />
      </CircleAndLine>
      {children}
    </>
  )
}

export const StepContent = (props: StepProps) => {
  const { state, size, labelOn, title, description } = props
  return (
    <StepContentContainer {...props}>
      {(title || description) && (
        <div
          className={cx({
            'flex flex-col items-start justify-center': labelOn !== 'below',
            'flex flex-col items-center': labelOn === 'below'
          })}
        >
          {title && (
            <div
              className={cx(
                size === 'default' ? 'type-default' : 'type-subhead',
                'font-semibold',
                state === 'error' ? 'text-error' : 'text-default',
                {
                  'flex justify-center pt-1 text-center whitespace-nowrap':
                    labelOn === 'below'
                }
              )}
            >
              {title}
            </div>
          )}
          {description && (
            <div
              className={cx(
                size === 'default' ? 'type-subhead' : 'type-caption',
                'font-normal',
                state === 'error' ? 'text-error' : 'text-secondary',
                {
                  'flex justify-center text-center whitespace-nowrap':
                    labelOn === 'below'
                }
              )}
            >
              {description}
            </div>
          )}
        </div>
      )}
    </StepContentContainer>
  )
}
