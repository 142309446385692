export enum Size {
  lg = 'lg',
  base = 'base', // deprecated in favor of 'lg'
  sm = 'sm',
  auto = 'auto'
}

export type SizeTypes =
  | Size
  | 'lg'
  | 'base' // deprecated in favor of 'lg'
  | 'sm'
  | 'auto'
