import { gql } from '@apollo/client'

const SSN_REQUIRED_CUSTOMER_SETTING = 339
const EEO_ENABLED_CUSTOMER_SETTING = 338
const TAFW_ENABLED_CUSTOMER_SETTING = 295
const BENEFITS_PLAN_ENABLED = 296

const CUSTOMER_SETTINGS = gql`
  query FindCustomerSettings($settings: [Int!]!) {
    findCustomerSettings(settings: $settings) {
      id
      value
    }
    numRunPayrollsInThePastYear
    customerBenefits {
      label
      key
      options {
        value
        label
      }
    }
    customerConfiguration {
      customerPositions {
        id
        name
        fein {
          id
          name
        }
        salaryType
        checkCode {
          id
          name
          periodsPerYear
        }
        payGroup {
          id
          name
        }
        isExempt
      }
      locations {
        id
        name
        exportId
        jobs {
          id
          name
          exportId
        }
      }
      payrollSecurityRoles {
        id
        name
      }
      workTaxLocations {
        id
        fein
        name
      }
      payGroupsV2 {
        id
        name
      }
      defaultEarningsIds {
        hourly
        salary
        contractorHourly
        contractorSalary
      }
    }
  }
`

export {
  SSN_REQUIRED_CUSTOMER_SETTING,
  EEO_ENABLED_CUSTOMER_SETTING,
  TAFW_ENABLED_CUSTOMER_SETTING,
  BENEFITS_PLAN_ENABLED,
  CUSTOMER_SETTINGS
}
