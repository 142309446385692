import { CountryIsoCode, countryIsoCodes } from './data'

/**
 * checkCountryIsoCode - Returns the given ISO code if it's supported, defaulting to US
 * @param {CountryIsoCode | string | undefined} input
 * @returns {CountryIsoCode} A two-digit ISO code string
 */
export const checkCountryIsoCode = (
  input: CountryIsoCode | string | undefined
): CountryIsoCode => {
  // NOTE: We need to support this union type so we can cover cases where country codes that are not yet supported may be inputted */
  const inputtedCountryCode = input as CountryIsoCode
  return countryIsoCodes.includes(inputtedCountryCode)
    ? inputtedCountryCode
    : 'US'
}

/**
 * isInternationalCountryCode - Returns true if the the given ISO code is one of the valud Toast international codes
 * @param {CountryIsoCode | string | undefined} input
 * @returns {boolean}
 */
export const isInternationalCountryIsoCode = (
  input: CountryIsoCode | string | undefined
) => {
  const validCountry = checkCountryIsoCode(input)
  return validCountry !== 'US'
}
