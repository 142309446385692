import * as React from 'react'
import { Modal } from '@toasttab/buffet-pui-modal'
import { Button, ButtonGroup } from '@toasttab/buffet-pui-buttons'
import { trackModalClicks } from '@local/track'

type Props = {
  onSubmit: () => void
  onClose: () => void
  illustration: React.ReactNode
  header: string
  body: React.ReactNode
  primaryButton?: string
  secondaryButton: string
  testId?: string
}

const NewHireModal = (props: Props) => {
  const {
    onSubmit,
    onClose,
    illustration,
    header,
    body,
    primaryButton,
    secondaryButton,
    testId
  } = props
  const id = testId || 'newHireModal'
  return (
    <Modal
      size='sm'
      isOpen
      onRequestClose={onSubmit}
      className='text-center'
      overflowBehavior='modal'
      testId={id}
    >
      <Modal.Header />
      <Modal.Body>
        <div className='flex justify-center mb-8'>{illustration}</div>
        <div className='mb-4 font-bold type-large text-normal text-default'>
          {header}
        </div>
        <div className='mb-4'>{body}</div>
      </Modal.Body>
      <Modal.Footer>
        <ButtonGroup
          direction={ButtonGroup.direction.vertical}
          buttonSize='lg'
          className='w-full mt-2'
        >
          {primaryButton && (
            <Button
              testId={`${id}_primaryButton`}
              variant='secondary'
              onClick={onSubmit}
              {...trackModalClicks('primary')}
            >
              {primaryButton}
            </Button>
          )}
          <Button
            testId={`${id}_secondaryButton`}
            type='submit'
            onClick={onClose}
            {...trackModalClicks('secondary')}
          >
            {secondaryButton}
          </Button>
        </ButtonGroup>
      </Modal.Footer>
    </Modal>
  )
}
export type { Props }
export { NewHireModal }
