import { AddEmployeeModalController } from './AddEmployeeModalController'
import { LoadingPage } from './LoadingPage'
import { ErrorPage } from './components/ErrorPage'
import { useDebounceValue } from './hooks'
import { CustomerConfigurations } from './CustomerConfigurations'
import { getInitialValues } from './pages'
import { BasicInfoValues } from './pages/BasicInfo/basic-info-types'

type Props = {
  onClose: () => void
  onSuccess?: (employeeId: string | null) => void
  onSuccessButtonLabel?: string
}

const AddEmployeeRequirementsManager = (props: Props) => {
  const { onClose, onSuccess, onSuccessButtonLabel } = props
  const {
    customerData,
    customerLoading,
    customerError,
    refetch,
    eeoEnabled,
    ssnRequired,
    filteredJobsAndPay,
    activeBenefits,
    numRunPayrollsInThePastYear
  } = CustomerConfigurations()

  // The loading screen uses  its own Page, etc from @toasttab/buffet-pui-wizard-templates.
  // If it loading completes within the animation time,
  // it will un-mount and re-mount everything, causing a visual artifact.
  // therefore, we ensure that if loading starts, it lasts at least 200 ms

  const showLoading = useDebounceValue(customerLoading, 200) || customerLoading

  const isError = !!customerError || !customerData

  if (showLoading) {
    return <LoadingPage onClose={onClose} />
  }
  if (isError || filteredJobsAndPay.defaultEarningsIds === null) {
    return <ErrorPage callback={() => refetch()} onClose={onClose} />
  }

  const basicInfo = {} as BasicInfoValues

  const defaultValues = getInitialValues({
    basicInfo: basicInfo,
    benefits: activeBenefits,
    jobsAndPay: filteredJobsAndPay
  })

  return (
    <AddEmployeeModalController
      eeoEnabled={eeoEnabled}
      ssnRequired={ssnRequired}
      defaultValues={defaultValues}
      benefits={activeBenefits}
      jobsAndPay={filteredJobsAndPay}
      onClose={onClose}
      onSuccess={onSuccess}
      onSuccessButtonLabel={onSuccessButtonLabel}
      employeeId={null}
      hasInitialSsn={false}
      isNewHire={true}
      isPOS={false}
      showErrorState={isError}
      numRunPayrollsInThePastYear={numRunPayrollsInThePastYear}
      mappings={[]} // only used for POS users
    />
  )
}

export { AddEmployeeRequirementsManager }
