import * as React from 'react'
import { useTranslation } from '@local/translations'
import { Alert } from '@toasttab/buffet-pui-alerts'
import { useRef } from 'react'

type Props = { isFormSubmitting: boolean }

const FormErrorAlert = (props: Props) => {
  const { isFormSubmitting } = props
  const { t } = useTranslation()
  const alertRef = useRef<HTMLDivElement>(null)

  React.useEffect(() => {
    if (
      isFormSubmitting &&
      alertRef.current &&
      alertRef.current.scrollIntoView
    ) {
      alertRef.current.scrollIntoView({ behavior: 'smooth' })
    }
  }, [isFormSubmitting])

  return (
    <div ref={alertRef}>
      <Alert variant='error'> {t('genericErrorMessage')} </Alert>
    </div>
  )
}

export default FormErrorAlert
