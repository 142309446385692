import { useTranslation } from '@local/translations'
import { IconButton } from '@toasttab/buffet-pui-buttons'
import { EditIcon } from '@toasttab/buffet-pui-icons'
import { Alert } from '@toasttab/buffet-pui-alerts'
import { StepKey } from '../../domain/steps'
import { EmployeeValues } from '../index'
import { ReadonlyText, ReadOnlyRehireSsnInput } from '../../components'
import { format } from '@toasttab/buffet-pui-date-utilities'
import { formatPhoneNumber } from '../BasicInfo/helpers'

type Props = {
  setActiveStep: (step: StepKey) => void
  employeeValues: EmployeeValues
  hasInitialSsn: boolean
}

// zero width space to keep the height aligned
export const ZERO_WIDTH_SPACE = <>&#8203;</>

const ReviewAboutEmployee = (props: Props) => {
  const { setActiveStep, employeeValues, hasInitialSsn } = props
  const { t } = useTranslation()

  return (
    <>
      <div className={'flex justify-between mb-4 md:mb-8'}>
        <div
          data-testid='aboutThisEmployee'
          className='font-bold type-headline-5 text-default'
        >
          {t('aboutThisEmployee')}
        </div>
        <IconButton
          icon={<EditIcon aria-label='Edit' />}
          onClick={() => setActiveStep('basic')}
          cropToIcon
        />
      </div>
      <div className='grid-cols-2 lg:grid gap-x-6'>
        <ReadonlyText
          label={t('name')}
          value={`${employeeValues.firstName} ${employeeValues.lastName}`}
        />
        <ReadonlyText
          label={t('employeeNumber')}
          value={`${employeeValues.employeeNumber}`}
        />
        <ReadonlyText
          label={t('chosenName')}
          value={employeeValues.chosenName}
        />
      </div>
      <div className='grid-cols-2 lg:grid gap-x-6'>
        <ReadonlyText
          label={t('email')}
          value={
            employeeValues.willProvideEmail
              ? employeeValues.email
              : ZERO_WIDTH_SPACE
          }
        />
        <ReadonlyText
          label={t('mobilePhoneNumber')}
          value={
            formatPhoneNumber(employeeValues.phoneNumber.nationalNumber) ??
            ZERO_WIDTH_SPACE
          }
        />
        {employeeValues.willProvideSsn ? (
          <ReadOnlyRehireSsnInput
            ssnValue={employeeValues.socialSecurityNumber}
            hasInitialSsn={hasInitialSsn}
          />
        ) : (
          !employeeValues.willProvideSsn &&
          employeeValues.taxationType !== '1099' && (
            <div className='w-64 mb-2'>
              <ReadonlyText
                label={t('socialSecurityNumber')}
                value={
                  <Alert testId='noSsnProvided'>
                    {t('socialSecurityNumberNotProvided')}
                  </Alert>
                }
              />
            </div>
          )
        )}
        <ReadonlyText
          label={t('startDate')}
          value={
            // e.x. May 23, 2023 (Tuesday)
            format(new Date(employeeValues.startDate), 'MMM d, y (EEEE)') || ''
          }
        />
        <ReadonlyText
          label={t('taxDesignation')}
          value={
            employeeValues.taxationType === 'W2'
              ? t('employeeW2')
              : t('contractor1099')
          }
        />
      </div>
    </>
  )
}

export { ReviewAboutEmployee }
export type { Props }
