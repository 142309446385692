import * as React from 'react'
import { useState } from 'react'
import { ApolloProvider } from '@apollo/client'
import apolloClient from './apolloClient'
import { TranslationProvider } from '@local/translations'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { FullPageOverlayPortal } from '../FullPageOverlay'
import { useEcProps } from '@toasttab/ec-banquet-props'
import { getI18n } from 'react-i18next'

const SECONDS = 1000

const MINUTES = 60 * SECONDS

// the cache will be active as long as the provider is open. That's why we made it into a function
//  instead of just instantiating it. The way it stands now when you close the modal the cache is
//  cleared but if you have the modal open it stays as is
const getQueryClient = () =>
  new QueryClient({
    defaultOptions: {
      queries: {
        staleTime: 2 * MINUTES,
        cacheTime: 0,
        refetchOnMount: 'always'
      }
    }
  })

type Props = {
  children: React.ReactNode
}

const AddEmployeeProviders = (props: Props) => {
  const { children } = props

  const [queryClient] = useState(() => getQueryClient())

  const { i18n: banquetI18n } = useEcProps()

  const [i18n] = useState(() => {
    return banquetI18n || getI18n()
  })

  return (
    <FullPageOverlayPortal tailwindIndentifier='data-ec-add-employee-spa'>
      <TranslationProvider i18n={i18n}>
        <ApolloProvider client={apolloClient}>
          <QueryClientProvider client={queryClient}>
            {children}
          </QueryClientProvider>
        </ApolloProvider>
      </TranslationProvider>
    </FullPageOverlayPortal>
  )
}

export { AddEmployeeProviders }
