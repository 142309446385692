import * as React from 'react'
import cx from 'classnames'
import { CloseIcon } from '@toasttab/buffet-pui-icons'

export interface ChipProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  label: React.ReactNode
  onClick: () => void
  testId?: string | number
  className?: string
  size?: Size
  disabled?: boolean
  ariaLabel?: string
  deletable?: boolean
}

type Size = 'sm' | 'xs' | 'xxs'

export const Chip = ({
  label,
  onClick,
  testId = 'chip',
  className,
  size = 'sm',
  disabled,
  deletable,
  ariaLabel,
  ...chipProps
}: ChipProps) => {
  const aLabel = typeof label === 'string' ? label : ''

  return (
    <button
      data-testid={testId}
      type='button'
      className={cx(
        'bg-darken-8 rounded inline-flex items-center justify-between px-3 md:px-2 py-3 group',
        {
          'hover:bg-darken-12 outline-none focus-within:shadow-selectable-focus':
            !disabled,
          'cursor-default shadow-none': disabled,
          'md:py-2': size === 'sm',
          'md:py-1': size === 'xs',
          'md:py-0': size === 'xxs'
        },
        className
      )}
      disabled={disabled}
      onClick={onClick}
      aria-label={
        ariaLabel ? ariaLabel : deletable ? `Remove ${aLabel}` : aLabel
      }
      {...chipProps}
    >
      <div
        className={cx('type-default text-default text-left px-1 ', {
          'text-disabled': disabled
        })}
      >
        {label}
      </div>
      {deletable && (
        <CloseIcon
          className={cx('text-secondary pl-1', {
            'text-disabled': disabled,
            'group-hover:text-gray-125': !disabled
          })}
          data-testid={`${testId}-delete-icon`}
          accessibility='decorative'
        />
      )}
    </button>
  )
}
