import * as React from 'react'
import {
  IllustrationWrapper,
  IllustrationProps
} from '../../IllustrationWrapper'
export const UnderReviewIllustration = React.forwardRef(
  function UnderReviewIllustration(
    props: IllustrationProps,
    ref?: React.ForwardedRef<HTMLDivElement>
  ) {
    const resizeToContainer = props.resizeToContainer
    return (
      <IllustrationWrapper ref={ref} {...props}>
        <>
          {
            <svg
              width={resizeToContainer ? '100%' : '400'}
              height={resizeToContainer ? '100%' : '204'}
              viewBox='0 0 400 204'
              fill='none'
              className='inline-block'
            >
              <path
                d='M221.682 130.098h98.913v-23.356h-98.913M221.682 176.809h113.404v-23.356H221.682'
                stroke='#E5E5E5'
                strokeWidth={4}
                strokeLinecap='square'
                strokeLinejoin='round'
              />
              <path
                d='M203.788 200.055H323.34v-23.356M297.212 153.453v-23.355'
                stroke='#E5E5E5'
                strokeWidth={4}
                strokeLinecap='square'
                strokeLinejoin='round'
              />
              <path
                d='M256.702 49.614l-2.305 10.417M250.335 59.154c-.329-14.693-15.479-12.39-15.479-12.39-2.305 10.416 4.391 13.048 4.391 13.048'
                stroke='#E5E5E5'
                strokeWidth={4}
                strokeLinejoin='round'
              />
              <path
                d='M247.7 103.891c12.551 0 22.725-10.162 22.725-22.697 0-12.536-10.174-22.698-22.725-22.698-12.55 0-22.724 10.162-22.724 22.698 0 12.535 10.174 22.697 22.724 22.697z'
                stroke='#E5E5E5'
                strokeWidth={4}
                strokeLinejoin='round'
              />
              <path d='M0 200.164h400' stroke='silver' strokeWidth={4} />
              <path
                d='M184.027 18.145h20.31c9.551 0 17.345 7.785 17.345 17.325v147.37c0 9.539-7.794 17.324-17.345 17.324H88.297c-9.55 0-17.345-7.785-17.345-17.324V35.47c0-9.54 7.795-17.325 17.346-17.325h20.309'
                stroke='silver'
                strokeWidth={4}
                strokeLinecap='round'
                strokeLinejoin='round'
              />
              <path
                d='M70.952 137.115V35.579c0-9.649 7.795-17.434 17.456-17.434h20.199'
                stroke='silver'
                strokeWidth={4}
                strokeLinecap='round'
                strokeLinejoin='round'
              />
              <path
                d='M127.489 67.707h37.655M127.489 86.567h68.943M127.489 105.317h37.655M127.489 124.177h68.943'
                stroke='#E5E5E5'
                strokeWidth={4}
                strokeLinecap='square'
                strokeLinejoin='round'
              />
              <path
                d='M99.165 86.567v18.86M99.165 125.054v18.75M99.166 162.664c5.16 0 9.442-4.167 9.442-9.43 0-5.154-4.172-9.43-9.442-9.43-5.16 0-9.44 4.167-9.44 9.43.109 5.154 4.28 9.43 9.44 9.43z'
                stroke='silver'
                strokeWidth={4}
                strokeLinecap='round'
                strokeLinejoin='round'
              />
              <path
                d='M127.489 142.927h37.655M127.489 161.787h68.943'
                stroke='#E5E5E5'
                strokeWidth={4}
                strokeLinecap='square'
                strokeLinejoin='round'
              />
              <path
                d='M99.166 86.567c5.16 0 9.442-4.167 9.442-9.43 0-5.154-4.172-9.43-9.442-9.43-5.16 0-9.44 4.167-9.44 9.43.109 5.153 4.28 9.43 9.44 9.43zM99.166 124.177c5.16 0 9.442-4.167 9.442-9.43 0-5.154-4.172-9.43-9.442-9.43-5.16 0-9.44 4.167-9.44 9.43.109 5.153 4.28 9.43 9.44 9.43zM169.865 4h-47.097c-7.794 0-14.162 6.36-14.162 14.145 0 7.785 6.368 14.145 14.162 14.145h47.097c7.794 0 14.161-6.36 14.161-14.145C184.026 10.36 177.659 4 169.865 4z'
                stroke='silver'
                strokeWidth={4}
                strokeLinecap='round'
                strokeLinejoin='round'
              />
              <path
                d='M180.295 135.69c14.612 0 26.457-11.831 26.457-26.426 0-14.594-11.845-26.425-26.457-26.425s-26.458 11.83-26.458 26.425 11.846 26.426 26.458 26.426zM238.808 159.155l-38.094-32.456'
                stroke='#FF4C00'
                strokeWidth={4}
                strokeLinecap='round'
                strokeLinejoin='round'
              />
            </svg>
          }
        </>
      </IllustrationWrapper>
    )
  }
)
