import * as React from 'react'
import { CardSelectorGroup } from '@toasttab/buffet-pui-card-selector'
import { Label, HelperText } from '@toasttab/buffet-pui-text-base'
import { useField } from 'formik'
import cx from 'classnames'
import { useIsMobile } from '../hooks'

type Option = {
  label?: string
  value: string
  helpText?: string
  disabled?: boolean
}

type BaseProps = Omit<
  React.ComponentProps<typeof CardSelectorGroup>,
  'options' | 'onChange'
>

type Props = BaseProps & {
  fieldName: string
  onChange?: (date: string) => void
  options: Option[]
  stacked?: boolean
  singleOptionHelperText?: React.ReactNode
  rightAlignedHelperText?: boolean
}

const OptionsSelector = (props: Props) => {
  const { isMobile } = useIsMobile()
  const {
    options,
    fieldName,
    stacked = isMobile,
    testId,
    errorText,
    invalid,
    name,
    onChange,
    defaultValue,
    helperText,
    singleOptionHelperText,
    rightAlignedHelperText,
    label,
    ...rest
  } = props

  const [, meta, helpers] = useField(fieldName || '')

  const { error, value, touched } = meta

  const formattedOptions = options.map((option) => {
    return {
      testId: option.label,
      value: option.value,
      contents: option.helpText ? option.helpText : '',
      title: option.label ? option.label : '',
      checked: String(value) === option.value,
      disabled: option.disabled
    }
  })

  const displayError = errorText ?? touched ? error : undefined

  const displayInvalid = invalid ?? touched ? !!error : false

  const selectorTestId = testId || fieldName

  return (
    <div
      className={cx(
        'md:w-80 mb-4',
        stacked ? '' : 'max-w-2xl lg:w-full' // 'lg' needed since the inputs here are on a lg:flex display
      )}
    >
      <Label className='pb-4' name={name} required={true}>
        {label}
      </Label>
      <CardSelectorGroup
        required
        {...rest}
        itemsContainerClassName={cx(
          'text-secondary',
          stacked ? '' : 'grid-cols-2 gap-x-6 '
        )}
        options={formattedOptions}
        testId={selectorTestId}
        name={name || fieldName || ''}
        invalid={displayInvalid}
        errorText={displayError}
        defaultValue={defaultValue || value}
        onChange={(event) => {
          const newValue = event.target.value
          if (fieldName) helpers.setValue(newValue)
          if (onChange) onChange(newValue)
        }}
        data-toast-track-id={`add-employee-${fieldName}-options-selector`}
        helperText={helperText}
      />
      <div className={cx('pt-1 w-80', rightAlignedHelperText && 'float-right')}>
        <HelperText
          testId={`${selectorTestId}-helper-text`}
          helperText={singleOptionHelperText}
        />
      </div>
    </div>
  )
}
export default OptionsSelector
