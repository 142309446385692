import * as React from 'react'
import cx from 'classnames'
import { useWindowSize } from '@toasttab/buffet-use-window-size'
import { ScreenSize, useScreenSize } from '@toasttab/use-screen-size'
import { patternWizardTemplateIllustrationDataUrl } from '@toasttab/buffet-pui-illustrations'

export interface PageContainerProps {
  testId?: string | number
  containerClassName?: string
  backgroundImage?: string
  children?: React.ReactNode
}

export const PageContainer = ({
  testId = 'page-container',
  containerClassName,
  backgroundImage = patternWizardTemplateIllustrationDataUrl,
  children
}: PageContainerProps) => {
  const screenSize = useScreenSize()
  const isMobile = screenSize < ScreenSize.MD
  const { height: windowHeight } = useWindowSize()

  const style = !isMobile
    ? {
        backgroundImage: backgroundImage,
        height: '100%'
      }
    : {}

  return (
    <div
      data-testid={testId}
      className={cx(containerClassName, 'pt-12 md:pt-16 bg-page')}
      style={{ height: windowHeight }}
    >
      <div
        data-testid={`${testId}-pattern`}
        className={'flex flex-col justify-start bg-right-top bg-no-repeat'}
        style={style}
      >
        {children}
      </div>
    </div>
  )
}
