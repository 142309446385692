import * as React from 'react'
import { AutorenewIcon } from '@toasttab/buffet-pui-icons'
import { useFormikContext } from 'formik'
import { EmployeeValues } from '../combined-values'
import { useTranslation } from '@local/translations'
import { useSnackBar } from '@toasttab/buffet-pui-snackbars'
import { v4 as uuidv4 } from 'uuid'

import { DatePicker, NumberInput } from '../../components'

import { useNextAvailableEmployeeNumberQuery } from '@local/api'

type Props = {
  isNewHire: boolean
}
const EmployeeDetailsCard = (props: Props) => {
  const { isNewHire } = props
  const { t } = useTranslation()
  const { values, setFieldValue, handleBlur } =
    useFormikContext<EmployeeValues>()

  // Preserve this link state across step transitions in case a user returns to this step
  const [syncPosAccessCode, setSyncPosAccessCode] = React.useState(
    values.employeeNumber === values.posAccessCode
  )

  const {
    data: employeeNumber,
    loading: loadingEmployeeNumber,
    refetch: refetchEmployeeNumber
    // notifyOnNetworkStatusChange updates 'loading' on refetch
  } = useNextAvailableEmployeeNumberQuery({
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'no-cache'
  })

  // load the employee number initially on page load,
  // but persist it if the user comes back to this page
  const [isPageModified, setIsPagedModified] = React.useState(false)
  React.useEffect(() => {
    if (values.employeeNumber === '' && !isPageModified) {
      setFieldValue(
        'employeeNumber',
        employeeNumber?.nextAvailableEmployeeNumber === undefined ||
          employeeNumber?.nextAvailableEmployeeNumber === null
          ? ''
          : // nextAvailableEmployeeNumber is a number | undefined, but employeeNumber needs to be a string, so it can
            // have an initial value of empty string
            employeeNumber?.nextAvailableEmployeeNumber.toString()
      ).then(() => setSyncPosAccessCode(true))
    }
  }, [
    employeeNumber?.nextAvailableEmployeeNumber,
    isPageModified,
    setFieldValue,
    values.employeeNumber
  ])

  const { showErrorSnackBar, closeSnackBar } = useSnackBar()
  const DisplaySnackBarError = (callback: () => void) => {
    const snackBarId = uuidv4()
    showErrorSnackBar(t('somethingWentWrongRetry'), {
      key: snackBarId,
      showIcon: true,
      action: (
        <button
          onClick={() => {
            closeSnackBar(snackBarId)
            callback()
          }}
        >
          Reload
        </button>
      )
    })
  }

  const onRefetchEmployeeNumber = () => {
    setFieldValue('employeeNumber', '').then(() => {
      refetchEmployeeNumber()
        .then((res) => {
          const nextAvailableEmployeeNumber =
            res.data.nextAvailableEmployeeNumber
          if (nextAvailableEmployeeNumber) {
            setFieldValue(
              'employeeNumber',
              // nextAvailableEmployeeNumber is a number, but employeeNumber needs to be a string, so it can have an
              // initial value of empty string
              nextAvailableEmployeeNumber.toString()
            )
          }
        })
        .catch(() => DisplaySnackBarError(onRefetchEmployeeNumber))
    })
  }

  React.useEffect(() => {
    // set the value of textC, based on textA and textB
    if (syncPosAccessCode) {
      setFieldValue('posAccessCode', values.employeeNumber)
    }
  }, [setFieldValue, syncPosAccessCode, values.employeeNumber])

  const datePicker = (
    <DatePicker
      fieldName='startDate'
      required
      helperText={t('startDateSubtext')}
      label={t('startDate')}
      dateLegendLabel={t('today')}
      containerClassName='mb-4 w-80'
    />
  )

  return (
    <>
      <div
        data-testid='employee-details-header'
        className='mb-6 font-bold type-headline-4 text-default'
      >
        {t('employmentDetails')}
      </div>
      <div className='gap-6 lg:flex'>
        {datePicker}
        <NumberInput
          decimalScale={0}
          fieldName='employeeNumber'
          required
          readOnly={loadingEmployeeNumber || !isNewHire}
          label={t('employeeNumber')}
          helperText={isNewHire ? t('employeeNumberSubtext') : null}
          containerClassName='mb-4 w-80 lg:mr-6'
          icon={
            isNewHire ? (
              <AutorenewIcon aria-label='refetch employee number' />
            ) : undefined
          }
          onIconClick={onRefetchEmployeeNumber}
          onBlur={handleBlur}
          onChange={() => setIsPagedModified(true)}
          maxLength={8}
        />
      </div>
    </>
  )
}

export { EmployeeDetailsCard }
