import * as React from 'react'
import {
  TextInput,
  NumberInput as BuffetNumberInput
} from '@toasttab/buffet-pui-text-input'
import { IconButton } from '@toasttab/buffet-pui-buttons'
import { Suffix } from '@toasttab/buffet-pui-text-base'

import { useField } from 'formik'
import { VisibilityOffIcon, VisibilityOnIcon } from '@toasttab/buffet-pui-icons'
import { useState } from 'react'
import SocialSecurityModal from './SocialSecurityModal'
import { useSsnContext } from '../hooks/useSsnManagement'

type IconType = React.ComponentProps<typeof IconButton>['icon']

type BaseProps = Omit<
  React.ComponentProps<typeof BuffetNumberInput>,
  'onChange'
>

type Props = BaseProps & {
  testId?: string
  icon?: IconType
  fieldName: string
  onChange?: (date: string) => void
  employeeId: string | null
}

const RehireSsnInput = (props: Props) => {
  const {
    fieldName,
    testId,
    icon,
    errorText,
    invalid,
    name,
    employeeId,
    onChange,
    ...rest
  } = props

  const { savedSsn, onFetchSsn, isLoading } = useSsnContext()

  const [shouldShowActualSsn, setShouldShowActualSsn] = useState(false)

  const [showSsnModal, setShowSsnModal] = useState(false)

  const maskedSsn = !shouldShowActualSsn

  const [, meta, helpers] = useField(fieldName || '')

  const { error, value, touched } = meta

  const displayError = errorText ?? touched ? error : undefined

  const displayInvalid = invalid ?? touched ? !!error : false

  const loadingState = (
    <TextInput
      required
      testId={testId}
      value='###-##-####'
      inputMode='decimal'
      suffix={
        <IconButton
          disabled={isLoading}
          icon={<VisibilityOnIcon aria-label='Toggle Off' />}
          contained
        />
      }
      size='lg'
      readOnly={true}
      containerClassName={'w-full mb-4 md:w-80 md:mb-6'}
      disabled={true}
    />
  )

  return (
    <>
      {isLoading ? (
        loadingState
      ) : (
        <BuffetNumberInput
          {...rest}
          inputMode='decimal'
          testId={testId || fieldName}
          containerClassName={'w-full md:w-80'}
          name={name || fieldName || ''}
          invalid={displayInvalid}
          errorText={displayError}
          disabled={isLoading}
          value={value}
          onBlur={() => {
            !maskedSsn && helpers.setTouched(true)
          }}
          onChange={(val) => {
            const value = val.value
            if (fieldName) helpers.setValue(value)
            if (onChange) onChange(value)
            helpers.setTouched(false)
          }}
          format={'###-##-####'}
          isNumericString
          allowEmptyFormatting
          maxLength={maskedSsn ? 9 : undefined}
          type={maskedSsn ? 'password' : ''}
          suffix={
            <IconButton
              icon={
                maskedSsn ? (
                  <VisibilityOnIcon aria-label='Toggle Off' />
                ) : (
                  <VisibilityOffIcon aria-label='Toggle On' />
                )
              }
              contained
              onClick={() => {
                if (savedSsn) {
                  setShouldShowActualSsn((prev) => !prev)
                } else {
                  setShowSsnModal(true)
                }
              }}
            />
          }
          suffixVariant={Suffix.Variant.iconButton}
          size='lg'
          readOnly={maskedSsn}
          data-toast-track-id='add-employee-rehire-ssn-input'
        />
      )}

      <SocialSecurityModal
        onSubmit={() => {
          onFetchSsn()
          setShowSsnModal(false)
          setShouldShowActualSsn(true)
        }}
        onClose={() => {
          setShowSsnModal(false)
        }}
        showSsnModal={showSsnModal}
        isInProgress={isLoading}
      />
    </>
  )
}
export type { Props }
export default RehireSsnInput
