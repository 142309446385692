import * as React from 'react'
import {
  Skeleton,
  TextInputSkeleton
} from '@toasttab/buffet-pui-loading-indicators'
import {
  ContentFooter,
  NavigationContainer,
  Page,
  PageAside,
  PageBody,
  PageContainer,
  PageMain,
  Header,
  PageStepper
} from '@toasttab/buffet-pui-wizard-templates'
import { EmployeeBadgeIcon } from '@toasttab/buffet-pui-icons'
import { useTranslation } from '@local/translations'

type Props = {
  onClose: () => void
}

const InputSkeleton = () => (
  <div className='mb-4 mr-6 w-80'>
    <TextInputSkeleton noHelperText />
  </div>
)

const LoadingPage = (props: Props) => {
  const { onClose } = props
  const { t } = useTranslation()

  return (
    <Page testId='Loading-page'>
      <Header
        icon={<EmployeeBadgeIcon aria-label='employee' />}
        title={t('addTeamMember')}
        onRequestClose={onClose}
      />
      <PageContainer>
        <PageStepper steps={[]} />
        <PageBody>
          <PageMain>
            <div className='flex'>
              <div>
                <InputSkeleton />
                <InputSkeleton />
              </div>
              <div>
                <InputSkeleton />
                <InputSkeleton />
              </div>
            </div>
            <Skeleton height='4.5rem' className='w-80' />
            <ContentFooter>
              <NavigationContainer>
                <Skeleton height='2.5rem' className='w-32' />
              </NavigationContainer>
            </ContentFooter>
          </PageMain>
          <PageAside />
        </PageBody>
      </PageContainer>
    </Page>
  )
}

export { LoadingPage }
