import { useCompanyCode } from '@toasttab/ec-session'
import { useSsnQuery } from '../providers/SsnManager'
import * as React from 'react'
import {
  useState,
  useContext,
  createContext,
  useMemo,
  useCallback
} from 'react'

type Value = {
  savedSsn: string | undefined
  onFetchSsn: () => void
  isLoading: boolean
}

const DEFAULT_VALUE = {
  savedSsn: undefined,
  onFetchSsn: () => {},
  isLoading: false
}

const SsnContext = createContext<Value>(DEFAULT_VALUE)

const useSsnContext = () => useContext(SsnContext)

type ProviderProps = {
  children: React.ReactNode
  savedSsn: string | undefined
  onFetchSsn: () => void
  isLoading: boolean
}

const SsnContextProvider = (props: ProviderProps) => {
  const { children, savedSsn, onFetchSsn, isLoading } = props

  const value = useMemo(() => {
    return { savedSsn, onFetchSsn, isLoading }
  }, [savedSsn, onFetchSsn, isLoading])

  return <SsnContext.Provider value={value}>{children}</SsnContext.Provider>
}

type Args = {
  employeeId: string
}

const useSsnManagement = (args: Args) => {
  const { employeeId } = args

  const [fetchSsnEnabled, setFetchSsnEnabled] = useState(false)

  const companyCode = useCompanyCode()
  const query = useSsnQuery({
    employeeId,
    companyCode,
    enabled: fetchSsnEnabled
  })

  const onFetchSsn = useCallback(() => {
    setFetchSsnEnabled(true)
  }, [])

  return {
    savedSsn: query.data,
    onFetchSsn,
    isLoading: query.fetchStatus !== 'idle' && query.isLoading
  }
}

export { useSsnContext, SsnContextProvider, useSsnManagement }
