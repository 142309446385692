import * as React from 'react'
import { FileUtils } from '../utils/FileUtils'
import { Alert } from '@toasttab/buffet-pui-alerts'
import { useFileSelector } from '../FileSelectorProvider/FileSelectorProvider'
import { t } from '../defaultStrings'

/**
 * File selector component properties
 */
export interface FileErrorProps {
  testId?: string
}

/**
 * File selector component that allow file selection via a button or drag and drop
 */
export function FileErrorList({
  testId = 'file-selector'
}: FileErrorProps): JSX.Element | null {
  const { maxSize, invalidFiles, setInvalidFiles, onChange, files } =
    useFileSelector()

  if (!invalidFiles.length) {
    return null
  }

  return (
    <div data-testid={testId + '-errors'}>
      <Alert
        className='w-full mb-2'
        onDismiss={() => {
          setInvalidFiles([])
          onChange?.({
            files: files,
            invalidFiles: []
          })
        }}
        variant='error'
      >
        {invalidFiles.map((error, index) => {
          return (
            <div
              key={error.file.name}
              data-testid={`${testId}-error-${index}`}
            >{`${
              error.reason === 'size'
                ? t('file-size-exceeds-maximum') +
                  FileUtils.getFileDisplaySize(maxSize)
                : t('file-type-not-allowed')
            } - ${error.file.name} (${FileUtils.getFileDisplaySize(
              error.file.size
            )})`}</div>
          )
        })}
      </Alert>
    </div>
  )
}
