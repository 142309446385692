import * as yup from 'yup'
import { UploadFileDetails } from '../../providers/DocumentUploadManager'
import { T } from '../../types'

export type OnboardingDocument = {
  id: number | string
  name: string
  assignedByPosition: boolean
  touched: boolean
  checked: boolean
}

export type AdditionalDocument = {
  fileName: string
  filePath: string
}

export type CustomDocumentField = {
  id: string
  documentIds: string[]
  name: string
  fieldId: number
  value: string
}

type Args = {
  t: T
}

type OnboardingValues = {
  selectedDocuments: OnboardingDocument[]
  additionalDocuments: UploadFileDetails[]
  customDocumentFields: CustomDocumentField[]
}

const getOnboardingInitialValues = (): OnboardingValues => {
  return {
    selectedDocuments: [],
    additionalDocuments: [],
    customDocumentFields: []
  }
}

const onboardingDocumentValidationSchema = yup.object({
  id: yup.mixed().required(),
  name: yup.string().required(),
  assignedByPosition: yup.boolean().required(),
  touched: yup.boolean().required(),
  checked: yup.boolean().required()
})

const getOnboardingValidationSchema = (args: Args) => {
  const { t } = args
  return yup.object({
    selectedDocuments: yup.array().of(onboardingDocumentValidationSchema),
    customDocumentFields: yup.array().of(
      yup.object({
        id: yup.string().required(),
        name: yup.string().required(),
        documentIds: yup.array().of(yup.string().required()),
        fieldId: yup.number().required(),
        value: yup.string().required(t('customFieldRequired'))
      })
    )
  })
}

export type { OnboardingValues }

export { getOnboardingValidationSchema, getOnboardingInitialValues }
