import * as React from 'react'
import cx from 'classnames'
import { useIsMobile } from '../../hooks'
import { StandaloneLabel } from './StandaloneLabel'
import { StandaloneLegend } from './StandaloneLegend'

// this component will vertically align the left and right across their mid-line.
// in mobile, it will stack the left content first and the right content second

// pass `__debug` to view the line that the component is lining everything up against

type TwoColumnProps = {
  left: React.ReactNode
  right: React.ReactNode
  align?: 'top' | 'bottom'
  showAlert?: boolean
}

const TwoColumns = ({ left, right, align, showAlert }: TwoColumnProps) => {
  return (
    <div
      className={cx(
        'flex flex-row gap-6', //
        !showAlert && align === 'bottom' && 'items-end'
      )}
    >
      <div className={cx('w-80')}>{left}</div>

      <div className={cx('w-80')}>{right}</div>
    </div>
  )
}

type Props = {
  showAlert?: boolean
  leftHeading: React.ReactNode
  rightHeading: React.ReactNode
  leftContent: React.ReactNode
  rightContent: React.ReactNode
  __debug?: true
}

const AlignedSection = (props: Props) => {
  const {
    showAlert,
    leftHeading, //
    rightHeading,
    leftContent,
    rightContent,
    __debug
  } = props

  const { isMobile } = useIsMobile()

  if (isMobile) {
    return (
      // we may need to wrap certain subgroups and add padding, etc
      <div className='w-full'>
        {leftHeading}
        {leftContent}
        {rightHeading}
        {rightContent}
      </div>
    )
  }

  return (
    <div
      style={
        __debug ? { outline: '2px solid rgba(255, 128, 128, 0.5)' } : undefined
      }
    >
      <TwoColumns
        left={leftHeading}
        right={rightHeading}
        align='bottom'
        showAlert={showAlert}
      />

      {__debug && (
        <div style={{ borderBottom: '2px solid  rgba(255, 128, 128, 0.5)' }} />
      )}

      <TwoColumns left={leftContent} right={rightContent} align='top' />
    </div>
  )
}

AlignedSection.StandaloneLabel = StandaloneLabel
AlignedSection.StandaloneLegend = StandaloneLegend

export { AlignedSection }
