import { useTranslation } from '@local/translations'
import { IconButton } from '@toasttab/buffet-pui-buttons'
import { EditIcon, CopyIcon } from '@toasttab/buffet-pui-icons'
import { StepKey } from '../../domain/steps'
import { EmployeeValues } from '../index'
import { ReadonlyText } from '@toasttab/buffet-pui-readonly'
import uniq from 'lodash/uniq'
import compact from 'lodash/compact'
import { JobsAndPay, Location } from '../../types'
import { useAddEmployeeContext } from '../../hooks'
import { EEO_OPTIONS } from '../JobsAndPay/jobs-and-pay-types'
import { ZERO_WIDTH_SPACE } from './ReviewAboutEmployee'
import { getSelectedLocationsExportIds } from '../JobsAndPay/JobsAndPayForm'
import { Job } from '../JobsAndPay/jobs-and-pay-types'
import { useSnackBar } from '@toasttab/buffet-pui-snackbars'

type Props = {
  setActiveStep: (step: StepKey) => void
  employeeValues: EmployeeValues
  jobsAndPay: JobsAndPay
  isNewHire: boolean
}

// if newhire
//  - is ff enabled
//    - if any location selected has export ids show field
//  - if ff disabled show field
// if rehire do not show field
const shouldShowAccessCode = (
  isNewHire: boolean,
  jobs: Array<Job>,
  locations: Array<Location>
) => {
  if (!isNewHire) {
    return false
  }
  return getSelectedLocationsExportIds(jobs, locations).length > 0
}

const ReviewGeneralJobInfo = (props: Props) => {
  const { displayName } = useAddEmployeeContext()
  const { showSuccessSnackBar } = useSnackBar()
  const { setActiveStep, employeeValues, jobsAndPay, isNewHire } = props
  const { t } = useTranslation()

  const showAccessCode = shouldShowAccessCode(
    isNewHire,
    employeeValues.jobs,
    jobsAndPay.locations
  )

  const getFeinFromCustomerPosition = (positionId: string) => {
    return jobsAndPay.customerPositions.find((cp) => cp.id === positionId)?.fein
      .name
  }

  const getPaygroupFromId = (payGroupId: string) => {
    return jobsAndPay.payGroupsV2.find((pg) => pg.id === payGroupId)?.name
  }

  const getSecuirtyRoleFromId = (role: string) => {
    return jobsAndPay.payrollSecurityRoles.find((r) => r.id === role)?.name
  }

  const payGroups = uniq(
    employeeValues.jobs.map((job) => getPaygroupFromId(job.payGroupId))
  ).map((payGroup) => {
    return <div key={payGroup}>{payGroup}</div>
  })

  const feins = uniq(
    compact(
      employeeValues.jobs.map((j) =>
        j.customerPositionId
          ? getFeinFromCustomerPosition(j.customerPositionId)
          : null
      )
    )
  ).map((fein) => {
    return <div key={fein}>{fein}</div>
  })

  return (
    <>
      <div className={'flex justify-between mb-4 md:mb-8'}>
        <div
          data-testid='generalJobInfo'
          className='font-bold type-headline-5 text-default'
        >
          {t('generalJobInfo')}
        </div>
        <IconButton
          icon={<EditIcon aria-label={'edit jobs and pay'} />}
          onClick={() => setActiveStep('jobsAndPay')}
          cropToIcon
          data-toast-track-id='add-employee-return-to-jobs-icon-link'
        />
      </div>

      <div className='grid-cols-2 lg:grid gap-x-6'>
        <ReadonlyText
          label={t('isOvertimeEligible', {
            name: displayName
          })}
          value={
            employeeValues.overtimeEligible ? t('overtimeYes') : t('overtimeNo')
          }
        />
        <ReadonlyText label={t('payGroup')} value={<div>{payGroups}</div>} />
        <div className='mb-4'>
          <ReadonlyText
            className='mb-0'
            label={t('eeoClassification')}
            value={
              employeeValues.eeoClassification
                ? t('eeoDefaultClassification', {
                    id: employeeValues.eeoClassification,
                    // options array is 0 indexed but eeo classification is 1 indexed due to how it is
                    // setup in the database so we subtract 1 to get the array value. This will always
                    // return a value because we are only letting the user select a value from the given values
                    name: t(
                      EEO_OPTIONS[
                        parseInt(employeeValues.eeoClassification) - 1
                      ].labelKey
                    )
                  })
                : ZERO_WIDTH_SPACE
            }
          />
          {employeeValues.eeoClassification && (
            <div className='text-secondary type-subhead'>
              {t('eeoClassificationSubtext')}
            </div>
          )}
        </div>
        <ReadonlyText
          label={t('securityRole')}
          value={getSecuirtyRoleFromId(employeeValues.securityRole) || ''}
        />
        {showAccessCode ? (
          <ReadonlyText
            label={t('posAccessCode')}
            value={
              <div className='flex items-center justify-between w-28'>
                {employeeValues.posAccessCode}
                <IconButton
                  cropToIcon
                  onClick={() => {
                    // navigator() requires an HTTPS connection and may not work in Storybook
                    navigator.clipboard
                      .writeText(employeeValues.posAccessCode)
                      .then(() => showSuccessSnackBar(t('successfullyCopied')))
                  }}
                  icon={<CopyIcon aria-label='Copy' />}
                  iconColor='secondary'
                />
              </div>
            }
          />
        ) : null}
        <ReadonlyText
          label={feins.length > 1 ? t('businesses') : t('business')}
          value={<div>{feins}</div>}
        />
      </div>
    </>
  )
}

export { ReviewGeneralJobInfo }
export type { Props }
