import React from 'react'
import cx from 'classnames'
import { BaseProps, SizeMap, SIZE } from '../utils'

export type ToastLogoProps = BaseProps & JSX.IntrinsicElements['svg']

export const ToastLogo = ({
  size = 'xxl',
  strokeColor = 'text-white',
  fillColor = 'bg-brand-50',
  className = '',
  testId = 'toast-logo',
  ...props
}: ToastLogoProps) => {
  return (
    <svg
      className={cx(
        'fill-current align-middle leading-none',
        SizeMap[size],
        strokeColor,
        fillColor,
        className
      )}
      data-testid={testId}
      xmlns='http://www.w3.org/2000/svg'
      viewBox='0 0 48 48'
      {...props}
    >
      <g>
        <path d='M35.78 23.785c-.124-1.194-.476-2.048-1.06-2.52 1.212-.816 1.14-2.462.641-3.774-.69-1.793-2.325-2.958-3.58-3.614a17.89 17.89 0 00-17.118.632c-1.455.887-2.906 2.576-2.211 4.398.182.456.455.872.803 1.222 0 0 .181.202.338.363a8.937 8.937 0 00-1.365 2.727c-.913 3.236-.808 5.326-.537 8.143.361 3.506 1.726 4.43 2.81 4.586.266.036.535.053.804.052.87-.027 1.735-.13 2.586-.307.476-.085.951-.165 1.384-.222l1.08-.141a23.405 23.405 0 013.984-.316c1.143.053 2.282.163 3.414.33 1.26.189 2.53.3 3.804.335h.148c1.298 0 3.485.061 3.899-1.755.308-1.62.437-3.268.385-4.916v-.585a37.801 37.801 0 00-.21-4.638zm-19.144-2.996a.308.308 0 00.138-.26.272.272 0 00-.08-.193 4.1 4.1 0 00-.32-.274 1.466 1.466 0 01-.475-.561 1.407 1.407 0 01-.052-1.175c.352-.943 1.526-1.684 2.168-1.967a13.59 13.59 0 015.487-1.104 13.3 13.3 0 016.78 1.731c.795.472 1.945 1.708 1.936 2.657a1.14 1.14 0 01-.67 1.005.322.322 0 00-.134.259.273.273 0 00.153.245c1.04.515 1.345 1.996 1.512 3.374a22.186 22.186 0 01-.181 5.968 1.75 1.75 0 01-.176.557v.028c-.509 1.17-2.059 1.033-3.195.944a31.106 31.106 0 01-2.211-.288 24.393 24.393 0 00-3.086-.335 19.933 19.933 0 00-4.722.585l-.632.16c-1.008.264-2.15.566-2.891-.075-1.104-.944-1.256-2.652-1.27-3.84.018-1.253.143-2.501.37-3.733.138-.753.36-1.489.662-2.193a4.828 4.828 0 01.889-1.515z' />
      </g>
    </svg>
  )
}

ToastLogo.Size = SIZE
