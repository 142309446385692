// Basic Info
import type { BasicInfoValues } from './BasicInfo/basic-info-types'
import {
  getInitialBasicInfoValues,
  getBasicInformationValidationSchema
} from './BasicInfo/basic-info-types'

// Jobs & Pay
import type { JobsAndPayValues } from './JobsAndPay/jobs-and-pay-types'
import {
  getJobsAndPayInitialValues,
  getJobsAndPayValidationSchema
} from './JobsAndPay/jobs-and-pay-types'

// Benefits
import type { BenefitsValues } from './Benefits/benefits-types'
import {
  getBenefitsInitialValues,
  getBenefitsValidationSchema
} from './Benefits/benefits-types'

// Onboarding
import type { OnboardingValues } from './Onboarding/onboarding-types'
import {
  getOnboardingInitialValues,
  getOnboardingValidationSchema
} from './Onboarding/onboarding-types'

// Types
type EmployeeValues = BasicInfoValues &
  JobsAndPayValues &
  BenefitsValues &
  OnboardingValues

const t = (str: string) => str

// validation schema
const getCombinedValidationSchema = () => {
  const basicInfoSchema = getBasicInformationValidationSchema({
    t,
    hasInitialSsn: false,
    isNewHire: true
  })
  const jobsAndPaySchema = getJobsAndPayValidationSchema({
    t,
    eeoEnabled: false,
    isNewHire: true,
    positionPaygroupHash: {},
    isPOS: false
  })
  const benefitsSchema = getBenefitsValidationSchema({ t, benefits: [] })
  const onboardingSchema = getOnboardingValidationSchema({ t })
  return basicInfoSchema
    .concat(jobsAndPaySchema)
    .concat(benefitsSchema)
    .concat(onboardingSchema)
}

// initial values
type InitialValuesArgs = Parameters<typeof getInitialBasicInfoValues>[0] &
  Parameters<typeof getBenefitsInitialValues>[0] &
  Parameters<typeof getJobsAndPayInitialValues>[0]

const getInitialValues = (args: InitialValuesArgs) => {
  const { benefits, jobsAndPay, basicInfo } = args

  const initialBasicInfoValues = getInitialBasicInfoValues({ basicInfo })

  const jobsAndPayValues = getJobsAndPayInitialValues({ jobsAndPay })

  const benefitsValues = getBenefitsInitialValues({ benefits })

  const onboardingValues = getOnboardingInitialValues()

  return {
    ...initialBasicInfoValues,
    ...jobsAndPayValues,
    ...benefitsValues,
    ...onboardingValues
  }
}

// exports
export type { EmployeeValues }

export { getCombinedValidationSchema, getInitialValues }
