import { Yup } from '../../components'
import { Benefit, T } from '../../types'
import pickBy from 'lodash/pickBy'
import some from 'lodash/some'

type BenefitsValues = {
  benefitPlan?: string
  acaPlan?: string
  tafwPolicy?: string
  workersComp?: string
  wli?: string
}

type FilterBenefitsType = {
  benefits: Benefit[]
  benefitsAndACAEnabled: boolean
  tafwEnabled: boolean
}

const filterBenefits = (args: FilterBenefitsType) => {
  const { benefits, benefitsAndACAEnabled, tafwEnabled } = args
  return [...benefits].filter((b) => {
    if (b.key === 'benefitPlan' || b.key === 'acaPlan') {
      return benefitsAndACAEnabled
    }
    if (b.key === 'tafwPolicy') {
      return tafwEnabled
    }
    return true
  })
}

const BENEFITS_CONFIG = [
  {
    key: 'benefitPlan',
    labelKey: 'benefitPlan'
  },
  {
    key: 'acaPlan',
    labelKey: 'acaPlan'
  },
  {
    key: 'tafwPolicy',
    labelKey: 'tafwPolicy'
  },
  {
    key: 'workersComp',
    labelKey: 'workersComp'
  },
  {
    key: 'wli',
    labelKey: 'wli'
  }
] as const

const getFullBenefitsValidator = ({ t }: { t: T }) => {
  return {
    benefitPlan: Yup.string().required(t('selectBenefitPlan')),
    acaPlan: Yup.string().required(t('selectACA')),
    tafwPolicy: Yup.string().required(t('selectTAFW')),
    workersComp: Yup.string().required(t('selectWorkersComp')),
    wli: Yup.string().required(t('selectWLI'))
  }
}

type InitialArgs = {
  benefits: Benefit[]
}

const getBenefitsInitialValues = ({
  benefits
}: InitialArgs): BenefitsValues => {
  const initialValues = {}
  if (benefits) {
    benefits.forEach((benefit) => {
      // @ts-ignore: Ignoring because typescript doesn't like when we build objects like this but this is a valid way to build a BenefitsValues object
      initialValues[benefit.key] =
        benefit?.options?.length === 1 ? benefit.options[0].value : ''
    })
  }

  return initialValues
}

type Args = {
  t: T
  benefits: Benefit[]
}

const getBenefitsValidationSchema = ({ t, benefits }: Args) => {
  const schema = pickBy(getFullBenefitsValidator({ t }), (val, key) => {
    return some(benefits, (benefit) => benefit.key === key)
  })
  return Yup.object(schema)
}

export type { BenefitsValues }

export {
  getBenefitsValidationSchema,
  getBenefitsInitialValues,
  filterBenefits,
  BENEFITS_CONFIG
}
