import * as React from 'react'
import cx from 'classnames'
import { StepContent } from './StepContent'

export interface StepProps extends BaseStepProps {
  size: 'sm' | 'default'
  circleContent: 'icon' | number
  labelOn: 'side-horizontal' | 'below' | 'side-vertical'
  isFirst: boolean
  isLast: boolean
}

export interface BaseStepProps {
  testId?: string | number
  state: 'complete' | 'active' | 'inactive' | 'error'
  title?: string
  description?: string
  disableTruncate?: boolean
  renderStep?: React.FC<React.PropsWithChildren<StepProps>>
}

export interface StepContainerProps extends StepProps {
  children: React.ReactNode
  className?: string
}

export const StepContainer = ({
  testId = `Step`,
  labelOn = 'side-horizontal',
  disableTruncate = false,
  size,
  className,
  children
}: StepContainerProps) => {
  return (
    <div
      data-testid={testId}
      className={cx(
        {
          'flex flex-col items-stretch': labelOn === 'below',
          'flex items-stretch space-x-2':
            labelOn === 'side-vertical' || labelOn === 'side-horizontal',
          truncate: labelOn !== 'side-vertical' && !disableTruncate
        },
        className
      )}
      style={{
        minWidth: size === 'default' ? '2.25rem' : '1.5rem'
      }}
    >
      {children}
    </div>
  )
}

export const Step = (props: StepProps) => {
  const { testId = `Step`, labelOn = 'side-horizontal', size, ...rest } = props
  return (
    <StepContainer {...props}>
      <StepContent {...rest} labelOn={labelOn} size={size} testId={testId} />
    </StepContainer>
  )
}
