import * as React from 'react'
import cx from 'classnames'
import { MAIN_CARD_MAX_WIDTH_PX } from '../../constants'

export interface PageBodyProps {
  testId?: string | number
  className?: string
  children?: React.ReactNode
}

export const PageBody = ({
  testId = 'page-body',
  className,
  children
}: PageBodyProps) => {
  return (
    <div
      data-testid={testId}
      className={cx(
        className,
        'flex flex-row flex-grow',
        'md:mx-10 lg:mx-0 md:pt-10 lg:pt-0',
        'lg:min-h-0',
        'md:rounded-t-card'
      )}
    >
      <div
        className={cx(
          'card-elevation-flush md:card-elevation lg:shadow-none',
          'bg-white lg:bg-none',
          'sm:static w-full lg:h-full lg:contents mx-auto',
          'md:rounded-card'
        )}
        style={{
          maxWidth: MAIN_CARD_MAX_WIDTH_PX
        }}
      >
        <div
          className={cx(
            'w-full h-full md:bg-white lg:contents',
            'md:px-16 lg:px-10 pb-28',
            'md:rounded-t-card'
          )}
        >
          {children}
        </div>
      </div>
    </div>
  )
}
