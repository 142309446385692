import * as React from 'react'
import { useTranslation } from '@local/translations'
import {
  NavigationContainer,
  BackButton,
  ContinueButton,
  CompleteButton
} from '@toasttab/buffet-pui-wizard-templates'
import { AutorenewIcon } from '@toasttab/buffet-pui-icons'
import type { TrackPages } from '@local/track'
import { trackAddEmployee } from '@local/track'

type Props = {
  onBack?: () => void
  backDisabled?: boolean
  onNext: () => void
  showSubmit?: boolean // props shape still kind of up in the air here
  isInProgress?: boolean
  trackId: TrackPages
}

const WizardFooter = (props: Props) => {
  const { t } = useTranslation()
  const { backDisabled, onNext, onBack, showSubmit, isInProgress, trackId } =
    props

  return (
    // don't show the back button on the first page
    <NavigationContainer>
      {!backDisabled && (
        <BackButton
          onClick={onBack}
          testId='addEmployeeBackButton'
          {...trackAddEmployee(`${trackId}-back`)}
        >
          {t('back')}
        </BackButton>
      )}

      {showSubmit ? (
        <CompleteButton
          {...trackAddEmployee(`${trackId}-continue`)}
          onClick={onNext}
          testId={'addEmployeeNextButton'}
          disabled={isInProgress}
          iconLeft={
            isInProgress ? (
              <AutorenewIcon
                className='animate-spin'
                accessibility='decorative'
              />
            ) : null
          }
        >
          {t('addEmployee')}
        </CompleteButton>
      ) : (
        <ContinueButton
          {...trackAddEmployee(`${trackId}-continue`)}
          onClick={onNext}
          testId='addEmployeeNextButton'
          disabled={isInProgress}
          iconLeft={
            isInProgress ? (
              <AutorenewIcon
                className='animate-spin'
                accessibility='decorative'
              />
            ) : null
          }
        >
          {t('continue')}
        </ContinueButton>
      )}
    </NavigationContainer>
  )
}

export { WizardFooter }
