import * as React from 'react'
import { TextInput as BuffetTextInput } from '@toasttab/buffet-pui-text-input'

import { IconButton } from '@toasttab/buffet-pui-buttons'
import { Suffix } from '@toasttab/buffet-pui-text-base'

import { useField } from 'formik'

type IconType = React.ComponentProps<typeof IconButton>['icon']

type BaseProps = Omit<React.ComponentProps<typeof BuffetTextInput>, 'onChange'>

type Props = BaseProps & {
  testId?: string
  icon?: IconType
  fieldName: string
  onChange?: (date: string) => void
}

const TextInput = (props: Props) => {
  const {
    fieldName,
    testId,
    icon,
    errorText,
    invalid,
    name,
    onChange,
    ...rest
  } = props

  const [, meta, helpers] = useField(fieldName || '')

  const { error, value, touched } = meta

  const suffix = icon ? <IconButton icon={icon} contained /> : undefined

  const suffixVariant = icon ? Suffix.Variant.iconButton : undefined

  const displayInvalid = invalid ?? touched ? !!error : false

  const displayError = errorText ?? touched ? error : undefined

  return (
    <BuffetTextInput
      {...rest}
      testId={testId || fieldName}
      containerClassName={'w-full mb-4 md:w-80 md:mb-6'}
      name={name || fieldName || ''}
      invalid={displayInvalid}
      errorText={displayError}
      suffix={suffix}
      suffixVariant={suffixVariant}
      value={value}
      onBlur={() => {
        helpers.setValue(value.trim())
        helpers.setTouched(true)
      }}
      onChange={(event) => {
        const value = event.target.value
        if (fieldName) helpers.setValue(value)
        if (onChange) onChange(value)
        helpers.setTouched(false)
      }}
      size='lg'
      data-toast-track-id={`add-employee-${fieldName}-input`}
    />
  )
}
export default TextInput
