import * as React from 'react'
import cx from 'classnames'
import { ScreenSize, useScreenSize } from '@toasttab/use-screen-size'

export interface PageAsideProps {
  testId?: string | number
  containerClassName?: string
  className?: string
  children?: React.ReactNode
}

export const PageAside = ({
  testId = 'page-aside',
  containerClassName,
  className,
  children
}: PageAsideProps) => {
  const screenSize = useScreenSize()
  const isMobile = screenSize <= ScreenSize.MD

  const parentCustomizedStyle = !isMobile
    ? {
        maxWidth: '960px',
        flexShrink: 4
      }
    : {}
  const childMaxWidthStyle = !isMobile ? { maxWidth: '608px' } : {}

  return (
    <div
      data-testid={testId}
      className={cx(
        containerClassName,
        'lg:mt-10 md:-mt-16 px-4 md:px-0',
        'bg-white w-full lg:flex lg:justify-between lg:items-center lg:bg-transparent'
      )}
      style={parentCustomizedStyle}
    >
      <aside
        data-testid={`${testId}-content`}
        className={cx(
          className,
          'pb-8 py-6 px-6 lg:py-16 lg:px-16 mb-6 lg:mb-0',
          'lg:overflow-auto',
          'flex flex-col w-full h-full relative rounded-card lg:rounded-none lg:bg-transparent',
          {
            /**
             * In mobile version, if right column has no children, the right column background color
             * should just be white. Otherwise, it should be gray.
             */
            'bg-page': children,
            'bg-white': !children
          }
        )}
        style={childMaxWidthStyle}
      >
        {children}
      </aside>
    </div>
  )
}
