import { Yup } from '../../components'
import type {
  CustomerPosition,
  GetSchemaArgs,
  JobsAndPay,
  T
} from '../../types'
import uniqueId from 'lodash/uniqueId'
import { POS_ACCESS_CODE_REGEX } from '../BasicInfo/basic-info-types'
import { positionPaygroupHash } from './JobsAndPayForm'

type Job = {
  id: string
  jobId: string
  workTaxLocationId: string
  wage: number | null
  locationId: string
  payGroupId: string
  customerPositionId: string
}

type JobsAndPayValues = {
  isHourly: boolean
  posAccessCode: string
  overtimeEligible: boolean
  securityRole: string
  salary: number | null
  eeoClassification: string | null
  jobs: Job[]
}

type Args = {
  jobsAndPay: JobsAndPay
}

const getJobsAndPayInitialValues = (args: Args): JobsAndPayValues => {
  const { jobsAndPay } = args
  const initSecurityRole =
    jobsAndPay?.payrollSecurityRoles?.length === 1
      ? jobsAndPay.payrollSecurityRoles[0].id
      : ''
  return {
    isHourly: true,
    overtimeEligible: true,
    securityRole: initSecurityRole,
    salary: null,
    posAccessCode: '',
    eeoClassification: null,
    jobs: [getInitializeJobsValue(jobsAndPay)]
  }
}

const getInitializeJobsValueForPOS = (
  existingJobs: Job[],
  jobsAndPay: JobsAndPay
): Job[] => {
  const { workTaxLocationId, payGroupId, customerPositionId } =
    getInitializeJobsValue(jobsAndPay)
  return existingJobs.map((j) => ({
    id: j.id,
    jobId: j.jobId,
    workTaxLocationId: workTaxLocationId,
    wage: null,
    locationId: j.locationId,
    payGroupId: payGroupId,
    customerPositionId: customerPositionId
  }))
}

/**
 * Initial Job Values is a combination of default values and pre-selected fields
 * if they are only one item that can be selected
 * @param jobsAndPay Job & Pay Data
 * @returns Initial Job Values
 */
const getInitializeJobsValue = (jobsAndPay: JobsAndPay): Job => {
  const { workTaxLocations, locations, payGroupsV2: payGroups } = jobsAndPay
  const initWork =
    workTaxLocations?.length === 1 ? workTaxLocations[0].id : null
  const initPayGroup = payGroups?.length === 1 ? payGroups[0].id : null
  const initLocation = locations?.length === 1 ? locations[0].id : null

  let initPosition = null

  if (initPayGroup) {
    const positions = positionPaygroupHash(jobsAndPay)[initPayGroup]
    if (positions.length === 1) {
      initPosition = positions[0].id
    } else {
      initPosition = ''
    }
  }

  const initJobId =
    initLocation && locations[0].jobs.length === 1
      ? locations[0].jobs[0].id
      : ''

  return {
    id: uniqueId('job-'),
    jobId: initJobId,
    workTaxLocationId: initWork || '',
    wage: null,
    locationId: initLocation || '',
    payGroupId: initPayGroup || '',
    customerPositionId: initPosition || ''
  }
}

const getJobsSchema = ({
  t,
  positionPaygroupHash
}: {
  t: T
  positionPaygroupHash: { [key: string]: CustomerPosition[] }
}) => {
  return Yup.object({
    jobId: Yup.string().required(t('fieldRequired')),
    workTaxLocationId: Yup.string().required(t('fieldRequired')),
    wage: Yup.number()
      .nullable()
      .max(99999999.99, t('amountTooLarge'))
      .min(0.0, t('negativeWageError')),
    locationId: Yup.string().required(t('fieldRequired')),
    payGroupId: Yup.string(),
    customerPositionId: Yup.string().when(
      'payGroupId',
      (payGroupId, schema) => {
        return positionPaygroupHash[payGroupId]?.length > 0
          ? schema.required(t('fieldRequired'))
          : schema
      }
    )
  })
}

type ValidationArgs = {
  t: T
  eeoEnabled: boolean
  isNewHire: boolean
  positionPaygroupHash: { [key: string]: CustomerPosition[] }
  isPOS: boolean
}

const isPosAccessCode = ({ t }: GetSchemaArgs) => {
  return Yup.string().when('validateAccessCode', {
    is: true,
    then: (schema) =>
      schema
        .required(t('posAccessCodeRequired'))
        .matches(POS_ACCESS_CODE_REGEX, t('posAccessCodeRegexFailed'))
  })
}

const getJobsAndPayValidationSchema = (args: ValidationArgs) => {
  const { t, eeoEnabled, isNewHire, positionPaygroupHash, isPOS } = args
  const MAXIMUM_WAGE = 99999999.99
  const MINIMUM_WAGE = 0.0

  return Yup.object({
    isHourly: Yup.boolean().required(t('fieldRequired')),
    overtimeEligible: Yup.boolean().required(t('fieldRequired')),
    salary: Yup.number()
      .typeError(t('fieldRequired'))
      .nullable()
      .max(MAXIMUM_WAGE, t('amountTooLarge'))
      .min(MINIMUM_WAGE, t('negativeWageError'))
      .when('isHourly', {
        is: false,
        then: (schema) => schema.required(t('fieldRequired'))
      }),
    securityRole: Yup.string().required(t('fieldRequired')),
    eeoClassification: eeoEnabled
      ? Yup.string().nullable().required(t('fieldRequired'))
      : Yup.string().nullable(),
    validateAccessCode: Yup.boolean().default(false),
    posAccessCode: isNewHire && !isPOS ? isPosAccessCode({ t }) : Yup.string(),
    jobs: Yup.array()
      .of(getJobsSchema({ t, positionPaygroupHash }))
      .min(1)
      .test('payGroupRequired', t('fieldRequired'), (jobs, context) => {
        // We do the test here since we need access to the parent context
        const { isHourly } = context.parent
        const errors = jobs!
          .map((job, index) => {
            if (index === 0 && !job.payGroupId) {
              return context.createError({
                path: 'jobs.0.payGroupId',
                message: t('fieldRequired')
              })
            }
            if (index > 0 && isHourly && !job.payGroupId) {
              return context.createError({
                path: `jobs.${index}.payGroupId`,
                message: t('fieldRequired')
              })
            }
            return null
          })
          .filter(Boolean)
        if (errors.length === 0) {
          return true
        }

        return new Yup.ValidationError(
          errors as Yup.ValidationError & Yup.ValidationError[]
        )
      })
      .test('wageRequired', t('fieldRequired'), (jobs, context) => {
        // We do the test here since we need access to the parent context
        const { isHourly } = context.parent
        const errors = jobs!
          .map((job, index) => {
            if (isHourly && job.wage === null) {
              return context.createError({
                path: `jobs.${index}.wage`,
                message: t('fieldRequired')
              })
            }
            return null
          })
          .filter(Boolean)
        if (errors.length === 0) {
          return true
        }

        return new Yup.ValidationError(
          errors as Yup.ValidationError & Yup.ValidationError[]
        )
      })
  })
}

const EARNING_TYPE_OPTIONS = [
  { labelKey: 'hourly', value: 'true', helpTextKey: 'hourlyHelpText' },
  { labelKey: 'salary', value: 'false', helpTextKey: 'salaryHelpText' }
] as const

const OVERTIME_OPTIONS = [
  { labelKey: 'yes', value: 'true', helpTextKey: 'overtimeEligibleHelpText' },
  { labelKey: 'no', value: 'false', helpTextKey: 'overtimeIneligibleHelpText' }
] as const

const EEO_OPTIONS = [
  { labelKey: 'eeoExecutiveSeniorLevelOfficialsAndManagers', value: '1' },
  { labelKey: 'eeoFirstMidLevelOfficialsAndManagers', value: '2' },
  { labelKey: 'eeoProfessionals', value: '3' },
  { labelKey: 'eeoTechnicians', value: '4' },
  { labelKey: 'eeoSalesWorkers', value: '5' },
  { labelKey: 'eeoAdministrativeSupportWorkers', value: '6' },
  { labelKey: 'eeoCraftWorkers', value: '7' },
  { labelKey: 'eeoOperatives', value: '8' },
  { labelKey: 'eeoLaborersAndHelpers', value: '9' },
  { labelKey: 'eeoServiceWorkers', value: '10' },
  { labelKey: 'eeoNone', value: '11' }
] as const

enum EEO_VALUES {
  EXECUTIVE_SENIOR_LEVEL_OFFICIALS_AND_MANAGERS = 1,
  FIRST_MID_LEVEL_OFICIALS_AND_MANAGERS = 2,
  PROFESSIONALS = 3,
  TECHNICIANS = 4,
  SALES_WORKERS = 5,
  ADMINISTRATIVE_SUPPORT_WORKERS = 6,
  CREAFT_WORKERS = 7,
  OPERATIVES = 8,
  LABORERS_AND_HELPERS = 9,
  SERVICE_WORKERS = 10,
  NONE = 11
}

export type { JobsAndPayValues, Job }
export {
  getJobsAndPayValidationSchema,
  getJobsAndPayInitialValues,
  getInitializeJobsValue,
  getInitializeJobsValueForPOS,
  OVERTIME_OPTIONS,
  EARNING_TYPE_OPTIONS,
  EEO_OPTIONS,
  EEO_VALUES
}
