import { WizardLayoutAnimationLayer } from './WizardParts'
import { SnackBarProvider } from '@toasttab/buffet-pui-snackbars'
import { RehireEmployeeRequirementsManager } from './RehireEmployeeRequirementsManager'

import { render } from './helpers'
import { ErrorPage } from './components/ErrorPage'
// todo: possibly refactor this to take children and combine with portal and providers to wrap both add and rehire

type Props = {
  isOpen: boolean
  employeeId: string
  initialRehireDate: string
  onCancel: () => void
  onSuccess: (employeeId: string | null) => void
}

// Load all necessary data on page
const RehireEmployeeModalApp = (props: Props) => {
  const { isOpen, employeeId, onCancel, onSuccess, initialRehireDate } = props
  return (
    <WizardLayoutAnimationLayer isOpen={isOpen}>
      {render(() => {
        if (isOpen) {
          return (
            <SnackBarProvider>
              <RehireEmployeeRequirementsManager
                initialRehireDate={initialRehireDate}
                employeeId={employeeId}
                onClose={onCancel}
                onSuccess={onSuccess}
              />
            </SnackBarProvider>
          )
        }

        // if we get here, something has gone very wrong, so just refresh the page
        return (
          <ErrorPage callback={window.location.reload} onClose={onCancel} />
        )
      })}
    </WizardLayoutAnimationLayer>
  )
}

export { RehireEmployeeModalApp }
