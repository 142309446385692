import { CA, IE, GB, US } from 'country-flag-icons/react/3x2'

export const flags = {
  CA,
  IE,
  GB,
  US
}

export type Flags = typeof flags
