import { Locale } from '@toasttab/buffet-pui-locale-utilities'
import { shouldLogWarning } from '@toasttab/buffet-utils'

import { Money, BuffetFormatOptions } from '../'
import { roundNumber } from './roundNumber'

export const format = (
  input: number | Money,
  locale: Locale,
  options: BuffetFormatOptions
) => {
  try {
    const number = typeof input === 'number' ? roundNumber(input, options) : NaN
    // eslint-disable-next-line @toasttab/buffet/hard-coded-currencies
    return Intl.NumberFormat(locale, {
      ...options
    }).format(number)
  } catch (e) {
    if (shouldLogWarning()) {
      console.warn('Buffet/Number Utilities error: ', e)
    }
    return ''
  }
}
