import * as React from 'react'
import { Chips, Chip } from '@toasttab/buffet-pui-chips'
import { FileUtils, getFileKey } from '../utils/FileUtils'
import { useFileSelector } from '../FileSelectorProvider/FileSelectorProvider'
import { t } from '../defaultStrings'

/**
 * File list component properties
 */
export interface FileListProps {
  testId?: string
  deletable?: boolean
}

/**
 * File list component
 * Lists files and allows them to be deleted
 */
export function FileSelectionList({
  testId = 'file-selector-list',
  deletable = true
}: FileListProps): JSX.Element | null {
  const { files, setFiles, onChange, invalidFiles } = useFileSelector()

  const deleteFile = (index: number) => {
    files.splice(index, 1)
    setFiles([...files])
    onChange?.({
      files: files,
      invalidFiles: invalidFiles
    })
  }

  if (!files.length) {
    return null
  }

  return (
    <div data-testid={testId} className='text-secondary'>
      <Chips testId={`${testId}-chips`} className='justify-center'>
        <p className='sr-only'>{t('selected-files')}</p>
        {files.map((file, index) => {
          const fileSizeMessage = t('file-size', {
            fileSize: FileUtils.getFileDisplaySize(file.size)
          })
          return (
            <Chip
              key={getFileKey(file)}
              onClick={() => deleteFile(index)}
              deletable={deletable}
              label={
                <span className='break-all'>
                  {file.name}
                  <span className='pl-2 text-secondary'>{fileSizeMessage}</span>
                </span>
              }
              ariaLabel={`${file.name}${deletable && t('click-to-remove')}}`}
              testId={`${testId}-chip-${index}`}
            />
          )
        })}
      </Chips>
    </div>
  )
}
