import {
  track as ecTrack,
  useTracking as useEcTracking
} from '@toasttab/ec-track'

type DataToastTrackIdProps = {
  'data-toast-track-id': TrackId
}

type AddEmployeeTrackId = 'add-employee'
export type TrackPages =
  | 'basic-information'
  | 'wizard-page'
  | 'review'
  | 'documents'
  | 'jobs-and-pay'
  | 'benefits'

type AddEmployeeActionTrackIds =
  | 'basic-information-continue'
  | 'basic-information-back'
  | 'wizard-page-continue'
  | 'wizard-page-back'
  | 'review-continue'
  | 'review-back'
  | 'documents-continue'
  | 'documents-back'
  | 'jobs-and-pay-continue'
  | 'jobs-and-pay-back'
  | 'benefits-continue'
  | 'benefits-back'

export const trackAddEmployee = (
  actionTrackId: AddEmployeeActionTrackIds
): DataToastTrackIdProps => {
  return track(`add-employee.${actionTrackId}`)
}

type AddEmployeeModalTrackId = 'add-employee-modal'
type ModalsTrackId =
  | 'discard-and-close'
  | 'exit-resume'
  | 'primary'
  | 'secondary'
  | 'error-submit'
  | 'error-close'
  | 'rehire-submit'
  | 'rehire-close'
  | 'newhire-with-email-submit'
  | 'newhire-with-email-close'
  | 'newhire-without-email-submit'
  | 'newhire-without-email-close'

export const trackModalClicks = (
  actionTrackId: ModalsTrackId
): DataToastTrackIdProps => {
  return track(`add-employee-modal.${actionTrackId}`)
}

type AddEmployeeSubmitTrackId = 'add-employee-submit'
type SubmittingTrackIds = 'hire' | 'rehire'

export const trackSubmit = (
  actionTrackId: SubmittingTrackIds
): DataToastTrackIdProps => {
  return track(`add-employee-submit.${actionTrackId}`)
}

export type TrackId =
  | `${AddEmployeeTrackId}.${AddEmployeeActionTrackIds}`
  | `${AddEmployeeSubmitTrackId}.${SubmittingTrackIds}`
  | `${AddEmployeeModalTrackId}.${ModalsTrackId}`

const track = (trackId: TrackId): { 'data-toast-track-id': TrackId } =>
  ecTrack(trackId) as DataToastTrackIdProps

export const useTracking = (): { track: (trackId: TrackId) => void } => {
  const { track: ecTrack } = useEcTracking()
  const track = (trackId: TrackId) => {
    ecTrack(trackId)
  }
  return { track }
}
