import { ScreenSize, useScreenSize } from '@toasttab/use-screen-size'

const useIsMobile = () => {
  const screenSize = useScreenSize()

  const isMobile = screenSize <= ScreenSize.MD

  return { isMobile }
}

export { useIsMobile }
