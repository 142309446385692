import * as React from 'react'
import { useFormik, FormikProvider } from 'formik'
import { useOnDraftChange } from '../../drafts'
import { useAddEmployeeContext, useIsMobile } from '../../hooks'
import { useTranslation } from '@local/translations'
import { Dropdown } from '../../components'
import type { BenefitsValues } from './benefits-types'
import { Benefit } from '../../types'
import { getBenefitsValidationSchema, BENEFITS_CONFIG } from './benefits-types'
import keyBy from 'lodash/keyBy'
import { ContentFooter, PageMain } from '@toasttab/buffet-pui-wizard-templates'
import { WizardFooter } from '../../WizardParts/WizardFooter'
import DesktopFacts, { DesktopFactsItems } from '../../components/DesktopFacts'
import { InfoDisplayIcon } from '@toasttab/buffet-pui-icons'

export type Props = {
  benefits: Benefit[]
  numRunPayrollsInThePastYear: number
}

const BenefitsForm = (props: Props) => {
  const { benefits, numRunPayrollsInThePastYear } = props
  const { employeeValues, onChange, onNext, onBack } = useAddEmployeeContext()
  const { t } = useTranslation()
  const { isMobile } = useIsMobile()

  const validationSchema = React.useMemo(() => {
    return getBenefitsValidationSchema({ t, benefits })
  }, [t, benefits])

  const formik = useFormik<BenefitsValues>({
    initialValues: employeeValues,
    validationSchema,
    onSubmit: (data) => {
      onChange(data)
      onNext()
    }
  })

  const benefitsByKey = keyBy(benefits, 'key')

  const visibleBenefits = BENEFITS_CONFIG.filter((config) => {
    return benefitsByKey[config.key]
  })

  useOnDraftChange({ values: formik.values, onChange })

  const benefitsDesktopFacts: DesktopFactsItems = [
    {
      Icon: InfoDisplayIcon,
      header: t('desktopFactBenefitsHeader'),
      description: (
        <>
          {t('desktopFactBenefitsDescription')}
          <span className='text-link'>payrollsupport@toasttab.com</span>.
        </>
      )
    }
  ]

  return (
    <>
      <PageMain>
        <FormikProvider value={formik}>
          <div className='mb-6 font-bold type-headline-4 text-default'>
            {t('benefits')}
          </div>
          <div className='flex-wrap lg:flex gap-x-6'>
            {visibleBenefits.map((benefit) => {
              const { labelKey, key } = benefit
              const mappedBenefit = benefitsByKey[key]
              return (
                <div className='content-end' key={benefit.key}>
                  <Dropdown
                    testId={`${benefit.key}-select`}
                    fieldName={key}
                    label={key === 'wli' ? mappedBenefit.label : t(labelKey)}
                    key={key}
                    options={mappedBenefit.options}
                    required
                    enableSearch
                  />
                </div>
              )
            })}
          </div>
          <ContentFooter>
            <WizardFooter
              onBack={onBack}
              backDisabled={false}
              onNext={() => formik.handleSubmit()}
              showSubmit={false}
              trackId='benefits'
            />
          </ContentFooter>
        </FormikProvider>
      </PageMain>
      {!isMobile && numRunPayrollsInThePastYear >= 2 && (
        <DesktopFacts
          items={benefitsDesktopFacts}
          testId={'desktop-facts-benefits'}
        />
      )}
    </>
  )
}

export { BenefitsForm }
