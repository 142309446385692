import { DefaultEarningsId, useFindCustomerSettingsQuery } from '@local/api'
import {
  EEO_ENABLED_CUSTOMER_SETTING,
  SSN_REQUIRED_CUSTOMER_SETTING,
  TAFW_ENABLED_CUSTOMER_SETTING,
  BENEFITS_PLAN_ENABLED
} from './helpers/customer-settings-gql'
import compact from 'lodash/compact'
import { JobsAndPay } from './types'
import { filterBenefits } from './pages/Benefits/benefits-types'

export const CustomerConfigurations = () => {
  const {
    data: customerData,
    error: customerError,
    loading: customerLoading,
    refetch
  } = useFindCustomerSettingsQuery({
    variables: {
      settings: [
        EEO_ENABLED_CUSTOMER_SETTING,
        SSN_REQUIRED_CUSTOMER_SETTING,
        TAFW_ENABLED_CUSTOMER_SETTING,
        BENEFITS_PLAN_ENABLED
      ]
    },
    notifyOnNetworkStatusChange: true
  })

  const jobsAndPay = customerData?.customerConfiguration ?? ({} as JobsAndPay)
  const defaultEarnings = jobsAndPay.defaultEarningsIds as DefaultEarningsId

  const benefits = compact(customerData?.customerBenefits)

  const numRunPayrollsInThePastYear =
    customerData?.numRunPayrollsInThePastYear ?? 0

  const eeoEnabled =
    customerData?.findCustomerSettings.find(
      (cs) => cs.id === EEO_ENABLED_CUSTOMER_SETTING
    )?.value === 'true'

  const ssnRequired =
    customerData?.findCustomerSettings.find(
      (cs) => cs.id === SSN_REQUIRED_CUSTOMER_SETTING
    )?.value === 'true'

  const benefitsAndACASetting = customerData?.findCustomerSettings.find(
    (cs) => cs.id === BENEFITS_PLAN_ENABLED
  )?.value

  const tafwSetting = customerData?.findCustomerSettings.find(
    (cs) => cs.id === TAFW_ENABLED_CUSTOMER_SETTING
  )?.value

  const benefitsAndACAEnabled =
    benefitsAndACASetting === 'true' || benefitsAndACASetting === '1'

  const tafwEnabled = tafwSetting === 'true' || tafwSetting === '1'

  const activeBenefits = filterBenefits({
    benefits,
    benefitsAndACAEnabled,
    tafwEnabled
  })

  const filteredJobsAndPay = {
    ...jobsAndPay,
    defaultEarningsIds: defaultEarnings
  }

  return {
    customerData: customerData,
    customerError: customerError,
    customerLoading: customerLoading,
    refetch: refetch,
    eeoEnabled: eeoEnabled,
    ssnRequired: ssnRequired,
    filteredJobsAndPay: filteredJobsAndPay,
    activeBenefits: activeBenefits,
    numRunPayrollsInThePastYear: numRunPayrollsInThePastYear
  }
}
