import * as React from 'react'
import type { EmployeeValues } from '../combined-values'
import { StepKey } from '../../domain/steps'
import { ReviewAboutEmployee } from './ReviewAboutEmployee'
import { DividingLine } from '@toasttab/buffet-pui-config-templates'
import { ReviewJobsAndPay } from './ReviewJobsAndPay'
import { ReviewBenefits } from './ReviewBenefits'
import { ReviewGeneralJobInfo } from './ReviewGeneralJobInfo'
import { ReviewOnboarding } from './ReviewOnboarding'
import { JobsAndPay, Benefit } from '../../types'
import isEmpty from 'lodash/isEmpty'
import { ContentFooter, PageMain } from '@toasttab/buffet-pui-wizard-templates'
import { WizardFooter } from '../../WizardParts/WizardFooter'
import { useIsMobile, useTranslation } from '../../hooks'
import { InfoDisplayIcon } from '@toasttab/buffet-pui-icons'
import DesktopFacts, { DesktopFactsItems } from '../../components/DesktopFacts'
import LinkButton from '../../components/LinkButton'

type Props = {
  setActiveStep: (step: StepKey) => void
  employeeValues: EmployeeValues
  onBack: () => void
  onSubmit: () => void
  loading: boolean
  jobsAndPay: JobsAndPay
  benefits: Benefit[]
  isLoading: boolean
  hasInitialSsn: boolean
  isNewHire: boolean
}

const ReviewForm = (props: Props) => {
  const {
    setActiveStep,
    employeeValues,
    onBack,
    onSubmit,
    jobsAndPay,
    benefits,
    isLoading,
    hasInitialSsn,
    isNewHire
  } = props

  const { isMobile } = useIsMobile()
  const { t } = useTranslation()

  const reviewDesktopFacts: DesktopFactsItems = [
    {
      Icon: InfoDisplayIcon,
      header: t('desktopFactReviewLastStepHeader'),
      description: t('desktopFactReviewLastStepDescription')
    },
    {
      Icon: InfoDisplayIcon,
      header: t('desktopFactReviewNextHeader'),
      description: (
        <>
          {t('desktopFactReviewNextDescription')}
          <LinkButton
            linkUrl={
              'https://central.toasttab.com/s/article/Toast-Payroll-Employee-New-Hire-Guide'
            }
            linkText={t('desktopFactReviewNextArticle')}
          />
          .
        </>
      )
    }
  ]

  const showBenefits = !isEmpty(benefits)

  return (
    <>
      <PageMain>
        <ReviewAboutEmployee
          setActiveStep={setActiveStep}
          employeeValues={employeeValues}
          hasInitialSsn={hasInitialSsn}
        />

        <div className={'mb-2 md:mb-4'}>
          <DividingLine />
        </div>

        <ReviewJobsAndPay
          setActiveStep={setActiveStep}
          employeeValues={employeeValues}
          jobsAndPay={jobsAndPay}
        />

        <div className={'mb-2 md:mb-4'}>
          <DividingLine />
        </div>

        <ReviewGeneralJobInfo
          setActiveStep={setActiveStep}
          employeeValues={employeeValues}
          jobsAndPay={jobsAndPay}
          isNewHire={isNewHire}
        />

        {showBenefits && (
          <>
            <div className={'mb-2 md:mb-4'}>
              <DividingLine />
            </div>
            <ReviewBenefits
              setActiveStep={setActiveStep}
              employeeValues={employeeValues}
              benefits={benefits}
            />
          </>
        )}

        <div className={'mb-2 md:mb-4'}>
          <DividingLine />
        </div>

        <ReviewOnboarding
          setActiveStep={setActiveStep}
          employeeValues={employeeValues}
        />
        <ContentFooter>
          <WizardFooter
            onBack={onBack}
            backDisabled={false}
            onNext={onSubmit}
            showSubmit={true}
            isInProgress={isLoading}
            trackId='review'
          />
        </ContentFooter>
      </PageMain>
      {!isMobile && <DesktopFacts items={reviewDesktopFacts} />}
    </>
  )
}

export { ReviewForm }
export type { Props }
