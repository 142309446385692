import * as React from 'react'
import cx from 'classnames'
import { TestIdentifiable } from '@toasttab/buffet-shared-types'
import { AddIcon } from '@toasttab/buffet-pui-icons'
import { Size } from '../DashBorderButton'
export interface DashBorderButtonContentProps extends TestIdentifiable {
  loading?: React.ReactNode
  iconLeft?: React.ReactNode
  loadingLabel?: string | React.ReactNode
}

const getButtonVariantClass = (
  invalid?: boolean,
  disabled?: boolean,
  loading?: boolean,
  noFill?: boolean
) => {
  const transparentBackground = noFill && 'bg-transparent'

  if (disabled) {
    return cx(
      transparentBackground || 'bg-darken-8',
      'border-darken-12 text-disabled cursor-default'
    )
  } else if (invalid) {
    return cx(
      transparentBackground || 'bg-error-0',
      'border-error text-error hover:border-error-hover hover:text-error-hover focus-visible:shadow-focus'
    )
  } else if (loading) {
    return cx(
      transparentBackground || 'bg-darken-8',
      'text-disabled cursor-default'
    )
  } else {
    return cx(
      transparentBackground || 'bg-primary-0',
      'border-primary-75 hover:border-primary-100 text-link hover:text-primary-100 focus-visible:shadow-focus'
    )
  }
}

const getButtonSizeClass = (size?: Size) => {
  switch (size) {
    case 'sm':
      return 'h-10'
    case 'lg':
    case 'base':
      return 'h-12'
    case 'auto':
    default:
      return 'h-12 md:h-10'
  }
}

export const useDashBorderButtonStyles = ({
  className,
  iconLeft = <AddIcon accessibility='decorative' />,
  size,
  invalid,
  disabled,
  loading,
  noFill
}: {
  className?: string
  iconLeft?: React.ReactNode
  size?: Size
  invalid?: boolean
  disabled?: boolean
  loading?: boolean
  noFill?: boolean
}) => {
  const buttonVariantClassName = getButtonVariantClass(
    invalid,
    disabled,
    loading,
    noFill
  )

  return cx(
    'text-center',
    'border border-dashed rounded-button outline-none',
    'relative inline-flex items-center justify-center min-w-button space-x-2',
    'transition ease-in-out duration-200',
    'pr-6',
    buttonVariantClassName,
    getButtonSizeClass(size),
    className,
    {
      'pl-6': !iconLeft,
      'pl-3': iconLeft
    }
  )
}

export const DashBorderButtonContent: React.FC<
  React.PropsWithChildren<DashBorderButtonContentProps>
> = ({
  iconLeft = <AddIcon accessibility='decorative' />,
  children,
  loading,
  testId,
  loadingLabel
}) => {
  const buttonContent = loading ? loadingLabel : children

  return (
    <>
      {!loading && <IconForButton icon={iconLeft} />}
      <span
        data-testid={`${testId}-button-content`}
        className={cx(
          {
            'text-secondary': loading
          },
          'truncate',
          'type-default font-semibold',
          'my-px'
        )}
      >
        {buttonContent}
      </span>
    </>
  )
}

const IconForButton = ({ icon }: { icon: React.ReactNode }) =>
  icon ? <span className='flex items-center mt-px'>{icon}</span> : null
