import React from 'react'
import cx from 'classnames'
import {
  TooltipTextLayout,
  TooltipTextLayoutProps
} from '@toasttab/buffet-pui-tooltip'

export interface ReadonlyTextProps {
  label: React.ReactNode
  helperIconButton?: TooltipTextLayoutProps['helperIconButton']
  value: string | JSX.Element
  truncate?: boolean
  className?: string
  containerClassName?: string
  testId?: string | number
}

export const ReadonlyText: React.FC<ReadonlyTextProps> = ({
  className,
  containerClassName,
  label,
  helperIconButton,
  testId,
  truncate,
  value,
  ...props
}) => {
  return (
    <article className={containerClassName} data-testid={`${testId}-container`}>
      {helperIconButton ? (
        <TooltipTextLayout
          helperIconButton={helperIconButton}
          className='mb-1'
          iconSize='xs'
        >
          <header className='inline-block type-default font-semibold'>
            {label}
          </header>
        </TooltipTextLayout>
      ) : (
        <header className='mb-1 inline-block type-default font-semibold'>
          {label}
        </header>
      )}

      <p
        className={cx('type-default', { truncate }, className)}
        data-testid={testId}
        {...props}
      >
        {value}
      </p>
    </article>
  )
}
