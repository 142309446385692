import * as React from 'react'
import { useTranslation } from '@local/translations'
import { StepKey } from '../../domain/steps'
import { EmployeeValues } from '../index'
import { ReadonlyText } from '../../components'
import { IconButton } from '@toasttab/buffet-pui-buttons'
import { EditIcon, ExpandIcon, LaunchIcon } from '@toasttab/buffet-pui-icons'
import { useState } from 'react'
import { Alert } from '@toasttab/buffet-pui-alerts'
import { useAddEmployeeContext } from '../../hooks'

type Props = {
  setActiveStep: (step: StepKey) => void
  employeeValues: EmployeeValues
}

const ReviewOnboarding = (props: Props) => {
  const { employeeValues, setActiveStep } = props
  const { t } = useTranslation()
  const [isExpanded, setIsExpanded] = useState(false)
  const hasEmail = employeeValues.willProvideEmail
  const selectedDocuments = employeeValues.selectedDocuments.filter(
    (d) => d.checked
  )
  const numDocs =
    selectedDocuments.length + employeeValues.additionalDocuments.length
  const { displayName } = useAddEmployeeContext()

  return (
    <>
      <div
        data-testid='employeeOnboarding'
        className={'font-bold type-headline-5 text-default mb-2'}
      >
        {t('employeeOnboarding')}
      </div>
      <div className={'mb-4 md:mb-6'}>{t('employeeOnboardingSubtext')}</div>
      {!hasEmail ? (
        <Alert
          variant='warning'
          className='mb-6'
          testId='noEmailDocumentsWarning'
        >
          {t('cantReviewDocsNoEmailWarning', {
            name: displayName
          })}
          <button
            className='inline-link-inherit'
            onClick={() => setActiveStep('basic')}
          >
            {t('addEmailOnboarding')}
          </button>
          {t('cantReviewDocsNoEmailWarningCont')}
        </Alert>
      ) : null}

      <ReadonlyText
        label={`1. ${t('completePayrollAccount')}`}
        value={<div className='ml-4'>{t('completePayrollAccountSubtext')}</div>}
      />

      <ReadonlyText
        label={`2. ${t('completePersonalInformation')}`}
        value={
          <div className='ml-4'>{t('completePersonalInformationSubtext')}</div>
        }
      />

      <ReadonlyText
        label={`3. ${t('fillOutI9')}`}
        value={<div className='ml-4'>{t('fillOutI9Subtext')}</div>}
      />

      <ReadonlyText
        label={`4. ${t('fillOutW4')}`}
        value={<div className='ml-4'>{t('fillOutW4Subtext')}</div>}
      />

      <ReadonlyText
        label={
          <>
            {`5. ${t('reviewDocuments')}`}{' '}
            <IconButton
              cropToIcon
              icon={<EditIcon aria-label={'edit documents'} className='ml-2' />}
              onClick={() => setActiveStep('documents')}
              data-toast-track-id='add-employee-return-to-documents-icon-link'
            />
          </>
        }
        value={
          numDocs === 0 ? (
            <div className='ml-4'>{t('noDocumentsOnboarding')}</div>
          ) : (
            <div
              data-testid='displayDocuments'
              className={'ml-4 text-link cursor-pointer'}
              onClick={() => setIsExpanded(!isExpanded)}
            >
              {isExpanded ? t('hideDocuments') : t('viewDocuments')}
              <ExpandIcon
                aria-label={'expand documents'}
                isExpanded={isExpanded}
              />
            </div>
          )
        }
        children={
          isExpanded && (
            <div className='mt-2'>
              {selectedDocuments.map((doc) => (
                <div
                  data-testid='reviewDocuments'
                  key={doc.id}
                  className='mb-2 ml-6'
                >
                  • {doc.name}
                </div>
              ))}
              {employeeValues.additionalDocuments.map((doc) => (
                <div
                  data-testid='reviewDocuments'
                  key={doc.id}
                  className='mb-2 ml-6'
                >
                  • {doc.fileName}
                </div>
              ))}
            </div>
          )
        }
      />

      <div>
        <ReadonlyText
          label={`6. ${t('selectPaymentMethod')}`}
          value={<div className='ml-4'>{t('selectPaymentMethodSubtext')}</div>}
        />
      </div>

      <a
        target='_blank'
        href='https://central.toasttab.com/s/article/Toast-Payroll-Employee-New-Hire-Guide'
        className='flex mb-6 font-semibold align-middle text-link mt-7'
        rel='noreferrer'
        data-toast-track-id='add-employee-learn-more-onboarding-link'
      >
        {t('learnMoreOnboarding')}
        <LaunchIcon accessibility='decorative' className='ml-3' size='sm' />
      </a>
    </>
  )
}

export { ReviewOnboarding }
export type { Props }
