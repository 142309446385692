import * as React from 'react'
import cx from 'classnames'
import { ScreenSize, useScreenSize } from '@toasttab/use-screen-size'

// Tablet screen size is a single column
// And the implementation of card padding and margin
// moves to PageBody, where it is shared by PageMain and PageAside.
// This constant allows the margin to be 40px and padding to be 64px
// At a screen width of 768px to 948px,
// after which the margin and padding grows to limit the
// growth of the content width to 740px, which is the maximum
// content width of the two-column layout
const TABLET_MAX_CONTENT_WIDTH = '740px'

// These constants for _narrow_ PageMain component
// allow the PageMain component to reach a
// minimum content width of 288px at a screen width of 1024px
// and maximum card width of 576px at a screen width of 1440px
const LARGE_NARROW_FLEX_SHRINK = 4.6667
const LARGE_NARROW_MAX_WIDTH = '576px'

// These constants for _default_ PageMain component
// allow the accompanying _PageAside_ component to reach a
// minimum content width of 288px at a screen width of 1024px
// while the PageMain component attains
// and maximum card width of 868px at a screen width of1440px
const LARGE_FLEX_SHRINK = 2.625
const XLARGE_FLEX_SHRINK = 2.29
const LARGE_MAX_WIDTH = '868px'

export interface PageMainProps {
  testId?: string | number
  containerClassName?: string
  isNarrow?: boolean
  children: React.ReactNode
}

export const PageMain = ({
  testId = 'page-main-column',
  containerClassName,
  isNarrow = false,
  children
}: PageMainProps) => {
  const screenSize = useScreenSize()
  const isLargeNarrowScreen = screenSize > ScreenSize.MD && isNarrow
  const isTablet = screenSize === ScreenSize.MD
  const isDesktop = screenSize > ScreenSize.MD
  const isXlDesktop = screenSize > ScreenSize.LG

  const customizedContainerStyles = isLargeNarrowScreen
    ? { maxWidth: LARGE_NARROW_MAX_WIDTH, flexShrink: LARGE_NARROW_FLEX_SHRINK }
    : isDesktop
    ? {
        maxWidth: LARGE_MAX_WIDTH,
        flexShrink: isXlDesktop ? XLARGE_FLEX_SHRINK : LARGE_FLEX_SHRINK
      }
    : isTablet
    ? { maxWidth: TABLET_MAX_CONTENT_WIDTH }
    : {}

  return (
    <div
      data-testid={testId}
      className={cx(
        containerClassName,
        'mx-auto lg:mt-10 lg:ml-10 lg:mr-0 px-4 md:px-0 py-8 md:py-0 lg:card-elevation w-full bg-white lg:relative md:rounded-t-card'
      )}
      style={customizedContainerStyles}
    >
      <main
        data-testid={`${testId}-content`}
        className={
          'mx-auto md:pt-12 md:pb-24 lg:px-16 flex flex-col w-full h-full lg:overflow-auto'
        }
      >
        {children}
      </main>
    </div>
  )
}
