import { useTranslation } from '../../hooks'
import { NumberInput, Dropdown } from '../../components'
import { DividingLine } from '@toasttab/buffet-pui-config-templates'
import { CustomerPosition, Option } from '../../types'
import { DeleteIcon } from '@toasttab/buffet-pui-icons'
import { Button } from '@toasttab/buffet-pui-buttons'
import { Job } from './jobs-and-pay-types'
import { useFormikContext } from 'formik'
import { EmployeeValues } from '../combined-values'

type Props = {
  index: number
  job: Job
  workTaxLocationOptions: Option[]
  payGroupOptions: Option[]
  locationOptions: Option[]
  jobOptions: Option[]
  isHourly: boolean
  updateJobsList: (job: Job) => void
  deleteJob: (id: string) => void
  jobDisabled: boolean
  disablePayGroup: boolean
  isPOS: boolean
  disableWTL: boolean
  customerPositionDropdownOptions: CustomerPosition[]
  getRemainingJobs: (locId: string, jobId: string) => Option[]
}

const AdditionalJobsCard = (props: Props) => {
  const {
    index,
    workTaxLocationOptions,
    payGroupOptions,
    locationOptions,
    customerPositionDropdownOptions,
    jobOptions,
    isHourly,
    isPOS,
    deleteJob,
    updateJobsList,
    jobDisabled,
    disablePayGroup,
    disableWTL,
    job,
    getRemainingJobs
  } = props
  const { handleBlur } = useFormikContext<EmployeeValues>()
  const { t } = useTranslation()
  const WTLDropdown = (
    <Dropdown
      fieldName={`jobs.${index}.workTaxLocationId`}
      label={t('workTaxLocation')}
      options={workTaxLocationOptions}
      disabled={disablePayGroup || disableWTL}
      helperText={
        disablePayGroup || disableWTL ? t('workTaxLocationLocked') : null
      }
      onChange={(val) => updateJobsList({ ...job, workTaxLocationId: val })}
      enableSearch
    />
  )
  const payGroupHelperText = disablePayGroup
    ? t('payGroupLocked')
    : payGroupOptions.length === 1
    ? t('singlePayGroup')
    : null
  const PayGroupDropdown = (
    <Dropdown
      fieldName={`jobs.${index}.payGroupId`}
      label={t('payGroup')}
      options={payGroupOptions}
      disabled={disablePayGroup}
      helperText={payGroupHelperText}
      onChange={(val) =>
        updateJobsList({ ...job, payGroupId: val, customerPositionId: '' })
      }
      enableSearch
    />
  )

  return (
    <>
      <div className='gap-6 lg:flex'>
        <Dropdown
          fieldName={`jobs.${index}.locationId`}
          label={t('location')}
          options={locationOptions}
          disabled={isPOS}
          onChange={(val) => {
            const jobs = getRemainingJobs(val, job.jobId)
            updateJobsList({
              ...job,
              locationId: val,
              jobId: jobs.length === 1 ? jobs[0].value : ''
            })
          }}
          enableSearch
        />
        <Dropdown
          fieldName={`jobs.${index}.jobId`}
          label={t('job')}
          options={jobOptions}
          disabled={jobDisabled || isPOS}
          onChange={(val) => updateJobsList({ ...job, jobId: val })}
          enableSearch
        />
      </div>
      {isHourly && (
        <>
          <div className='gap-6 lg:flex'>
            <NumberInput
              required
              prefix='$'
              fieldName={`jobs.${index}.wage`}
              label={t('hourlyPay')}
              onBlur={handleBlur}
            />
            {PayGroupDropdown}
          </div>
          <div className='gap-6 lg:flex'>
            {WTLDropdown}
            {customerPositionDropdownOptions?.length > 0 && (
              <Dropdown
                fieldName={`jobs.${index}.customerPositionId`}
                label={t('position')}
                onChange={(val) =>
                  updateJobsList({ ...job, customerPositionId: val })
                }
                options={customerPositionDropdownOptions.map((cp) => ({
                  label: cp.name,
                  value: cp.id
                }))}
                disabled={disablePayGroup || disableWTL}
                helperText={
                  disablePayGroup || disableWTL
                    ? t('positionLocked')
                    : t('positionHelper')
                }
                enableSearch
              />
            )}
          </div>
        </>
      )}
      {!isPOS && (
        <div>
          <Button
            testId={`delete-button-${index}`}
            variant='text-link'
            iconLeft={<DeleteIcon accessibility='decorative' />}
            onClick={() => deleteJob(job.id)}
            data-toast-track-id='add-employee-delete-job-button'
          >
            {t('deleteJob')}
          </Button>
          <div>
            <DividingLine />
          </div>
        </div>
      )}
    </>
  )
}

export { AdditionalJobsCard }
