import { t, DefaultStrings } from './defaultStrings'
import { Errors } from './PhoneInput'

export const getErrorState = (errors: Errors, strings?: DefaultStrings) => {
  let errorText, countrySelectInvalid, numberInvalid
  if (errors?.nationalNumber) {
    errorText = strings
      ? strings[errors.nationalNumber]
      : t(errors.nationalNumber)
    numberInvalid = true
  } else if (errors?.countryCode) {
    errorText = strings ? strings[errors.countryCode] : t(errors.countryCode)
    countrySelectInvalid = true
    numberInvalid = true
  }
  return { errorText, countrySelectInvalid, numberInvalid }
}
