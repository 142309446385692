import * as React from 'react'
import cx from 'classnames'
import { useIsMobile } from '../hooks'
import Flex from './Flex'

type Props = {
  header?: React.ReactNode
  contentHeader?: React.ReactNode
  children?: React.ReactNode
}

// transition timings of 0.2s in this file are paired with the WAIT time from `userIsOpen.ts`

const WizardLayoutInner = (props: Props) => {
  const { contentHeader, children } = props

  const { isMobile } = useIsMobile()

  return isMobile ? (
    <Flex className={cx('')}>
      {contentHeader && <div className=''>{contentHeader}</div>}
      {children}
    </Flex>
  ) : (
    <Flex className={cx('items-center', !isMobile && 'p-4')}>
      <Flex style={{ width: '1024px', overflow: '' }}>
        {contentHeader && <div className=''>{contentHeader}</div>}
        {children}
      </Flex>
    </Flex>
  )
}

export default WizardLayoutInner
