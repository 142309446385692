import React from 'react'
import cx from 'classnames'
import { TestIdentifiable } from '@toasttab/buffet-shared-types'

export interface LinearProgressBarProps extends TestIdentifiable {
  progressPercent: number
  /** Text shown centered beneath the progress bar */
  descriptionText?: string
  /**
   * When both minLabel and maxLabel are set,
   * minLabel will display on the left-hand
   * side below the progress bar
   **/
  minLabel?: string
  /**
   * When both minLabel and maxLabel are set,
   * maxLabel will display on the right-hand
   * side below the progress bar
   **/
  maxLabel?: string
  size?: LinearProgressBarSize
  /** Show the percentage to the right of the progress bar */
  showPercentage?: boolean
  /** Use square corners for the progress bar instead of rounded corners */
  flush?: boolean
}

export type LinearProgressBarSize = 'sm' | 'md'

const getSizeClassName = (size: LinearProgressBarSize) => {
  switch (size) {
    default:
    case 'md':
      return 'h-3'
    case 'sm':
      return 'h-2'
  }
}

export const LinearProgressBar = ({
  progressPercent,
  testId = 'linear-progress-bar',
  descriptionText = '',
  minLabel = '',
  maxLabel = '',
  size = 'md',
  showPercentage = false,
  flush = false
}: LinearProgressBarProps) => {
  const getTestId = (suffix: string) => `${testId}-${suffix}`
  const showLabels = minLabel && maxLabel
  const showDescription = descriptionText && !showLabels
  const isDefaultSize = size === 'md'
  if (showPercentage && flush) {
    console.error(
      'warning: this configuration is not supported, showPercentage is not supported when flush is set to true'
    )
    showPercentage = false
  }
  if (showPercentage && minLabel && maxLabel) {
    console.error(
      'warning: this configuration is not supported, showPercentage is not supported when minLabel and maxLabel are set'
    )
    showPercentage = false
  }
  if (flush && minLabel && maxLabel) {
    console.error(
      'warning: this configuration is not supported, labels are not supported when flush is set to true'
    )
    minLabel = ''
    maxLabel = ''
  }

  return (
    <div>
      <div className='flex flex-row items-center justify-center space-x-2'>
        <div
          className={cx(
            'w-full bg-primary-0  overflow-hidden',
            getSizeClassName(size),
            flush ? '' : 'rounded-full'
          )}
        >
          <div
            className={cx(
              'bg-primary-75 leading-none py-1 transition-all transition-slowest ease',
              getSizeClassName(size),
              flush ? '' : 'rounded-full'
            )}
            data-testid={getTestId('inner')}
            style={{
              transform: `translateX(-${100 - progressPercent}%)`
            }}
          />
        </div>
        {showPercentage && (
          <div
            style={{ flexBasis: '2.5em' }}
            className={cx('w-2 text-secondary', {
              'font-semibold type-default': isDefaultSize,
              'type-subhead': !isDefaultSize
            })}
            data-testid={getTestId('progress-percent')}
          >
            {progressPercent}%
          </div>
        )}
      </div>

      {showDescription && (
        <div
          className={cx('text-center mt-1 text-secondary', {
            'font-semibold type-default': isDefaultSize,
            'type-subhead': !isDefaultSize
          })}
          data-testid={getTestId('description-text')}
        >
          {descriptionText}
        </div>
      )}

      {showLabels && (
        <div
          className={cx('flex mt-1 justify-between text-secondary', {
            'font-semibold type-default': isDefaultSize,
            'type-subhead': !isDefaultSize
          })}
        >
          <div data-testid={getTestId('min-label')}>{minLabel}</div>
          <div data-testid={getTestId('max-label')}>{maxLabel}</div>
        </div>
      )}
    </div>
  )
}
