import React from 'react'
import { FilesChangeEvent } from '../FileSelectorButton/FileSelectorButton'

export interface InvalidFile {
  file: File
  reason: 'size' | 'type'
}

export interface FileSelectorProviderProps {
  multiple?: boolean
  maxSize?: number
  accept?: string
  disabled?: boolean
  onChange?: (changes: FilesChangeEvent) => void
  /** For use in the controlled state */
  files?: File[]
  /** For use in the controlled state */
  invalidFiles?: InvalidFile[]
}

export interface FileSelectorContextType
  extends Omit<Required<FileSelectorProviderProps>, 'onChange'> {
  invalidFiles: InvalidFile[]
  setInvalidFiles: (invalidFiles: InvalidFile[]) => void
  files: File[]
  setFiles: (files: File[]) => void
  onChange?: (changes: FilesChangeEvent) => void
  inputElementRef?: React.RefObject<HTMLInputElement>
}

const FileSelectorContext = React.createContext<FileSelectorContextType | null>(
  null
)

export const useFileSelector = (): FileSelectorContextType => {
  return React.useContext(FileSelectorContext) as FileSelectorContextType
}

export const FileSelectorProvider = ({
  children,
  multiple = false,
  accept = '',
  maxSize = Number.POSITIVE_INFINITY,
  disabled = false,
  files: filesControlled,
  invalidFiles: invalidFilesControlled,
  onChange
}: React.PropsWithChildren<FileSelectorProviderProps>) => {
  const [invalidFiles, setInvalidFiles] = React.useState<InvalidFile[]>([])
  const [files, _setFiles] = React.useState<File[]>([])

  const inputElementRef = React.createRef<HTMLInputElement>()

  const setFiles = (files: any) => {
    /*
      Note: Here we reset the input element (in the FileSelectorButton element) after each change. This is to ensure that subsequent
      additions via the FileSelectorButton will always trigger the onchange event (for example we want to avoid the situation where
      adding a file and then removing it and then adding it again does _not_ trigger the onChange event). 

      Also... If you remove the three lines below you'll see a test fail. That might help explain things.
    */
    if (inputElementRef.current) {
      inputElementRef.current.value = ''
    }
    _setFiles(files)
  }

  const value: FileSelectorContextType = {
    accept,
    disabled,
    multiple,
    maxSize,
    invalidFiles: invalidFilesControlled || invalidFiles,
    setInvalidFiles,
    files: filesControlled || files,
    setFiles,
    onChange,
    inputElementRef
  }

  return (
    <FileSelectorContext.Provider value={value}>
      {children}
    </FileSelectorContext.Provider>
  )
}
