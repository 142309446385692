import { Modal } from '@toasttab/buffet-pui-modal'
import { Button } from '@toasttab/buffet-pui-buttons'
import * as React from 'react'
import { useTranslation } from '@local/translations'
import { AutorenewIcon } from '@toasttab/buffet-pui-icons'

type Props = {
  onSubmit: () => void
  onClose: () => void
  showSsnModal: boolean
  isInProgress: boolean
}

const SocialSecurityModal = (props: Props) => {
  const { onSubmit, onClose, showSsnModal, isInProgress } = props
  const { t } = useTranslation()
  return (
    <Modal isOpen={showSsnModal}>
      <Modal.Header>{t('socialSecurityUsage')}</Modal.Header>
      <Modal.Body data-testid='ssn-confirmation-modal'>
        {t('socialSecurityUsageSubtext')}
      </Modal.Body>
      <Modal.Footer>
        <Button variant='secondary' onClick={onClose}>
          {t('no')}
        </Button>
        <Button
          variant='primary'
          onClick={onSubmit}
          data-testid='ssn-confirmation-modal-yes-button'
          disabled={isInProgress}
          iconLeft={
            isInProgress ? (
              <AutorenewIcon
                className='animate-spin'
                accessibility='decorative'
              />
            ) : null
          }
        >
          {t('yes')}
        </Button>
      </Modal.Footer>
    </Modal>
  )
}
export default SocialSecurityModal
