import compact from 'lodash/compact'

import { EmployeeValues } from '../pages'

const getDisplayName = (values?: EmployeeValues | null, fallback?: string) => {
  return values?.chosenName || values?.firstName || fallback || 'Your Employee'
}

type Values = EmployeeValues | null

const getLongDisplayName = (values?: Values, fallback?: string) => {
  if (!values) return fallback || 'Your Employee'

  const { chosenName, firstName, lastName } = values

  const fullName = compact([firstName, lastName]).join(' ')

  if (chosenName && fullName) {
    return `${chosenName} (${fullName})`
  }

  if (fullName) {
    return fullName
  }

  return fallback || 'Your Employee'
}

export { getDisplayName, getLongDisplayName }
