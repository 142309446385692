import * as React from 'react'
import cx from 'classnames'
import { IconWrapper, IconProps, sizes } from '../../IconWrapper'
export const VisibilityOffIcon = React.forwardRef(function VisibilityOffIcon(
  props: IconProps,
  ref?: React.ForwardedRef<HTMLElement>
) {
  return (
    <IconWrapper ref={ref} {...props}>
      <>
        {
          <svg
            width={24}
            height={24}
            viewBox='0 0 24 24'
            fill='none'
            className={cx(
              'inline-block align-middle',
              sizes[props.size || 'sm'].className
            )}
          >
            <path
              d='M20.399 10.021A10.096 10.096 0 009.62 5.851l2.341 2.344c.014 0 .025-.008.039-.008A3.817 3.817 0 0115.813 12c0 .015-.008.029-.009.044l3.379 3.384c.449-.445.856-.93 1.216-1.45a3.556 3.556 0 000-3.957zM20.45 19.366L4.198 3.091a.75.75 0 10-1.062 1.06l3.177 3.18a10.1 10.1 0 00-2.711 2.69 3.555 3.555 0 000 3.958A10.13 10.13 0 0012 18.439a10.02 10.02 0 004.393-1.012l2.995 3a.75.75 0 101.062-1.06zM12 15.813a3.811 3.811 0 01-3.158-5.949l1.1 1.101a2.298 2.298 0 003.09 3.096l1.1 1.1a3.788 3.788 0 01-2.132.652z'
              fill='currentColor'
            />
          </svg>
        }
      </>
    </IconWrapper>
  )
})
