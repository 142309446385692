import * as React from 'react'
import { ErrorPage } from './components/ErrorPage'
import { AddEmployeeModalController } from './AddEmployeeModalController'
import { LoadingPage } from './LoadingPage'
import { convertEmployeeInitialValuesToDomain } from './helpers/loadEmployeeInitialValues'
import { useFindEmployeeByIdQuery, Employee } from '@local/api'
import { getInitialValues } from './pages'
import compact from 'lodash/compact'
import { useSsnManagement, SsnContextProvider } from './hooks/useSsnManagement'
import { useDebounceValue } from './hooks'
import { CustomerConfigurations } from './CustomerConfigurations'
import { BasicInfoValues } from './pages/BasicInfo/basic-info-types'

type Props = {
  employeeId: string
  initialRehireDate: string
  onClose: () => void
  onSuccess?: (employeeId: string | null) => void
}

const RehireEmployeeRequirementsManager = (props: Props) => {
  const { onClose, onSuccess, employeeId, initialRehireDate } = props

  const {
    customerData,
    customerLoading,
    customerError,
    refetch,
    eeoEnabled,
    ssnRequired,
    filteredJobsAndPay,
    activeBenefits,
    numRunPayrollsInThePastYear
  } = CustomerConfigurations()

  const {
    data: employeeLoadingData, // will never load an SSN for rehire, as we load it individually with another call
    error: employeeLoadingError,
    loading: employeeLoadingLoading
  } = useFindEmployeeByIdQuery({
    variables: {
      employeeId
    },
    notifyOnNetworkStatusChange: true
  })

  const {
    savedSsn,
    isLoading: ssnLoading,
    onFetchSsn
  } = useSsnManagement({ employeeId })

  const isLoading = customerLoading || employeeLoadingLoading

  const isError =
    !!customerError ||
    !!employeeLoadingError ||
    !customerData ||
    !employeeLoadingData

  // The loading screen uses  its own Page, etc from @toasttab/buffet-pui-wizard-templates.
  // If it loading completes within the animation time,
  // it will un-mount and re-mount everything, causing a visual artifact.
  // therefore, we ensure that if loading starts, it lasts at least 200 ms

  const showLoading = useDebounceValue(isLoading, 200) || isLoading

  if (showLoading) {
    return <LoadingPage onClose={onClose} />
  }
  if (isError || filteredJobsAndPay.defaultEarningsIds === null) {
    return <ErrorPage callback={() => refetch()} onClose={onClose} />
  }

  const benefits = compact(customerData?.customerBenefits)
  const employee = employeeLoadingData.findEmployeeById as Employee
  const basicInfo = {} as BasicInfoValues

  const initialValues = getInitialValues({
    basicInfo: basicInfo,
    benefits: activeBenefits,
    jobsAndPay: filteredJobsAndPay
  })

  const defaultValues =
    !employee || !filteredJobsAndPay
      ? initialValues
      : convertEmployeeInitialValuesToDomain({
          initialRehireDate,
          employee,
          jobsAndPay: filteredJobsAndPay,
          benefits,
          initialJobs: initialValues.jobs
        })

  return (
    <SsnContextProvider
      savedSsn={savedSsn}
      isLoading={ssnLoading}
      onFetchSsn={onFetchSsn}
    >
      <AddEmployeeModalController
        eeoEnabled={eeoEnabled}
        defaultValues={defaultValues}
        benefits={activeBenefits}
        jobsAndPay={filteredJobsAndPay}
        onClose={onClose}
        onSuccess={onSuccess}
        employeeId={employeeId}
        hasInitialSsn={
          employeeLoadingData?.findEmployeeById?.identification
            .hasSocialSecurityNumber ?? false
        }
        isNewHire={false}
        isPOS={false}
        showErrorState={isError}
        ssnRequired={ssnRequired}
        numRunPayrollsInThePastYear={numRunPayrollsInThePastYear}
        mappings={[]} // only used for POS users
      />
    </SsnContextProvider>
  )
}

export { RehireEmployeeRequirementsManager }
