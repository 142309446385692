import { phone } from 'phone'
import {
  diallingCodes,
  CountryIsoCode,
  checkCountryIsoCode
} from '@toasttab/buffet-pui-country-utilities'
import { phoneNumberFormatsByCountry } from './phoneNumberFormatsByCountry'

export type PhoneNumber = {
  countryCode: string
  nationalNumber: string
  countryIsoCode?: CountryIsoCode
}

const mapNumberToFormat = (num: string, country?: CountryIsoCode) => {
  const format = phoneNumberFormat(num, country || 'US')
  const minLength = phoneNumberMinLength(country || 'US', num)
  const initialLength = num.length
  const chars = Array.from(format)
  const formattedNumber = chars
    .map((char) => {
      if (char === '#') {
        const nextChar = num.charAt(0)
        num = num.substring(1)
        return nextChar
      } else {
        return char
      }
    })
    .join('')
  if (initialLength < minLength) {
    return formattedNumber.endsWith(' ')
      ? formattedNumber.slice(0, -1)
      : formattedNumber
  }

  const length = country === 'GB' && initialLength > minLength ? 11 : minLength
  const remainingDigits =
    length === 8 ? num.slice(0, -1) : num.substring(0, length)
  return formattedNumber + remainingDigits
}

/**
 * phoneNumberFormat
 * @param country {CountryIsoCode} - A country code
 * @param num {string} - A string representing the typed phone number
 * @returns {string} A format number representation, with '' if no format found
 */
export const phoneNumberFormat = (num: string, country?: CountryIsoCode) => {
  return phoneNumberFormatsByCountry(country || 'US').format(num)
}

/**
 * phoneNumberPlaceholder
 * @param country {CountryIsoCode} - A country code
 * @returns {string} A placeholder representing the phone number shape
 */
export const phoneNumberPlaceholder = (country: CountryIsoCode) =>
  phoneNumberFormatsByCountry(country).placeholder

/**
 * phoneNumberMinLength
 * @param num {string} - A string representing the typed phone number
 * @param country {CountryIsoCode} - A country code
 * @returns {number} An int representing the minimum phone number length
 */
export const phoneNumberMinLength = (country: CountryIsoCode, num: string) =>
  phoneNumberFormatsByCountry(country).phoneNumberMinLength(num)

type FormatStyle = 'local' | 'national' | 'international'

/**
 * formatPhone
 * @param nationalNumber {string} - A string representing the typed phone number
 * @param countryISOCode {CountryIsoCode} - An optional country ISO code
 * @param formatStyle {FormatStyle} - Controls whether number is local (will add leading zero), national (preserved given zero or not), or international (adds country dialling code)
 * @returns {string} Formatted phone number, such (855) 123-4567, 086 123 1234 or +(212) 123 1234
 */
export const formatPhone = (
  nationalNumber: string,
  countryISOCode?: CountryIsoCode,
  formatStyle: FormatStyle = 'local'
) => {
  const countryCode = countryISOCode ? diallingCodes[countryISOCode] : '1'
  switch (formatStyle) {
    case 'national':
      return mapNumberToFormat(nationalNumber, countryISOCode)
    case 'international':
      return `+${
        diallingCodes[countryISOCode || 'US']
      } ${makeNationalNumberIntlCompatible(
        mapNumberToFormat(nationalNumber, countryISOCode),
        countryISOCode || 'US'
      )}`

    default:
      return mapNumberToFormat(
        makeLocalPhoneNumberString({ nationalNumber, countryCode }),
        countryISOCode
      )
  }
}

/**
 * formatPhoneByDiallingCode
 * @param num {string} - A string representing the typed phone number
 * @param countryCode {string} - A numeric country dialling code, such as "1", or "353"
 * @returns {string} Formatted phone number, such (855) 123-4567 or 086 123 1234
 */
export const formatPhoneByDiallingCode = (num: string, countryCode: string) => {
  // Use countryCode to determine countryIsoCode
  const fallBackCountryIsoCode = 'US'
  if (countryCode === '1') {
    // Detect US vs CA using library
    const phoneInfo = phone(num)
    return mapNumberToFormat(
      num,
      (phoneInfo.countryIso2 || fallBackCountryIsoCode) as CountryIsoCode
    )
  }

  // Other countries
  for (const [key, value] of Object.entries(diallingCodes)) {
    if (value === countryCode) {
      return mapNumberToFormat(num, key as CountryIsoCode)
    }
  }

  // Fall back
  return mapNumberToFormat(num, fallBackCountryIsoCode)
}

const countryIsoCodeForPhoneNumber = (phoneNumber: PhoneNumber) => {
  if (!phoneNumber.countryIsoCode) {
    for (const [country, diallingCode] of Object.entries(diallingCodes)) {
      if (phoneNumber.countryCode === diallingCode) {
        return country as CountryIsoCode
      }
    }
  }

  return 'US'
}

/**
 * makeLocalPhoneNumberString
 * @param phone {PhoneNumber} - A phone number object
 * @returns {string} Phone number string formatted for use locally, such as "07771234567"
 */
export const makeLocalPhoneNumberString = (phoneNumber: PhoneNumber) => {
  const countryIsoCode = countryIsoCodeForPhoneNumber(phoneNumber)
  const nationalNumber = phoneNumber.nationalNumber || ''

  const conversionFn =
    phoneNumberFormatsByCountry(countryIsoCode).makeNationalNumberLocal
  return conversionFn ? conversionFn(nationalNumber) : nationalNumber
}

const makeNationalNumberIntlCompatible = (
  nationalNumber: string,
  countryIsoCode: CountryIsoCode
) => {
  const conversionFn =
    phoneNumberFormatsByCountry(countryIsoCode).makeNationalNumberIntlCompatible
  return conversionFn ? conversionFn(nationalNumber) : nationalNumber
}

/**
 * makeIntlPhoneNumberString
 * @param phone {PhoneNumber} - A phone number object
 * @returns {string} Phone number string formatted for use internationally, such as "+447771234567"
 */
export const makeIntlPhoneNumberString = (phoneNumber: PhoneNumber) => {
  const countryIsoCode = countryIsoCodeForPhoneNumber(phoneNumber)
  const countryCode = phoneNumber.countryCode || diallingCodes[countryIsoCode]
  const nationalNumber = phoneNumber.nationalNumber || ''

  const convertedPhoneNumber = makeNationalNumberIntlCompatible(
    nationalNumber,
    countryIsoCode
  )
  return `+${countryCode}${convertedPhoneNumber}`
}

/**
 * makePhoneNumberFromIntlPhoneNumberString
 * @param countryIsoCode {CountryIsoCode} - A country code, such as 'IE'
 * @param internationalNumber {string} - An international phone number, such as +353861231234 or 353867345926
 * @returns {PhoneNumber} such as { countryCode: '353', nationalNumber: '861231234', countryIsoCode: 'IE' }
 */
export const makePhoneNumberFromIntlPhoneNumberString = (
  countryIsoCode: CountryIsoCode,
  internationalNumber: string
) => {
  countryIsoCode = checkCountryIsoCode(countryIsoCode)
  const countryCode = diallingCodes[countryIsoCode]
  let nationalNumber = internationalNumber.replace('+', '')
  nationalNumber = nationalNumber.startsWith(countryCode)
    ? nationalNumber.replace(countryCode, '')
    : nationalNumber
  return {
    countryCode: countryCode,
    nationalNumber: nationalNumber,
    countryIsoCode: countryIsoCode
  }
}
