import { FilesChangeEvent } from '../FileSelectorButton/FileSelectorButton'
import { InvalidFile } from '../FileSelectorProvider/FileSelectorProvider'

export class FileUtils {
  /**
   * Validates a file against an input accept string
   */
  public static validateAccept(file: File, accept: string = ''): boolean {
    if (!accept || accept === '*' || accept === '*/*') {
      return true
    }

    const options = accept.split(',')

    const [fileBaseMimeType, fileType] = file.type.toLowerCase().split('/')

    return options.some((option) => {
      const typeOrExt = option.toLowerCase().trim()

      if (
        typeOrExt.startsWith('.') &&
        file.name.toLowerCase().endsWith(typeOrExt)
      ) {
        return true
      } else {
        const [baseMimeType, type] = typeOrExt.split('/')

        return (
          baseMimeType === fileBaseMimeType &&
          (type === '*' || type === fileType)
        )
      }
    })
  }

  /**
   * Gets the display size of a file
   */
  public static getFileDisplaySize(fileByteSize: number): string {
    const kb = 1024
    const mb = kb * kb
    const gb = mb * kb

    let fileSize = ''

    if (fileByteSize < kb) {
      fileSize = `~1kb`
    } else if (fileByteSize >= kb && fileByteSize < mb) {
      fileSize = `${(fileByteSize / kb).toFixed(1)}kb`
    } else if (fileByteSize >= mb && fileByteSize < gb) {
      fileSize = `${(fileByteSize / mb).toFixed(1)}mb`
    } else {
      fileSize = `${(fileByteSize / gb).toFixed(1)}gb`
    }

    return fileSize.replace(/\.0([km]b)$/, '$1')
  }

  public static validateFiles(
    fileList: FileList,
    accept: string,
    maxSize: number,
    multiple: boolean
  ): FilesChangeEvent {
    const files = Array.from(fileList)

    let validFiles: File[] = []
    const invalidFiles: InvalidFile[] = []

    for (const file of files) {
      if (!FileUtils.validateAccept(file, accept)) {
        invalidFiles.push({ file, reason: 'type' })
      } else if (maxSize && file.size > maxSize) {
        invalidFiles.push({ file, reason: 'size' })
      } else {
        validFiles.push(file)
      }
    }

    if (!multiple && validFiles.length) {
      validFiles = [validFiles[0]]
    }

    return {
      files: validFiles,
      invalidFiles: invalidFiles
    }
  }
}

export const getFileKey = (file: File) => {
  return `${file.name}-${file.lastModified}-${file.size}`
}
