import * as React from 'react'
import { NumberInput as BuffetNumberInput } from '@toasttab/buffet-pui-text-input'
import { IconButton } from '@toasttab/buffet-pui-buttons'
import { Suffix } from '@toasttab/buffet-pui-text-base'

import { useField } from 'formik'
import { VisibilityOffIcon, VisibilityOnIcon } from '@toasttab/buffet-pui-icons'
import { useState } from 'react'

type IconType = React.ComponentProps<typeof IconButton>['icon']

type BaseProps = Omit<
  React.ComponentProps<typeof BuffetNumberInput>,
  'onChange'
>

type Props = BaseProps & {
  testId?: string
  icon?: IconType
  fieldName: string
  onChange?: (date: string) => void
  disabled?: boolean
}

const SsnInput = (props: Props) => {
  const {
    fieldName,
    testId,
    icon,
    errorText,
    invalid,
    name,
    onChange,
    disabled,
    ...rest
  } = props

  const [, meta, helpers] = useField(fieldName || '')

  const [maskedSsn, setMaskedSsn] = useState(true)

  const { error, value, touched } = meta

  const displayError = errorText ?? touched ? error : undefined

  const displayInvalid = invalid ?? touched ? !!error : false
  return (
    <BuffetNumberInput
      {...rest}
      inputMode='decimal'
      testId={testId || fieldName}
      containerClassName={'w-full mb-4 md:w-80 md:mb-6'}
      name={name || fieldName || ''}
      invalid={displayInvalid}
      disabled={disabled}
      errorText={displayError}
      value={value}
      onBlur={() => helpers.setTouched(true)}
      onChange={(val) => {
        const value = val.value
        if (fieldName) helpers.setValue(value)
        if (onChange) onChange(value)
        if (!displayError || value.length < 9) helpers.setTouched(false)
      }}
      format={maskedSsn ? null : '###-##-####'}
      isNumericString
      allowEmptyFormatting
      maxLength={maskedSsn ? 9 : undefined}
      type={maskedSsn ? 'password' : ''}
      suffix={
        <IconButton
          icon={
            maskedSsn ? (
              <VisibilityOnIcon aria-label='Toggle Off' />
            ) : (
              <VisibilityOffIcon aria-label='Toggle On' />
            )
          }
          contained
          onClick={() => setMaskedSsn(!maskedSsn)}
        />
      }
      suffixVariant={Suffix.Variant.iconButton}
      size='lg'
      data-toast-track-id='add-employee-newhire-ssn-input'
    />
  )
}
export default SsnInput
