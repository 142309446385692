import { OnboardingDocumentsCard } from './OnboardingDocumentsCard'
import { useFormik, FormikProvider } from 'formik'
import { useOnDraftChange } from '../../drafts'
import { useAddEmployeeContext, useIsMobile, useTranslation } from '../../hooks'
import { ContentFooter, PageMain } from '@toasttab/buffet-pui-wizard-templates'
import { WizardFooter } from '../../WizardParts/WizardFooter'
import { StepKey } from '../../domain/steps'
import DesktopFacts, { DesktopFactsItems } from '../../components/DesktopFacts'
import { InfoDisplayIcon } from '@toasttab/buffet-pui-icons'
import { useCompanyCode, useUser } from '@toasttab/ec-session'
import LinkButton from '../../components/LinkButton'
import { getOnboardingValidationSchema } from './onboarding-types'
import FormErrorAlert from '../../components/FormErrorAlert'

type DocumentState = any
type Props = {
  setActiveStep: (step: StepKey) => void
}

const DocumentsForm = (props: Props) => {
  const { setActiveStep } = props
  const { employeeValues, onChange, onBack, onNext } = useAddEmployeeContext()
  const { isMobile } = useIsMobile()
  const companyCode = useCompanyCode()
  const user = useUser()
  const { t } = useTranslation()

  const validationSchema = getOnboardingValidationSchema({ t })

  const formik = useFormik<DocumentState>({
    initialValues: employeeValues,
    validationSchema,
    onSubmit: (data) => {
      onChange(data)
      onNext()
    }
  })

  const submittedWithErrors =
    formik.submitCount > 0 && Object.keys(formik.errors).length > 0

  const documentsDesktopFacts: DesktopFactsItems = [
    {
      Icon: InfoDisplayIcon,
      header: t('desktopFactDocumentsHeader'),
      description: (
        <>
          {t('desktopFactDocumentsDescription')}
          {user.isPureUserHRPlusOrAbove ? (
            <LinkButton
              linkUrl={`/mvc/${companyCode}/Company/NewHire/RequiredDocuments`}
              linkText={t('desktopFactDocumentsHereLink')}
            />
          ) : (
            t('desktopFactDocumentNonAdmin')
          )}
          {t('desktopFactDocumentsRest')}
        </>
      )
    }
  ]

  useOnDraftChange({ values: formik.values, onChange })

  return (
    <FormikProvider value={formik}>
      <PageMain>
        <OnboardingDocumentsCard setActiveStep={setActiveStep} />
        {submittedWithErrors && (
          <FormErrorAlert isFormSubmitting={formik.isSubmitting} />
        )}
        <ContentFooter>
          <WizardFooter
            onBack={() => {
              onBack()
            }}
            backDisabled={false}
            onNext={() => formik.handleSubmit()}
            showSubmit={false}
            trackId='documents'
          />
        </ContentFooter>
      </PageMain>
      {!isMobile && <DesktopFacts items={documentsDesktopFacts} />}
    </FormikProvider>
  )
}

export { DocumentsForm }
