import { createTranslationUtilities } from '@toasttab/buffet-pui-translation-utilities'
import { translationLoader } from './locales'
import type { Translations } from './locales'

const { TranslationProvider, useTranslation } =
  createTranslationUtilities<Translations>({
    spaName: 'ec-add-employee-spa',
    translationLoader
  })

export { TranslationProvider, useTranslation }
