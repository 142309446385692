import * as React from 'react'
import { NumberInput as BuffetNumberInput } from '@toasttab/buffet-pui-text-input'
import { IconButton } from '@toasttab/buffet-pui-buttons'
import { useTranslation } from '@local/translations'
import { VisibilityOffIcon, VisibilityOnIcon } from '@toasttab/buffet-pui-icons'
import { useState, useEffect } from 'react'
import SocialSecurityModal from './SocialSecurityModal'
import { useSsnContext } from '../hooks/useSsnManagement'
import { ReadonlyText } from './ReadonlyText'
import { formatSsn } from '../pages/BasicInfo/helpers'

type BaseProps = Omit<
  React.ComponentProps<typeof BuffetNumberInput>,
  'onChange'
>

type Props = BaseProps & {
  ssnValue: string
  hasInitialSsn: boolean
}

const ReadOnlyRehireSsnInput = (props: Props) => {
  const { ssnValue, hasInitialSsn } = props

  const MASKED_SSN = '••• - •• - ••••'

  const { t } = useTranslation()

  const { savedSsn, onFetchSsn, isLoading } = useSsnContext()

  const [ssn, setSsn] = useState(ssnValue)

  useEffect(() => {
    if (savedSsn && !ssnValue) {
      setSsn(savedSsn)
    }
  }, [savedSsn]) // eslint-disable-line react-hooks/exhaustive-deps

  const [shouldShowActualSsn, setShouldShowActualSsn] = useState(false)

  const [showSsnModal, setShowSsnModal] = useState(false)

  const maskedSsn = !shouldShowActualSsn

  return (
    <>
      <ReadonlyText
        label={t('socialSecurityNumber')}
        value={
          <div className='flex justify-between w-36'>
            {maskedSsn || isLoading ? MASKED_SSN : formatSsn(ssn)}
            <IconButton
              cropToIcon
              disabled={isLoading}
              icon={
                maskedSsn ? (
                  <VisibilityOnIcon aria-label='Toggle Off' />
                ) : (
                  <VisibilityOffIcon aria-label='Toggle On' />
                )
              }
              contained
              onClick={() => {
                if (savedSsn || !hasInitialSsn) {
                  setShouldShowActualSsn((prev) => !prev)
                } else {
                  setShowSsnModal(true)
                }
              }}
            />
          </div>
        }
      />
      <SocialSecurityModal
        onSubmit={() => {
          onFetchSsn()
          setShowSsnModal(false)
          setShouldShowActualSsn(true)
        }}
        onClose={() => {
          setShowSsnModal(false)
        }}
        showSsnModal={showSsnModal}
        isInProgress={isLoading}
      />
    </>
  )
}
export type { Props }
export default ReadOnlyRehireSsnInput
