import { gql } from '@apollo/client'

export const FETCH_ASSIGNED_DOCUMENTS = gql`
  query NewHireRequiredDocumentsForAssignedFields(
    $request: RequiredDocumentsRequest!
  ) {
    newHireRequiredDocumentsForAssignedFields(request: $request) {
      id
      name
      assignedByPosition
    }
    requiredDocumentationCustomFieldsV2 {
      id
      documentIds
      name
      fieldId
    }
  }
`

export const UPLOAD_DOCUMENT = gql`
  query EmployeeDocumentUploadUrl($request: EmployeeDocumentUploadRequest!) {
    employeeDocumentUploadUrl(request: $request) {
      fileName
      filePath
      uploadUrl
    }
  }
`
