import { CountryIsoCode } from '@toasttab/buffet-pui-country-utilities'
import { phone } from 'phone'

export const getPhoneInfo = (number: string, country?: string) => {
  let phoneInfo = phone(number, { country })
  if (!phoneInfo.isValid) {
    phoneInfo = phone(number, { validateMobilePrefix: false, country })
  }
  return {
    countryCode: phoneInfo.countryCode,
    isValid: phoneInfo.isValid,
    countryIsoCode: phoneInfo.countryIso2 as CountryIsoCode,
    nationalNumber: number
  }
}
