import { EmployeeNewHireDocument } from '@local/api'
import { UPLOAD_DOCUMENT } from '../pages/Onboarding/documents-step-gql'
import { v4 as uuidv4 } from 'uuid'

const RETRIES = 5

type EmployeeDocumentUploadUrlResp = {
  data: {
    employeeDocumentUploadUrl: EmployeeNewHireDocument
  }
}

export type UploadFileDetails = {
  file: File
  id: string
  fileName: string
  filePath: string
  error: boolean
}

const generateUrl = async (
  fileName: string
): Promise<EmployeeDocumentUploadUrlResp> => {
  const options = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      query: UPLOAD_DOCUMENT.loc && UPLOAD_DOCUMENT.loc.source.body,
      variables: JSON.stringify({ request: { fileName: fileName } })
    })
  } as const

  const fetchResp = await fetch(`/graphql`, options)

  if (fetchResp.ok) {
    return (await fetchResp.json()) as EmployeeDocumentUploadUrlResp
  }
  throw new Error(fetchResp.statusText || 'Unknown Error')
}

const uploadDocumentToS3Bucket = async (
  file: File,
  url: string
): Promise<void> => {
  const options = {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/pdf',
      'x-amz-acl': 'bucket-owner-full-control'
    },
    body: file
  } as const

  const fetchResp = await fetch(url, options)

  if (!fetchResp.ok) {
    throw new Error(fetchResp.statusText || 'Unknown Error')
  }
}

export const fileIsInList = (fileList: File[], fileToCheck: File): boolean => {
  return fileList.some(
    (file) =>
      file.name === fileToCheck.name &&
      file.lastModified === fileToCheck.lastModified &&
      file.size === fileToCheck.size
  )
}

/**
 * Async method that for each file calls the ec-api graphql upload document query to create an AWS s3 path, followed
 * by a PUT to AWS to add that document to the s3 path
 *
 * @param files list of files to upload to the s3 bucket
 * @param filesToIgnore list of files that were previously successfully uploaded to ignore
 * @returns an object detailing the possible error, and file information
 */
export const uploadDocuments = async (
  files: File[],
  filesToIgnore: File[]
): Promise<UploadFileDetails[]> => {
  return await Promise.all(
    files
      .filter((file) => !fileIsInList(filesToIgnore, file))
      .map(async (file) => {
        for (let currentRetry = 0; currentRetry < RETRIES; currentRetry++) {
          try {
            const response = await generateUrl(file.name)
            await uploadDocumentToS3Bucket(
              file,
              response.data.employeeDocumentUploadUrl.uploadUrl
            )
            return {
              error: false,
              file: file,
              id: uuidv4(),
              fileName: response.data.employeeDocumentUploadUrl.fileName,
              filePath: response.data.employeeDocumentUploadUrl.filePath
            }
          } catch (error) {
            continue
          }
        }
        return {
          error: true,
          file: file,
          id: uuidv4(),
          fileName: 'ERROR',
          filePath: 'ERROR'
        }
      })
  )
}
