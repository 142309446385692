import * as React from 'react'
import { useState } from 'react'
import findIndex from 'lodash/findIndex'
import { useEmployeeDraft, useIsMobile } from './hooks'
import { State, STEPS } from './domain/steps'
import { EmployeeValues } from './pages'
import type { StepKey } from './domain/steps'
import { useTranslation } from './hooks'
import { ExitModal } from './Dialogs/ExitModal'
import { AddEmployeeProvider } from './hooks/useAddEmployeeContext'
import { JobsAndPay } from './types'
import isEmpty from 'lodash/isEmpty'
import { Benefit } from './types'
import { AddEmployeeFormPages } from './AddEmployeeFormPages'
import {
  Page,
  PageContainer,
  PageBody,
  PageStepper,
  Header
} from '@toasttab/buffet-pui-wizard-templates'
import { EmployeeBadgeIcon } from '@toasttab/buffet-pui-icons'

export type Mapping = {
  locationGuid: string // exportId of payroll location
  restaurantUserGuid: string
}

type Props = {
  defaultValues: EmployeeValues
  benefits: Benefit[]
  jobsAndPay: JobsAndPay
  onClose: () => void
  onSuccess?: (employeeId: string | null) => void
  onSuccessButtonLabel?: string
  employeeId: string | null
  hasInitialSsn: boolean
  isNewHire: boolean
  isPOS: boolean
  showErrorState: boolean
  eeoEnabled: boolean
  ssnRequired: boolean
  numRunPayrollsInThePastYear: number
  mappings: Mapping[]
}

const AddEmployeeModalController = (props: Props) => {
  const {
    benefits,
    jobsAndPay,
    onClose,
    onSuccess,
    onSuccessButtonLabel,
    employeeId,
    hasInitialSsn,
    isNewHire,
    isPOS,
    defaultValues,
    eeoEnabled,
    ssnRequired,
    numRunPayrollsInThePastYear,
    mappings
  } = props

  const { t } = useTranslation()

  const { isMobile } = useIsMobile()

  const showBenefits = !isEmpty(benefits)

  const steps = STEPS.filter((step) => {
    return !(step.key === 'benefits' && !showBenefits)
  })

  const [activeStep, setActiveStep] = useState<StepKey>(() => {
    return steps[0].key || 'basic'
  })

  const getDisplaySteps = () => {
    const currentIndex = getIndex(activeStep)

    return steps.map(({ key, titleKey }) => {
      const index = getIndex(key)
      const isActiveStep = index === currentIndex

      // only show the current step's title on mobile view
      const title =
        (isMobile && isActiveStep) || !isMobile ? t(titleKey as any) : ''
      const state: State = isActiveStep
        ? 'active'
        : index < currentIndex
        ? 'complete'
        : 'inactive'

      return { title, state }
    })
  }

  const getIndex = (key: StepKey) => {
    return findIndex(steps, (step) => step.key === key)
  }

  const onNext = () => {
    setActiveStep(steps[getIndex(activeStep) + 1]?.key || activeStep)
  }

  const onBack = () => {
    setActiveStep(steps[getIndex(activeStep) - 1]?.key || 'basic')
  }

  const { employeeValues, updateEmployeeValues } = useEmployeeDraft({
    defaultValues
  })

  const handleSuccess = (employeeId: string | null) => {
    onSuccess?.(employeeId)
    onClose()
  }

  const currentStep = steps.find((step) => step.key === activeStep) || steps[0]

  const [showExitModal, setShowExitModal] = useState<boolean>(false)

  const onExitAndDiscard = () => {
    onClose()
  }

  const initialJobsAndPayValues = {
    isHourly: defaultValues.isHourly,
    salary: defaultValues.salary,
    overtimeEligible: defaultValues.overtimeEligible,
    posAccessCode: defaultValues.posAccessCode,
    securityRole: defaultValues.securityRole,
    jobs: defaultValues.jobs,
    eeoClassification: defaultValues.eeoClassification
  }

  return (
    <AddEmployeeProvider
      employeeValues={employeeValues}
      onNext={onNext}
      onBack={onBack}
      onChange={updateEmployeeValues}
    >
      <Page key={activeStep}>
        <Header
          icon={<EmployeeBadgeIcon aria-label='employee' />}
          title={isNewHire ? t('addTeamMember') : t('rehireTeamMember')}
          onRequestClose={() => setShowExitModal(true)}
        />
        <PageContainer>
          <PageStepper
            size={isMobile ? 'sm' : 'default'}
            type='number'
            orientation={
              !isMobile ? 'horizontal-text-side' : 'horizontal-text-below'
            }
            steps={getDisplaySteps()}
          />
          <PageBody>
            <AddEmployeeFormPages
              eeoEnabled={eeoEnabled}
              ssnRequired={ssnRequired}
              step={currentStep}
              setActiveStep={setActiveStep}
              onSuccess={handleSuccess}
              onSuccessButtonLabel={onSuccessButtonLabel}
              benefits={benefits}
              jobsAndPay={jobsAndPay}
              initialJobsAndPayValues={initialJobsAndPayValues}
              employeeUuid={employeeId}
              hasInitialSsn={hasInitialSsn}
              isNewHire={isNewHire}
              isPOS={isPOS}
              numRunPayrollsInThePastYear={numRunPayrollsInThePastYear}
              mappings={mappings}
            />
          </PageBody>
        </PageContainer>
      </Page>

      <ExitModal
        isOpen={showExitModal}
        onResume={() => setShowExitModal(false)}
        onExitAndDiscard={onExitAndDiscard}
      />
    </AddEmployeeProvider>
  )
}

export { AddEmployeeModalController }
