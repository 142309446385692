import * as React from 'react'
import { useState, useEffect } from 'react'
import { createPortal } from 'react-dom'

type Props = {
  children: React.ReactNode
  tailwindIndentifier?: string
}

const FullPageOverlayPortal = (props: Props) => {
  const { children, tailwindIndentifier } = props

  const [node] = useState<HTMLDivElement>(() => {
    const el = document.createElement('div')

    if (tailwindIndentifier) {
      el.setAttribute(tailwindIndentifier, '')
    }

    return el
  })

  const [mounted, setMounted] = useState<boolean>(false)

  useEffect(() => {
    document.body.appendChild(node)
    setTimeout(() => setMounted(true), 0)
    return () => {
      document.body.removeChild(node)
    }
  }, [node])

  const show = !!(node && mounted)

  return <>{show ? createPortal(<>{children}</>, node) : null}</>
}

export default FullPageOverlayPortal
