import * as React from 'react'
import { Option } from '../types'
import { Select } from './Select'
import { TextInput } from '@toasttab/buffet-pui-text-input'

type BaseProps = Omit<React.ComponentProps<typeof Select>, 'value' | 'onChange'>

type Props = BaseProps & {
  testId?: string
  fieldName: string
  label: string
  options: Array<Option>
  onChange?: (date: string) => void
  helperText?: string | null
  disabled?: boolean
}

export const Dropdown = (props: Props) => {
  const {
    fieldName,
    label,
    options,
    testId = fieldName,
    onChange,
    helperText,
    disabled
  } = props

  if (options.length === 1) {
    return (
      <TextInput
        required
        testId={testId}
        label={label}
        value={options[0].label || 'N/A'}
        size='lg'
        readOnly={true}
        containerClassName={'w-full mb-4 md:w-80 md:mb-6'}
        helperText={helperText}
        disabled={disabled}
      />
    )
  } else {
    return (
      <Select
        required
        testId={testId}
        fieldName={fieldName}
        label={label}
        onChange={onChange}
        options={options}
        helperText={helperText}
        disabled={disabled}
        data-toast-track-id={`add-employee-${fieldName}-dropdown`}
      />
    )
  }
}
