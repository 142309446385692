import * as React from 'react'
import { useTranslation } from '@local/translations'
import { IconButton } from '@toasttab/buffet-pui-buttons'
import { EditIcon } from '@toasttab/buffet-pui-icons'
import { StepKey } from '../../domain/steps'
import { EmployeeValues } from '../index'
import { Benefit } from '../../types'
import { findValueLabel, findWliFieldLabel } from './helpers'
import { ReadonlyText } from '../../components'

type Props = {
  setActiveStep: (step: StepKey) => void
  employeeValues: EmployeeValues
  benefits: Benefit[]
}

const ReviewBenefits = (props: Props) => {
  const { setActiveStep, employeeValues, benefits } = props
  const { t } = useTranslation()

  return (
    <>
      <div className={'flex justify-between mb-4 md:mb-8'}>
        <div
          data-testid='benefits'
          className='font-bold type-headline-5 text-default'
        >
          {t('benefits')}
        </div>
        <IconButton
          icon={<EditIcon aria-label={'edit benefits'} />}
          onClick={() => setActiveStep('benefits')}
          cropToIcon
          data-toast-track-id='add-employee-return-to-benefits-icon-link'
        />
      </div>
      <div className='grid-cols-2 lg:grid gap-x-6'>
        {employeeValues.benefitPlan && (
          <ReadonlyText
            label={t('benefitPlan')}
            value={findValueLabel('benefitPlan', benefits, employeeValues)}
          />
        )}
        {employeeValues.acaPlan && (
          <ReadonlyText
            label={t('acaPlan')}
            value={findValueLabel('acaPlan', benefits, employeeValues)}
          />
        )}
        {employeeValues.tafwPolicy && (
          <ReadonlyText
            label={t('tafwPolicy')}
            value={findValueLabel('tafwPolicy', benefits, employeeValues)}
          />
        )}
        {employeeValues.workersComp && (
          <ReadonlyText
            label={t('workersComp')}
            value={findValueLabel('workersComp', benefits, employeeValues)}
          />
        )}
        {employeeValues.wli && (
          <ReadonlyText
            label={findWliFieldLabel(benefits, t)}
            value={findValueLabel('wli', benefits, employeeValues)}
          />
        )}
      </div>
    </>
  )
}

export { ReviewBenefits }
export type { Props }
