import * as React from 'react'

export type StepperThemeOption = 'primary' | 'brand' | 'gray'

type StepperConfig = {
  theme: StepperTheme
}

export type StepperTheme = {
  complete: {
    bg: string
    border: string
  }
  active: {
    bg: string
    border: string
    text: string
  }
}

export const STEPPER_THEMES: Record<StepperThemeOption, StepperTheme> = {
  primary: {
    complete: {
      bg: 'bg-primary-75',
      border: 'border-primary-75'
    },
    active: {
      bg: 'bg-primary-75',
      border: 'border-primary-75',
      text: 'text-primary-75'
    }
  },
  brand: {
    complete: {
      bg: 'bg-brand-50',
      border: 'border-brand-50'
    },
    active: {
      bg: 'bg-brand-50',
      border: 'border-brand-50',
      text: 'text-brand-50' // this does not have high enough contract ratio, so it will be restricted at the invocation
    }
  },
  gray: {
    complete: {
      bg: 'bg-gray-75',
      border: 'border-gray-75'
    },
    active: {
      bg: 'bg-gray-75',
      border: 'border-gray-75',
      text: 'text-gray-75' // this does not have high enough contract ratio, so it will be restricted at the invocation
    }
  }
}
const StepperContext = React.createContext<StepperConfig>({
  theme: STEPPER_THEMES.primary
})

export const StepperProvider = ({
  theme,
  children
}: React.PropsWithChildren<{ theme: StepperThemeOption }>) => (
  <StepperContext.Provider value={{ theme: STEPPER_THEMES[theme] }}>
    {children}
  </StepperContext.Provider>
)

export const useStepperContext = () => React.useContext(StepperContext)
