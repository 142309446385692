import * as React from 'react'
import { Select as BuffetSelect } from '@toasttab/buffet-pui-select'
import { useField } from 'formik'
import { Option } from '../types'

type BaseProps = Omit<
  React.ComponentProps<typeof BuffetSelect>,
  'value' | 'onChange'
>

type Props = BaseProps & {
  testId?: string
  fieldName: string
  options: Array<Option>
  onChange?: (date: string) => void
}

export const Select = (props: Props) => {
  const {
    fieldName,
    testId,
    errorText,
    invalid,
    name,
    options,
    onChange,
    label,
    disabled,
    required,
    helperText
  } = props

  const [, meta, helpers] = useField(fieldName || '')

  const { error, value, touched } = meta

  const displayInvalid = invalid ?? touched ? !!error : false

  const displayError = errorText ?? touched ? error : undefined

  return (
    <BuffetSelect
      label={label}
      testId={testId || fieldName}
      containerClassName={'w-full mb-4 md:w-80 md:mb-6'}
      name={name || fieldName || ''}
      invalid={displayInvalid}
      errorText={displayError}
      value={value}
      onChange={(val) => {
        if (fieldName) helpers.setValue(val)
        if (onChange) onChange(val as string)
      }}
      options={options}
      size='lg'
      disabled={disabled}
      required={required}
      helperText={helperText}
    />
  )
}
