import * as React from 'react'
import cx from 'classnames'
import { Stepper, StepperProps } from '@toasttab/buffet-pui-stepper'
import { MAIN_CARD_MAX_WIDTH_PX } from '../../constants'

export const PageStepper = ({ containerClassName, ...props }: StepperProps) => {
  return (
    <div className='flex flex-row mx-auto lg:mx-0 px-4 md:px-10 w-full -mb-4 md:-mb-10 py-8 md:py-6'>
      <div
        style={{ maxWidth: MAIN_CARD_MAX_WIDTH_PX }}
        className={cx('flex-grow mx-auto lg:mx-0', containerClassName)}
      >
        <Stepper {...props} />
      </div>
    </div>
  )
}
