import { useMemo, useEffect, useState } from 'react'
import { useFormik, FormikProvider } from 'formik'
import {
  getJobsAndPayValidationSchema,
  EARNING_TYPE_OPTIONS,
  getInitializeJobsValue,
  getInitializeJobsValueForPOS
} from './jobs-and-pay-types'
import type { Job, JobsAndPayValues } from './jobs-and-pay-types'
import { useOnDraftChange } from '../../drafts'
import { useAddEmployeeContext, useIsMobile, useTranslation } from '../../hooks'
import { OptionsSelector } from '../../components'
import { DividingLine } from '@toasttab/buffet-pui-config-templates'
import { PaymentDetailsCard } from './PaymentDetailsCard'
import { CustomerPosition, JobsAndPay, Location } from '../../types'
import { ContentFooter, PageMain } from '@toasttab/buffet-pui-wizard-templates'
import { WizardFooter } from '../../WizardParts/WizardFooter'
import { jobsAndPayValuesChanged } from './helpers'
import FormErrorAlert from '../../components/FormErrorAlert'
import DesktopFacts, { DesktopFactsItems } from '../../components/DesktopFacts'
import { InfoDisplayIcon } from '@toasttab/buffet-pui-icons'
import LinkButton from '../../components/LinkButton'
import { useCompanyCode, useUser } from '@toasttab/ec-session'
import { useCheckPosAvailabilityLazyQuery } from '@local/api'
import compact from 'lodash/compact'
import pickBy from 'lodash/pickBy'
import isEmpty from 'lodash/isEmpty'

type Props = {
  jobsAndPay: JobsAndPay
  initialValues: JobsAndPayValues
  isNewHire: boolean
  isPOS: boolean
  hourlyEnabled: boolean
  salaryEnabled: boolean
  eeoEnabled: boolean
}

// this function will return the non-null exportIds of
// all of the locations the user has selected
export const getSelectedLocationsExportIds = (
  jobs: Array<Job>,
  locations: Array<Location>
) => {
  const selectedLocationIds = jobs.map((j) => j.locationId)
  const selectedLocations = locations.filter((l) =>
    selectedLocationIds.includes(l.id)
  )
  return compact(selectedLocations.map((l) => l.exportId))
}

export const positionPaygroupHash = (jobsAndPay: JobsAndPay) => {
  const positionRequiredHash: { [key: string]: CustomerPosition[] } = {}
  jobsAndPay.payGroupsV2.forEach((pg) => {
    positionRequiredHash[pg.id] = jobsAndPay.customerPositions.filter(
      (cp) => cp.payGroup.id === pg.id
    )
  })
  return positionRequiredHash
}

const JobsAndPayForm = (props: Props) => {
  const {
    jobsAndPay,
    initialValues,
    isNewHire,
    isPOS,
    hourlyEnabled,
    salaryEnabled,
    eeoEnabled
  } = props
  const { t } = useTranslation()

  const validationSchema = useMemo(() => {
    return getJobsAndPayValidationSchema({
      t,
      eeoEnabled,
      isNewHire,
      positionPaygroupHash: positionPaygroupHash(jobsAndPay),
      isPOS
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [t])

  const posAccessCodeError = (posAccessCode: boolean | null) => {
    if (posAccessCode === false) {
      return t('posAccessCodeAlreadyInUse')
    } else if (posAccessCode === null) {
      return t('posAccessCodeMightBeInUse')
    }
  }

  const { employeeValues, onChange, onBack, onNext } = useAddEmployeeContext()
  const { displayName } = useAddEmployeeContext()
  const [isLoading, setIsLoading] = useState(false)
  const { isMobile } = useIsMobile()
  const companyCode = useCompanyCode()
  const user = useUser()

  const formik = useFormik<JobsAndPayValues>({
    initialValues: employeeValues,
    validationSchema,
    onSubmit: async (data) => {
      setIsLoading(true)
      onChange(data)

      const exportIdsAvailable =
        getSelectedLocationsExportIds(employeeValues.jobs, jobsAndPay.locations)
          .length > 0

      // if user did not select mapped locations or if feature is disabled or if they already have a POS account
      // ignore any errors
      if (!isNewHire || !exportIdsAvailable || isPOS) {
        onNext()
      }

      // if feature is enabled and user selected mapped location
      // do validation check
      const { data: posAvailabilityGraphQlResponse } =
        await checkPosAvailability()
      const codeAvailable =
        posAvailabilityGraphQlResponse?.checkPosAvailability ?? null

      const errors = pickBy({
        posAccessCode: posAccessCodeError(codeAvailable)
      })

      if (isEmpty(errors)) {
        onNext()
      } else {
        setIsLoading(false)
        formik.setErrors(errors)
      }
    }
  })

  useOnDraftChange({ values: formik.values, onChange })

  const onPayTypeChange = (value: string) => {
    formik.setFieldValue('isHourly', value === 'true')
    if (isPOS) {
      formik.setFieldValue(
        'jobs',
        getInitializeJobsValueForPOS(formik.values.jobs, jobsAndPay)
      )
    } else {
      formik.setFieldValue('jobs', [getInitializeJobsValue(jobsAndPay)])
    }

    formik.setFieldValue('salary', null)
    if (employeeValues.taxationType === 'W2') {
      formik.setFieldValue('overtimeEligible', initialValues.overtimeEligible)
      if (value === 'false') {
        formik.setFieldValue('overtimeEligible', false)
      }
    }
  }

  const resetJobsValues = () => {
    formik.setFieldValue('isHourly', initialValues.isHourly)
    formik.setFieldValue('salary', initialValues.salary)
    formik.setFieldValue('jobs', initialValues.jobs)
    formik.setFieldValue('overtimeEligible', initialValues.overtimeEligible)
  }

  const [checkPosAvailability] = useCheckPosAvailabilityLazyQuery({
    variables: {
      request: {
        posAccessCode: parseInt(employeeValues.posAccessCode),
        exportIds: getSelectedLocationsExportIds(
          formik.values.jobs,
          jobsAndPay.locations
        )
      }
    }
  })

  const jobsChanged =
    !isNewHire && jobsAndPayValuesChanged(initialValues, formik.values)

  const submittedWithErrors =
    formik.submitCount > 0 && Object.keys(formik.errors).length > 0

  const availableTypes = {
    hourlyEnabled: employeeValues.taxationType === 'W2' ? true : hourlyEnabled,
    salaryEnabled: employeeValues.taxationType === 'W2' ? true : salaryEnabled
  }

  useEffect(() => {
    if (!availableTypes.hourlyEnabled && employeeValues.isHourly) {
      onPayTypeChange('false')
    } else if (!availableTypes.salaryEnabled && !employeeValues.isHourly) {
      onPayTypeChange('true')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const jobsAndPayDesktopFacts: DesktopFactsItems = [
    {
      Icon: InfoDisplayIcon,
      header: t('desktopFactTippedEmployeeHeader'),
      description: (
        <>
          {t('desktopFactTippedEmployeeDescription')}
          <LinkButton
            linkUrl={
              'https://central.toasttab.com/s/article/Toast-Payroll-Tip-Processing#how_to_bring_tips_from_pos_to_payroll'
            }
            linkText={t('checkOutThisArticle')}
          />
        </>
      )
    },
    {
      Icon: InfoDisplayIcon,
      header: t('desktopFactPermissionsHeader'),
      description: (
        <>
          {t('desktopFactPermissionsDescription')}
          <LinkButton
            linkUrl={
              'https://central.toasttab.com/s/article/Toast-Payroll-Security-Roles'
            }
            linkText={t('checkOutThisArticle')}
          />
        </>
      )
    }
  ]

  const eeoDesktopFact = {
    Icon: InfoDisplayIcon,
    header: t('desktopFactEEOHeader'),
    description: (
      <>
        {user.isPureUserHRPlusOrAbove ? (
          <>
            {t('desktopFactEEODescription')}
            <LinkButton
              linkUrl={`/mvc/${companyCode}/Company/NewHire/Settings`}
              linkText={t('desktopFactNewHireSettings')}
            />
          </>
        ) : (
          t('desktopFactEEODescriptionNonAdmin')
        )}
      </>
    )
  }

  if (eeoEnabled) {
    jobsAndPayDesktopFacts.splice(1, 0, eeoDesktopFact)
  }

  return (
    <FormikProvider value={formik}>
      <PageMain>
        <div className='mb-6 font-bold type-headline-4 text-default'>
          {t('payType')}
        </div>
        <>
          <OptionsSelector
            onChange={(value) => onPayTypeChange(value)}
            fieldName='isHourly'
            label={t('howEmployeePaid', { name: displayName })}
            options={EARNING_TYPE_OPTIONS.map(
              ({ labelKey, value, helpTextKey }) => {
                return {
                  label: t(labelKey),
                  value,
                  helpText: t(helpTextKey),
                  disabled:
                    labelKey === 'hourly' ? !hourlyEnabled : !salaryEnabled
                }
              }
            )}
          />
          <div className='mb-4'>
            <DividingLine />
          </div>
          <PaymentDetailsCard
            jobsAndPay={jobsAndPay}
            isHourly={formik.values.isHourly}
            isNewHire={isNewHire}
            isPOS={isPOS}
            posJobsDisabled={isPOS && initialValues.jobs[0].jobId !== ''}
            displayName={displayName}
            jobsChanged={jobsChanged}
            eeoEnabled={eeoEnabled}
            resetJobsValues={resetJobsValues}
          />
          {submittedWithErrors && (
            <FormErrorAlert isFormSubmitting={formik.isSubmitting} />
          )}
          <ContentFooter>
            <WizardFooter
              onBack={onBack}
              backDisabled={false}
              onNext={() => formik.handleSubmit()}
              showSubmit={false}
              isInProgress={isLoading}
              trackId='jobs-and-pay'
            />
          </ContentFooter>
        </>
      </PageMain>
      {!isMobile && <DesktopFacts items={jobsAndPayDesktopFacts} />}
    </FormikProvider>
  )
}

export { JobsAndPayForm }
export type { Props }
