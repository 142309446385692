import * as React from 'react'
import { PageAside, PageInfoList } from '@toasttab/buffet-pui-wizard-templates'

type IconType = React.ForwardRefExoticComponent<
  React.RefAttributes<HTMLElement>
>

export type DesktopFactsItems = {
  Icon: IconType
  header: string
  description: React.ReactNode
}[]

type Props = {
  testId?: string
  items: DesktopFactsItems
}

const DesktopFacts = (props: Props) => {
  const { testId, items } = props

  return (
    <PageAside testId={testId}>
      <PageInfoList items={items} itemClassName={'w-4/5'} />
    </PageAside>
  )
}
export default DesktopFacts
