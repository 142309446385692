import * as React from 'react'
import {
  AddEmployeeModalRoot,
  RehireEmployeeModalRoot,
  PosEmployeeModalRoot
} from '@local/add-employee-modal'

type UnknownProps = Record<string, unknown>

type AnyFunc = (...args: any[]) => void

const getGuardFn = (componentName: string) => {
  const gaurdFn = <T extends AnyFunc = () => void>(
    fn: unknown,
    propName: string
  ) => {
    if (typeof fn === 'function') {
      return fn as T
    }

    console.log(`'${propName}' was missing for '${componentName}'`)

    const defaultFn = () => {
      console.log(
        `'${propName}' for '${componentName}' was called but was not passed from parent`
      )
    }

    return defaultFn as T
  }
  return gaurdFn
}

const addEmployeeFn = getGuardFn('AddEmployeeModal')
const rehireEmployeeFn = getGuardFn('RehireEmployeeModal')
const posEmployeeFn = getGuardFn('PosEmployeeModal')

const HireApp = (props: UnknownProps) => {
  const {
    mode,
    isOpen,
    onCancel,
    onSuccess,
    onSuccessButtonLabel,
    employeeId,
    initialRehireDate,
    locationGuid,
    toastUserGuid
  } = props

  if (mode === 'add-employee') {
    return (
      <AddEmployeeModalRoot
        isOpen={!!isOpen}
        onCancel={addEmployeeFn(onCancel, 'onCancel')}
        onSuccessButtonLabel={
          !!onSuccessButtonLabel ? String(onSuccessButtonLabel) : undefined
        }
        onSuccess={addEmployeeFn(onSuccess, 'onSuccess')}
      />
    )
  }

  if (mode === 'rehire-employee') {
    return (
      <RehireEmployeeModalRoot
        isOpen={!!isOpen}
        onCancel={rehireEmployeeFn(onCancel, 'onCancel')}
        onSuccess={rehireEmployeeFn(onSuccess, 'onSuccess')}
        employeeId={`${employeeId || ''}`}
        initialRehireDate={`${initialRehireDate || new Date().toString()}`}
      />
    )
  }

  if (mode === 'pos-employee') {
    return (
      <PosEmployeeModalRoot
        isOpen={!!isOpen}
        onCancel={posEmployeeFn(onCancel, 'onCancel')}
        onSuccess={posEmployeeFn(onSuccess, 'onSuccess')}
        locationGuid={`${locationGuid || ''}`}
        toastUserGuid={`${toastUserGuid || ''}`}
      />
    )
  }

  return null
}

const App = (props: { appName?: string }) => {
  // due to updating to react 18 we now need a fallback in order to call the hook useFeature()
  // for our purposes this is allowed to be empty because the HireApp will always render and the useFeature doesn't
  // affect loading states
  return (
    <React.Suspense fallback={<></>}>
      <HireApp {...props} />
    </React.Suspense>
  )
}

export { App }
