import * as React from 'react'

import {
  FileSelectorProvider,
  FileSelectorProviderProps
} from './FileSelectorProvider/FileSelectorProvider'
import {
  FileSelectorContainer,
  FileSelectorContainerProps
} from './FileSelectorContainer/FileSelectorContainer'
import { loadStrings } from './defaultStrings'

export type FileSelectorProps = FileSelectorProviderProps &
  FileSelectorContainerProps

/**
 * File selector component that allow file selection via a button or drag and drop
 */
export function FileSelector({
  multiple,
  maxSize,
  disabled,
  accept,
  children,
  files,
  invalidFiles,
  ...props
}: React.PropsWithChildren<FileSelectorProps>): JSX.Element {
  loadStrings()
  return (
    <FileSelectorProvider
      multiple={multiple}
      maxSize={maxSize}
      disabled={disabled}
      accept={accept}
      files={files}
      invalidFiles={invalidFiles}
      onChange={props.onChange}
    >
      <FileSelectorContainer {...props}>{children}</FileSelectorContainer>
    </FileSelectorProvider>
  )
}
