export const formatPhoneNumber = (phoneNumberString: string) => {
  // format a string into (xxx) xxx-xxxx
  const cleaned = ('' + phoneNumberString).replace(/\D/g, '')
  const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/)
  if (match) {
    return '(' + match[1] + ') ' + match[2] + '-' + match[3]
  }
  return ''
}

export const formatSsn = (ssn: string) => {
  // The server expects the dashes to be in the SSN xxx-xx-xxxx,
  // but our input box does not format this value with the dashes, so we need to add them back in
  const cleaned = ('' + ssn).replace(/\D/g, '')
  const match = cleaned.match(/^(\d{3})(\d{2})(\d{4})$/)
  if (match) {
    return match[1] + '-' + match[2] + '-' + match[3]
  }
  return ''
}
