import {
  Locale,
  getLocale,
  checkLocale
} from '@toasttab/buffet-pui-locale-utilities'
import { Currency, Options, Money, BuffetFormatOptions } from './'
import { format, formatAbbreviated } from './utils'

type MoneyFn = (
  money: Money,
  localeOrOptions?: Locale | Options,
  options?: Options
) => string

const DEFAULT_MINIMUM_DIGITS = 2

const getMinimumAndMaximumFractionDigits = (
  trailingZeros: boolean | undefined,
  amount: number,
  precision: number | undefined
): {
  min: number | undefined
  max: number | undefined
} => {
  if (trailingZeros === false) {
    const remainder = String(amount).split('.')[1]
    if (remainder && Number(remainder) > 0) {
      const precisionOrDefault =
        typeof precision === 'number' ? precision : DEFAULT_MINIMUM_DIGITS
      return {
        min: precisionOrDefault,
        max: precisionOrDefault
      }
    }
    return {
      min: 0,
      max: precision && precision > DEFAULT_MINIMUM_DIGITS ? precision : 0
    }
  }

  if (typeof precision === 'number') {
    return {
      min: trailingZeros ? precision : 0,
      max: precision
    }
  }
  return {
    min: amount === 0 && !trailingZeros ? 0 : undefined,
    max: undefined
  }
}

/**
 * formatCurrency - Returns a formatted currency number
 * @param {Money} money - An object of type Money (amount and currency)
 * @param {Locale | Options} localeOrOptions - A locale (string) such as `en-US`, or options
 * @param {Options} options - Options to control precision, rounding, abbreviation and currencyDisplay
 */
export const formatCurrency: MoneyFn = (
  money,
  localeOrOptions,
  options = {}
) => {
  let { amount, currency } = money
  let locale = getLocale()
  if (typeof localeOrOptions === 'string') {
    locale = checkLocale(localeOrOptions)
  }
  if (typeof localeOrOptions === 'object') {
    options = localeOrOptions
  }

  amount = typeof amount === 'string' ? Number(amount) : amount
  const { precision, abbreviated, trailingZeros, roundingMode } = options

  if (abbreviated) {
    return formatAbbreviated(amount, locale, options, 'currency', currency)
  }

  const intlOptions: BuffetFormatOptions = {
    style: 'currency',
    currency: Currency[currency] || Currency.USD,
    currencyDisplay: options.currencyDisplay || 'symbol',
    roundingMode
  }
  const { min, max } = getMinimumAndMaximumFractionDigits(
    trailingZeros,
    amount,
    precision
  )
  intlOptions.minimumFractionDigits = min
  intlOptions.maximumFractionDigits = max

  return format(amount, locale, intlOptions)
}

type CreateMoneyFormatter = (
  localeOverride?: Locale,
  options?: Options
) => (money: Money) => string

/**
 * createFormatCurrency - Returns a function that will return a formatted currency number
 * @param {Locale} localeOverride - A locale (string) such as `en-US`
 * @param {Options} options - Options to control precision, rounding and abbreviation
 */
export const createFormatCurrency: CreateMoneyFormatter =
  (localeOverride, options) => (money) =>
    formatCurrency(money, localeOverride || getLocale(), options)
