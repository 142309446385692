import * as React from 'react'
import cx from 'classnames'
import { BaseStepProps, Step, StepProps } from '../Step'
import {
  StepperProvider,
  StepperThemeOption,
  useStepperContext
} from '../StepperContext'

export interface StepperProps {
  orientation?: 'vertical' | 'horizontal-text-side' | 'horizontal-text-below'
  type?: 'icon' | 'number'
  size?: StepProps['size']
  steps: Array<BaseStepProps>
  parentTestId?: string | number
  testId?: string | number
  containerClassName?: string
  theme?: StepperThemeOption
}

export const Stepper = ({
  testId = `Stepper`,
  containerClassName,
  orientation = 'horizontal-text-side',
  type = 'icon',
  size = 'default',
  theme = 'primary',
  steps
}: StepperProps) => {
  if (theme === 'brand' && type === 'number') {
    console.error(
      'this is an unsupported configuration, the combination of the "brand" theme and the "number" type is not supported due to its low color contrast ratio, defaulting to use the default theme "primary"'
    )

    theme = 'primary'
  }

  return (
    <StepperProvider theme={theme}>
      <div
        data-testid={testId}
        className={cx(containerClassName, {
          'flex items-start': orientation === 'horizontal-text-below',
          'flex items-stretch': orientation === 'horizontal-text-side',
          'flex flex-col items-start justify-center': orientation === 'vertical'
        })}
      >
        {steps.map((step, index) => {
          const isFirst = index === 0
          const isLast = index === steps.length - 1
          const stepId = step.testId ?? `step-${index}`

          const fullStepProps: StepProps = {
            ...step,
            testId: `${testId}-${stepId}`,
            circleContent: type === 'icon' ? 'icon' : index + 1,
            labelOn:
              orientation === 'horizontal-text-below'
                ? 'below'
                : orientation === 'horizontal-text-side'
                ? 'side-horizontal'
                : 'side-vertical',
            size,
            isFirst,
            isLast
          }

          if (isFirst) {
            if (step.renderStep) {
              return step.renderStep(fullStepProps)
            }
            return <Step key={stepId} {...fullStepProps} />
          }
          return (
            <React.Fragment key={stepId}>
              <StepDivider
                orientation={orientation}
                state={step.state}
                size={size}
              />
              {step.renderStep === undefined ? (
                <Step {...fullStepProps} />
              ) : (
                <>{step.renderStep(fullStepProps)}</>
              )}
            </React.Fragment>
          )
        })}
      </div>
    </StepperProvider>
  )
}

export const StepDivider = ({
  orientation,
  state,
  size
}: {
  orientation: StepperProps['orientation']
  state: StepProps['state']
  size: StepProps['size']
}) => {
  const {
    theme: { complete }
  } = useStepperContext()

  const borderColor =
    state === 'inactive' ? 'border-color-default' : complete.border

  if (orientation === 'horizontal-text-side') {
    return (
      <div
        className={cx('flex-grow flex')}
        style={{
          flexBasis: '8px',
          maxHeight: size === 'default' ? '2.75rem' : '2.25rem'
        }}
      >
        <div className={cx('my-auto mx-2 px-1 border w-full', borderColor)} />
      </div>
    )
  }
  return (
    <div
      className={cx(borderColor, {
        'flex-grow px-1 border': orientation === 'horizontal-text-below',
        'flex-grow border': orientation === 'vertical'
      })}
      style={{
        flexBasis: orientation === 'vertical' ? '16px' : '8px',
        marginTop:
          orientation === 'horizontal-text-below'
            ? size === 'default'
              ? 'calc(1rem - 1px)'
              : 'calc(0.75rem - 1px)'
            : '0px',
        marginLeft:
          orientation === 'vertical'
            ? size === 'default'
              ? 'calc(1rem - 1px)'
              : 'calc(0.75rem - 1px)'
            : undefined
      }}
    />
  )
}
