import { BenefitsValues } from '../pages/Benefits/benefits-types'
import type {
  Benefits,
  Employee,
  EmploymentTaxType,
  JobAssignment,
  Maybe,
  PayGroupAssignment
} from '@local/api'
import type {
  BasicInfoValues,
  TaxationOptionValue
} from '../pages/BasicInfo/basic-info-types'
import { Benefit, JobsAndPay } from '../types'
import type {
  JobsAndPayValues,
  Job
} from '../pages/JobsAndPay/jobs-and-pay-types'
import compact from 'lodash/compact'
import uniqueId from 'lodash/uniqueId'
import { EmployeeValues } from '../pages'
import { getBenefitsInitialValues } from '../pages/Benefits/benefits-types'

const employmentTaxTypeToDomain = (
  type: EmploymentTaxType | null
): TaxationOptionValue => {
  if (type === '_1099') {
    return '1099'
  }
  return 'W2'
}

const organizeJobsForDomain = (
  jobAssignments: JobAssignment[],
  payGroupAssignments: PayGroupAssignment[],
  jobsAndPay: JobsAndPay
) => {
  const customerPayGroups = compact(jobsAndPay.payGroupsV2)
  /*
    jobAssignments coming from the api can return duplicates because they're coming from
    employee_position as well as employee_secondary_rates. We need to make sure we're only
    adding unique jobs. So based on the location, paygroup, and jobId we build a hashmap that
    we check each time. If the job has already been added, we skip that jobAssignment
  */
  const usedJobs: { [key: string]: boolean } = {}
  const newJobsList: Job[] = []
  jobAssignments.forEach((job) => {
    if (!(`${job.locationId}${job.payGroupId}${job.jobId}` in usedJobs)) {
      usedJobs[`${job.locationId}${job.payGroupId}${job.jobId}`] = true
      const payGroup = payGroupAssignments.find(
        (pga) => pga.payGroupId === job.payGroupId
      )
      const cusPayGroup = customerPayGroups.find(
        (cpg) => cpg.id === job.payGroupId
      )

      const builtJob: Job = {
        id: uniqueId('job-'),
        jobId: job.jobId ?? '',
        workTaxLocationId: payGroup?.workTaxLocationId ?? '',
        wage: job.activeHourlyRateOfPay?.amount,
        locationId: job.locationId,
        customerPositionId: payGroup?.positionId ?? '',
        payGroupId: cusPayGroup?.id ?? ''
      }
      newJobsList.push(builtJob)
    }
  })
  return newJobsList
}

export const clearExampleEmail = (email: string) => {
  if (email.includes('@example.com')) {
    return ''
  }
  return email
}

const convertEeo = (eeo: number | undefined) => {
  if (eeo === undefined || eeo === 0) {
    return ''
  }
  return eeo.toString()
}

const setupBenefits = (
  defaultBenefits: BenefitsValues,
  existingBenefits: Maybe<Benefits>
): BenefitsValues => {
  if (existingBenefits) {
    const { acaPlan, benefitPlan, tafwPolicy, washingtonLaI, workersComp } =
      existingBenefits

    return {
      acaPlan:
        acaPlan !== null
          ? acaPlan !== ''
            ? acaPlan
            : defaultBenefits.acaPlan
          : defaultBenefits.acaPlan,
      benefitPlan:
        benefitPlan !== null
          ? benefitPlan !== ''
            ? benefitPlan
            : defaultBenefits.benefitPlan
          : defaultBenefits.benefitPlan,
      tafwPolicy:
        tafwPolicy !== null
          ? tafwPolicy !== ''
            ? tafwPolicy
            : defaultBenefits.tafwPolicy
          : defaultBenefits.tafwPolicy,
      wli:
        washingtonLaI !== null
          ? washingtonLaI !== ''
            ? washingtonLaI
            : defaultBenefits.wli
          : defaultBenefits.wli,
      workersComp:
        workersComp !== null
          ? workersComp !== ''
            ? workersComp
            : defaultBenefits.workersComp
          : defaultBenefits.workersComp
    }
  }
  return defaultBenefits
}

export const convertEmployeeInitialValuesToDomain = ({
  initialRehireDate,
  savedSsn,
  employee,
  jobsAndPay,
  benefits,
  initialJobs
}: {
  initialRehireDate: string
  savedSsn?: string
  employee: Employee
  jobsAndPay: JobsAndPay
  benefits: Benefit[]
  initialJobs: Job[]
}): EmployeeValues => {
  const jobAssignments = compact(employee.jobAssignments)
  const taxationType =
    jobAssignments.length === 0
      ? 'W2'
      : employmentTaxTypeToDomain(jobAssignments[0].employmentTaxType)

  const basicInfoValues: BasicInfoValues = {
    firstName: employee.user?.name.first ?? '',
    lastName: employee.user?.name.last ?? '',
    chosenName: employee.user?.name.chosen ?? '',
    email: clearExampleEmail(employee.user?.email ?? ''),
    phoneNumber: {
      countryCode: '1',
      countryIsoCode: 'US',
      nationalNumber: employee.contact.telephoneNumber?.telephoneNumber ?? ''
    },
    socialSecurityNumber: savedSsn ?? '',
    startDate: initialRehireDate,
    employeeNumber: employee.employment?.employeeNumber ?? '',
    taxationType: taxationType,
    willProvideSsn: taxationType !== '1099', //if employee is a 1099 rehire don't require ssn
    willProvideEmail: true
  }

  const existingBenefits = employee.benefits
  const defaultBenefits = getBenefitsInitialValues({ benefits })
  const benefitsValues = setupBenefits(defaultBenefits, existingBenefits)

  const salary = employee.salary
    ? employee.salary.activeSalaryRateOfPay?.amount
    : null

  const defaultOvertimeEligible = !salary
    ? true
    : employee.employment?.overtimeEligible ?? false

  const jobsAndPayValues: JobsAndPayValues = {
    isHourly: !salary,
    salary: salary,
    posAccessCode: employee.employment?.employeeNumber ?? '',
    overtimeEligible: defaultOvertimeEligible,
    securityRole: employee.payrollSecurityRole?.id ?? '',
    jobs:
      jobAssignments.length === 0
        ? initialJobs
        : organizeJobsForDomain(
            jobAssignments,
            compact(employee.payGroupAssignments),
            jobsAndPay
          ),
    eeoClassification: convertEeo(employee.employment?.eeoClassification.id)
  }

  return {
    ...basicInfoValues,
    ...benefitsValues,
    ...jobsAndPayValues,
    selectedDocuments: [],
    additionalDocuments: [],
    customDocumentFields: []
  }
}
