import * as React from 'react'
import { EmptyState } from '@toasttab/buffet-pui-empty-state'
import {
  EmployeeBadgeIcon,
  WarningOutlineIcon
} from '@toasttab/buffet-pui-icons'
import { Button } from '@toasttab/buffet-pui-buttons'
import { useTranslation } from '@local/translations'
import {
  ContentFooter,
  ContinueButton,
  Header,
  NavigationContainer,
  Page,
  PageAside,
  PageBody,
  PageContainer,
  PageMain,
  PageStepper
} from '@toasttab/buffet-pui-wizard-templates'

type Props = {
  callback: () => void
  onClose: () => void
}

const ErrorPage = (props: Props) => {
  const { callback, onClose } = props
  const { t } = useTranslation()

  return (
    <Page testId='Error-page'>
      <Header
        icon={<EmployeeBadgeIcon aria-label='employee' />}
        title={t('addTeamMember')}
        onRequestClose={onClose}
      />
      <PageContainer>
        <PageStepper steps={[]} />
        <PageBody>
          <PageMain>
            <EmptyState
              title={t('hmmSomethingWentWrong')}
              icon={<WarningOutlineIcon accessibility='decorative' />}
              button={<Button onClick={() => callback()}>Retry</Button>}
            >
              {t('pleaseRetry')}
            </EmptyState>
            <ContentFooter>
              <NavigationContainer>
                <ContinueButton
                  testId='addEmployeeNextButtonErrorPage'
                  disabled={true}
                />
              </NavigationContainer>
            </ContentFooter>
          </PageMain>
          <PageAside />
        </PageBody>
      </PageContainer>
    </Page>
  )
}

export { ErrorPage }
