const pad = (val: string | number, len = 2) => `${val}`.padStart(len, '0')

const convertDateToString = (date: Date | undefined) => {
  if (!date) return undefined
  const year = pad(date.getFullYear(), 4)
  const month = pad(date.getMonth() + 1)
  const day = pad(date.getDate())
  return `${year}-${month}-${day}`
}

const convertStringToDate = (dateStr: string | undefined) => {
  if (!dateStr) return undefined
  return new Date(dateStr)
}

export { convertDateToString, convertStringToDate }
