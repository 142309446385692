import each from 'lodash/each'
import { useEffect, useState } from 'react'

type Styles = Record<string, string>

const getCurrentStyles = (node: any, styles: Styles): Styles => {
  if (!node || !node.style) return {}
  try {
    const currentStyles: Styles = {}
    each(styles, (val, key) => {
      currentStyles[key] = node.style[key]
    })
    return currentStyles
  } catch {
    return {}
  }
}

const applyStyles = (node: any, styles: Styles) => {
  if (!node || !node.style) return
  try {
    each(styles, (val, key) => {
      node.style[key] = val
    })
  } finally {
  }
}

/* my deepest condolences if you have come to try to figure out why this hook exists.
this hook allows you to apply styles (and, as politely as possible, un-apply them) to an DOM node outside of the scope of react.
if this can be in _any_ way avoided, please do so.  especially please do not modify nodes made by other react code.
but if you must apply styles dynamically to server rendered dom nodes, then this hook is at least better than doing so from scratch
---
the original use case for was setting position: sticky on the container node that the react contents of the top nav were rendering into.
sticky is sensitive to nesting and so must be applied to an outermost node in order to have the desired effect.
*/

const useApplyStyles = (selector: string, passedStyles: Styles) => {
  const [styles] = useState(() => passedStyles)

  useEffect(() => {
    const node = document.querySelector(selector)

    const prevStyles = getCurrentStyles(node, styles)

    applyStyles(node, styles)

    return () => applyStyles(node, prevStyles)
  }, [selector]) // eslint-disable-line react-hooks/exhaustive-deps
}

type Props = {
  selector: string
  styles: Styles
}

const ApplyStyles = ({ selector, styles }: Props) => {
  useApplyStyles(selector, styles)
  return null
}

export { ApplyStyles }

export default useApplyStyles
