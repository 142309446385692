import { Currency } from '@local/api'
import { EmployeeValues } from '../pages'
import { convertDateToString } from './date-parsing'
import { TaxationOptionValue } from '../pages/BasicInfo/basic-info-types'
import { formatSsn } from '../pages/BasicInfo/helpers'
import type {
  NewJobAssignment,
  EmploymentTaxType,
  DefaultEarningsId
} from '@local/api'
import { getRandomEmail } from '../pages/BasicInfo/DemographicsCard'
import uniq from 'lodash/uniq'
import { CustomerPosition, Location } from '../types'
import { getSelectedLocationsExportIds } from '../pages/JobsAndPay/JobsAndPayForm'
import { Mapping } from '../AddEmployeeModalController'

type EmployeeValuesToGraphQLType = {
  employee: EmployeeValues
  locations: Location[]
  companyCode: string
  periodsPerYear: number | null
  defaultEarningsId: DefaultEarningsId
  positions: CustomerPosition[]
  mappings: Mapping[]
  isPOS: boolean
}

export const employeeValuestoGraphQL = ({
  employee,
  locations,
  companyCode,
  periodsPerYear,
  defaultEarningsId,
  positions,
  mappings,
  isPOS
}: EmployeeValuesToGraphQLType) => {
  return {
    benefits: {
      acaPlan: employee.acaPlan ?? null,
      benefitPlan: employee.benefitPlan ?? null,
      tafwPolicy: employee.tafwPolicy ?? null,
      washingtonLaI: employee.wli ?? null,
      workersComp: employee.workersComp ?? null
    },
    documents: employee.selectedDocuments
      .filter((d) => d.checked)
      .map((doc) => doc.id.toString()),
    additionalDocuments: employee.additionalDocuments.map((d) => ({
      fileName: d.fileName,
      filePath: d.filePath
    })),
    customDocumentFields: employee.customDocumentFields.map(
      (customDocField) => ({
        value: customDocField.value,
        fieldId: customDocField.fieldId,
        id: customDocField.id
      })
    ),
    employment: {
      employeeNumber: parseInt(employee.employeeNumber),
      employmentTaxType: taxTypeToGraphQL(employee.taxationType),
      hireDate: convertDateToString(new Date(employee.startDate)),
      eeoClassification: employee.eeoClassification
        ? {
            id: parseInt(employee.eeoClassification),
            name: '' // we do not need the name in saving it but this is how graphql should be organized so we have it anyways
          }
        : null,
      defaultEarningsCode: getDefaultCustomerEarningId(
        employee.taxationType,
        employee.isHourly,
        defaultEarningsId
      )
    },
    jobsAndPay: payGroupAssignmentToGraphQL(
      employee,
      periodsPerYear,
      positions,
      filterMappingsByLocationAssigned(mappings, locations)
    ),
    overtimeEligible: employee.overtimeEligible,
    person: {
      chosenName: employee.chosenName,
      email:
        employee.email === ''
          ? getRandomEmail(companyCode, employee.firstName, employee.lastName)
          : employee.email,
      firstName: employee.firstName,
      lastName: employee.lastName,
      phoneNumber: employee.phoneNumber.nationalNumber,
      posAccessCode:
        getSelectedLocationsExportIds(employee.jobs, locations).length > 0 &&
        !isPOS
          ? parseInt(employee.posAccessCode)
          : null,
      ssn:
        employee.socialSecurityNumber === ''
          ? null
          : formatSsn(employee.socialSecurityNumber)
    },
    securityRoleId: employee.securityRole
  }
}

const moneyToGraphQL = (amount: number | null, currency: Currency) => {
  return amount !== null && amount !== undefined
    ? { amount: amount.toString(), currency }
    : null
}

const taxTypeToGraphQL = (
  taxationType: TaxationOptionValue
): EmploymentTaxType => {
  switch (taxationType) {
    case 'W2':
      return 'W2'
    case '1099':
      return '_1099'
    default:
      return 'UNKNOWN'
  }
}

const payGroupAssignmentToGraphQL = (
  employee: EmployeeValues,
  periodsPerYear: number | null,
  positions: Array<CustomerPosition>,
  mappings: Mapping[]
) => {
  const { jobs, salary } = employee
  const wtlPaygroupMap: { [key: string]: string[] } = {}
  const positionPaygroupMap: { [key: string]: string } = {}
  const jobsList: NewJobAssignment[] = []

  jobs.forEach((job) => {
    jobsList.push({
      jobId: job.jobId,
      hourlyRate: moneyToGraphQL(job.wage, 'USD'),
      locationId: job.locationId
    })
    const key = `${job.workTaxLocationId} ${job.payGroupId}`
    if (!wtlPaygroupMap[key]) {
      wtlPaygroupMap[key] = []
    }
    positionPaygroupMap[job.payGroupId] = job.customerPositionId
    wtlPaygroupMap[key].push(job.locationId)
  })

  const payGroupAssignments = Object.keys(wtlPaygroupMap).map((wtlPg) => {
    const str = wtlPg.split(' ')
    const wtl = str[0]
    const payGroupId = str[1]
    const selectedPosition = positionPaygroupMap[payGroupId]

    // there will always be a selected position and there will always be a check code
    const checkCode = positions.find((p) => p.id === selectedPosition)!
      .checkCode.id

    return {
      payGroupId: payGroupId,
      checkCodeId: checkCode,
      positionId: selectedPosition,
      workTaxLocationId: wtl,
      locationIds: uniq(wtlPaygroupMap[wtlPg])
    }
  })

  const salaryPerPayPeriod =
    salary !== null && periodsPerYear !== null
      ? moneyToGraphQL(salary / periodsPerYear, 'USD')
      : null

  return {
    jobs: jobsList,
    payGroupAssignments,
    mappings: mappings,
    salaryRateOfPay: moneyToGraphQL(salary, 'USD'),
    annualSalary: moneyToGraphQL(salary, 'USD'),
    salaryPerPayPeriod: salaryPerPayPeriod
  }
}

const filterMappingsByLocationAssigned = (
  mappings: Mapping[],
  locations: Location[]
) => {
  return mappings.filter((mapping) => {
    return locations.find(
      (location) => location.exportId === mapping.locationGuid
    )
  })
}

/*
    isHourly and W2 -> hourly
    salary and W2 -> salary
    isHourly and 1099 -> contractorHourly
    salary and 1099 -> contractorSalary
  */
const getDefaultCustomerEarningId = (
  taxationType: TaxationOptionValue,
  isHourly: boolean,
  defaultEarningsId: DefaultEarningsId
): string => {
  if (taxationType === 'W2') {
    if (isHourly) {
      return defaultEarningsId.hourly
    }
    return defaultEarningsId.salary
  }
  // if we get to this point that means we definitly have the earning codes for these
  if (isHourly) {
    return defaultEarningsId.contractorHourly!!
  }
  return defaultEarningsId.contractorSalary!!
}
