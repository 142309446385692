import * as React from 'react'
import { AddEmployeeModalApp } from './AddEmployeeModalApp'
import { RehireEmployeeModalApp } from './RehireEmployeeModalApp'
import { AddEmployeeProviders } from './providers/AddEmployeeProviders'
import { PosEmployeeModalApp } from './PosEmployeeModalApp'

type AddProps = {
  isOpen: boolean
  onCancel: () => void
  onSuccess: (employeeId: string | null) => void
  onSuccessButtonLabel?: string
}

const AddEmployeeModalRoot = (props: AddProps) => {
  const { isOpen, onCancel, onSuccess, onSuccessButtonLabel } = props
  return (
    <AddEmployeeProviders>
      <AddEmployeeModalApp
        isOpen={isOpen}
        onCancel={onCancel}
        onSuccess={onSuccess}
        onSuccessButtonLabel={onSuccessButtonLabel}
      />
    </AddEmployeeProviders>
  )
}

type RehireProps = {
  isOpen: boolean
  employeeId: string
  initialRehireDate: string
  onCancel: () => void
  onSuccess: (employeeId: string | null) => void
}

const RehireEmployeeModalRoot = (props: RehireProps) => {
  const { isOpen, onCancel, onSuccess, employeeId, initialRehireDate } = props

  return (
    <AddEmployeeProviders>
      <RehireEmployeeModalApp
        isOpen={isOpen}
        onCancel={onCancel}
        onSuccess={onSuccess}
        employeeId={employeeId}
        initialRehireDate={initialRehireDate}
      />
    </AddEmployeeProviders>
  )
}

type PosProps = {
  isOpen: boolean
  onCancel: () => void
  onSuccess: (employeeId: string | null) => void
  locationGuid: string
  toastUserGuid: string
}

const PosEmployeeModalRoot = (props: PosProps) => {
  const { isOpen, onCancel, onSuccess, locationGuid, toastUserGuid } = props

  return (
    <AddEmployeeProviders>
      <PosEmployeeModalApp
        isOpen={isOpen}
        onCancel={onCancel}
        onSuccess={onSuccess}
        locationGuid={locationGuid}
        toastUserGuid={toastUserGuid}
      />
    </AddEmployeeProviders>
  )
}

export { AddEmployeeModalRoot, RehireEmployeeModalRoot, PosEmployeeModalRoot }
