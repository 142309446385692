import * as React from 'react'
import { FileUtils } from '../utils/FileUtils'
import cx from 'classnames'
import { useFileSelector } from '../FileSelectorProvider/FileSelectorProvider'
import { t } from '../defaultStrings'

/**
 * File selector component properties
 */
export interface FileSelectorContainerProps {
  testId?: string
  dragDropText?: string
}

/**
 * File selector component that allow file selection via a button or drag and drop
 */
export function FileSelectorContainer({
  testId = 'file-selector',
  dragDropText,
  children
}: React.PropsWithChildren<FileSelectorContainerProps>): JSX.Element {
  const {
    accept,
    maxSize,
    multiple,
    disabled,
    files,
    setFiles,
    setInvalidFiles,
    onChange: onChangeFromProvider
  } = useFileSelector()

  const [isDragging, setDragging] = React.useState(false)

  const dragEnterOver = (event: React.DragEvent) => {
    if (!disabled) {
      event.preventDefault()
      event.stopPropagation()
      setDragging(true)
    }
  }

  const fileDrop = (dropEvent: React.DragEvent<HTMLDivElement>) => {
    dropEvent.preventDefault()
    dropEvent.stopPropagation()

    if (!disabled) {
      setDragging(false)

      const validationResult = FileUtils.validateFiles(
        dropEvent.dataTransfer.files,
        accept,
        maxSize,
        multiple
      )

      setInvalidFiles(validationResult.invalidFiles)
      setFiles(validationResult.files)
      onChangeFromProvider?.({
        files: validationResult.files,
        invalidFiles: validationResult.invalidFiles
      })
    }
  }

  return (
    <div
      className={cx(
        'flex flex-col bg-white rounded-md xxl:p-6 p-4 border-2 border-dashed justify-center items-center transition-all gap-8',
        { 'border-primary-75': isDragging }
      )}
      onDragEnter={dragEnterOver}
      onDragLeave={() => setDragging(false)}
      onDragOver={dragEnterOver}
      onDrop={fileDrop}
      data-testid={testId}
    >
      {children}
      {!files.length ? (
        <div data-testid={testId + '-helpertext'}>
          {dragDropText ||
            t('drag-and-drop-files-here', { count: multiple ? 2 : 1 })}
        </div>
      ) : null}
    </div>
  )
}
