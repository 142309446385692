import * as React from 'react'

type Props = {
  linkUrl: string
  linkText: string
}

const LinkButton = (props: Props) => {
  const { linkUrl, linkText } = props

  return (
    <a href={linkUrl} target='_blank' rel='noreferrer'>
      <button className='type-subhead text-link'>{linkText}</button>
    </a>
  )
}
export default LinkButton
