import * as React from 'react'
import cx from 'classnames'

export interface ChipsProps {
  testId?: string | number
  className?: string
  size?: Size
}

type Size = 'sm' | 'xs' | 'xxs'

export const Chips: React.FC<React.PropsWithChildren<ChipsProps>> = ({
  children,
  testId = 'chips',
  className,
  size = 'sm'
}) => {
  return (
    <div
      data-testid={testId}
      className={cx(
        'flex flex-row flex-wrap items-center justify-start',
        {
          'gap-3': size === 'sm',
          'gap-4': size === 'xs' || size === 'xxs'
        },
        className
      )}
      role='group'
    >
      {children}
    </div>
  )
}
