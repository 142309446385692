import * as React from 'react'
import cx from 'classnames'
// We are now using the floating-ui base package to render the wizard template into a portal with focus traps and scroll lock
import {
  PortalManagement,
  Overlay,
  useFloating
} from '@toasttab/buffet-pui-floating-ui-base'
export interface PageProps {
  testId?: string | number
  className?: string
  disableInitialFocus?: boolean
  children: React.ReactNode
}

export const Page = ({
  testId = 'page-layout',
  className,
  /**
   * By default a Page will auto-focus to the first focusable element in the component.
   * But there could be times when you need to disable this behavior and if you find yourself in such
   * a situation, set `disableInitialFocus` to true.
   */
  disableInitialFocus,
  children
}: PageProps) => {
  const { context, refs } = useFloating({ open: true })
  const zIndexClassName = 'z-30' // elevation: z-30 for full screen layouts according to our guidelines

  return (
    <PortalManagement
      context={context}
      isModal={true}
      zIndexClassName={zIndexClassName}
      disableInitialFocus={disableInitialFocus}
    >
      <Overlay
        testId={`overlay-${testId}`}
        lockScroll
        id='modal-overlay'
        className={zIndexClassName}
      >
        <section
          data-testid={testId}
          ref={refs.setFloating}
          className={cx(
            className,
            'flex flex-col min-h-screen pr-0 bg-white md:bg-page overflow-auto lg:overflow-unset pb-20 md:pb-0',
            'fixed inset-0'
          )}
        >
          {children}
        </section>
      </Overlay>
    </PortalManagement>
  )
}
