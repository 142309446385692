import * as React from 'react'
import { useTranslation } from '@local/translations'
import { useFormikContext } from 'formik'
import partition from 'lodash/partition'
import { EmployeeValues } from '../combined-values'
import { CheckboxGroup } from '@toasttab/buffet-pui-checkbox'
import { DividingLine } from '@toasttab/buffet-pui-config-templates'
import { Alert } from '@toasttab/buffet-pui-alerts'
import {
  FileErrorList,
  FileSelectionList,
  FileSelector,
  FileSelectorButton
} from '@toasttab/buffet-pui-file-selector'
import { useAddEmployeeContext } from '../../hooks'
import compact from 'lodash/compact'
import { UnderReviewIllustration } from '@toasttab/buffet-pui-illustrations'
import { useNewHireRequiredDocumentsForAssignedFieldsQuery } from '@local/api'
import { MerryGoRound } from '@toasttab/buffet-pui-loading-indicators'
import { convertDateToString } from '../../helpers'
import { StepKey } from '../../domain/steps'
import { OnboardingDocumentsModal } from './OnboardingDocumentsModal'
import {
  UploadFileDetails,
  uploadDocuments,
  fileIsInList
} from '../../providers/DocumentUploadManager'
import { useMutation } from '@tanstack/react-query'
import { useEffect, useState } from 'react'
import { CustomDocumentField, OnboardingDocument } from './onboarding-types'
import { TextInput } from '../../components'
import intersection from 'lodash/intersection'

type Props = {
  setActiveStep: (step: StepKey) => void
}
const OnboardingDocumentsCard = (props: Props) => {
  const { setActiveStep } = props
  const { setFieldValue, values } = useFormikContext<EmployeeValues>()
  const [successUploadFiles, setSuccessUploadFiles] = useState<
    UploadFileDetails[]
  >([])
  const [errorUploadFiles, setErrorUploadFiles] = useState<UploadFileDetails[]>(
    []
  )
  const [savedCustomDocumentFields, setSavedCustomDocumentFields] = useState<
    CustomDocumentField[]
  >([])

  const { t } = useTranslation()
  const customFieldsArray = values.customDocumentFields

  const {
    selectedDocuments,
    startDate,
    securityRole,
    jobs,
    willProvideEmail,
    additionalDocuments
  } = values

  const [uploadedDocuments, setUploadedDocuments] =
    useState<UploadFileDetails[]>(additionalDocuments)

  const { displayName } = useAddEmployeeContext()

  const documentsQuery = useNewHireRequiredDocumentsForAssignedFieldsQuery({
    variables: {
      request: {
        hireDate: convertDateToString(new Date(startDate)),
        securityRoleId: securityRole,
        workTaxLocationIds: jobs.map((job) => job.workTaxLocationId)
      }
    }
  })

  const uploadDocumentMutation = useMutation({
    mutationFn: (files: File[]) => {
      return uploadDocuments(
        files,
        successUploadFiles.map((successFile) => successFile.file)
      )
    }
  })

  const { data, loading } = documentsQuery
  const apiDocuments = compact(
    data?.newHireRequiredDocumentsForAssignedFields
  ).map(
    (d) =>
      ({
        ...d,
        touched:
          !d.assignedByPosition ||
          selectedDocuments.some((dd) => dd.id === d.id && dd.touched),
        checked: selectedDocuments.some((dd) => dd.id === d.id && dd.checked)
          ? selectedDocuments.some((dd) => dd.id === d.id && dd.checked)
          : !d.assignedByPosition
      } as OnboardingDocument)
  )

  // Sets documents that are not assigned by position to selectedDocuments
  useEffect(() => {
    const updatedDocs = data?.newHireRequiredDocumentsForAssignedFields
    if (updatedDocs === undefined) {
      setFieldValue('selectedDocuments', [])
    } else {
      const docsToShow = updatedDocs
        .map((d) => {
          return {
            ...d,
            touched:
              !d.assignedByPosition ||
              selectedDocuments.some((dd) => dd.id === d.id && dd.touched),
            checked: selectedDocuments.some(
              (dd) => dd.id === d.id && dd.checked
            )
              ? selectedDocuments.some((dd) => dd.id === d.id && dd.checked)
              : !d.assignedByPosition
          } as OnboardingDocument
        })
        .filter((doc) => doc.touched)

      const customFieldsResponseObject =
        data?.requiredDocumentationCustomFieldsV2 || []
      const allCustomFields = customFieldsResponseObject.map((customField) => ({
        ...customField,
        value: ''
      }))
      setSavedCustomDocumentFields(allCustomFields)
      modifyCustomDocumentFields(docsToShow, allCustomFields, true)
      setFieldValue('selectedDocuments', docsToShow)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    data?.newHireRequiredDocumentsForAssignedFields,
    data?.requiredDocumentationCustomFieldsV2
  ])

  const modifyCustomDocumentFields = (
    currentSelectedDocuments: OnboardingDocument[],
    allCustomFields: CustomDocumentField[],
    initialValue?: boolean
  ) => {
    const selectedDocumentIds = currentSelectedDocuments
      .filter((document) => document.checked)
      .map((document) => document.id)
    if (initialValue) {
      //Remember the user's last custom field inputs to keep values when switching between the hire steps
      values.customDocumentFields.forEach((currentInputtedField) => {
        const foundIndex = allCustomFields.findIndex(
          (defaultCustomField) =>
            defaultCustomField.id === currentInputtedField.id
        )
        if (foundIndex !== -1) {
          allCustomFields[foundIndex].value =
            currentInputtedField.value || allCustomFields[foundIndex].value
        }
      })
    }
    const currentCustomFields = allCustomFields.filter(
      (customField) =>
        intersection(selectedDocumentIds, customField.documentIds).length > 0
    )
    setFieldValue('customDocumentFields', [...currentCustomFields])
  }

  const modifySelectedDocuments = (documents: OnboardingDocument[]) => {
    modifyCustomDocumentFields([...documents], savedCustomDocumentFields)
    setFieldValue('selectedDocuments', [...documents])
  }

  const modifySavedCustomDocuments = (id: string, value: string) => {
    const foundIndex = savedCustomDocumentFields.findIndex(
      (savedCustomField) => savedCustomField.id === id
    )
    const tempSavedCustomFields = [...savedCustomDocumentFields]
    if (foundIndex !== -1) {
      tempSavedCustomFields[foundIndex].value = value
    }
    setSavedCustomDocumentFields(tempSavedCustomFields)
  }

  const onClickDocument = (e: React.ChangeEvent<HTMLInputElement>) => {
    const docId = e?.target.value
    const checked = e?.target.checked
    const newSelectedDocuments = [...selectedDocuments]
    newSelectedDocuments.forEach((d) => {
      if (d.id === docId) {
        d.checked = checked
      }
    })
    modifyCustomDocumentFields(newSelectedDocuments, savedCustomDocumentFields)
    setFieldValue('selectedDocuments', newSelectedDocuments)
  }

  const saveSelectedAdditionalDocuments = async (files: File[]) => {
    // files are what is being held by FileSelector

    // for each file returned by the FileSelector, store in S3
    // this returns UploadFileDetails[]
    const data = await uploadDocumentMutation.mutateAsync(files)

    // remove any files that didn't work
    const [errorFiles, successFiles] = partition(
      data,
      (fileResponse) => fileResponse?.error
    )

    // collect all the successfully uploaded files
    const allSuccessUploadFiles = [...successUploadFiles, ...successFiles]

    setErrorUploadFiles(errorFiles)
    setSuccessUploadFiles(allSuccessUploadFiles)

    // get list of added documents, remove any removed documents, map to domain
    const additionalDocs = allSuccessUploadFiles.filter((successFile) =>
      fileIsInList(files, successFile.file)
    )
    setUploadedDocuments(additionalDocs)
    setFieldValue('additionalDocuments', additionalDocs)
  }

  const checkboxGroupOptions = selectedDocuments
    .filter((d) => d.touched)
    .map((doc) => ({
      value: `${doc.id}`,
      label: doc.name,
      checked: doc.checked
    }))

  const documentsBody = () => {
    return apiDocuments.length === 0 ? (
      <div className='mb-4'>{t('noDocuments', { name: displayName })}</div>
    ) : (
      <div className='mb-4'>
        {apiDocuments.length > 0
          ? t('onboardingDocumentsSubHeader', { name: displayName })
          : t('noDocuments', { name: displayName })}
      </div>
    )
  }

  const noDocumentLoadingImage = loading ? (
    <div data-testid='documentsLoading' className='flex justify-center'>
      <MerryGoRound size='lg' />
    </div>
  ) : null

  return (
    <>
      {apiDocuments.length > 0 && !willProvideEmail && (
        <Alert
          variant='warning'
          className='mb-9'
          testId='noEmailDocumentsWarning'
        >
          {t('cantReviewDocsNoEmailWarning', { name: displayName })}
          <button
            className='inline-link-inherit'
            onClick={() => setActiveStep('basic')}
          >
            {t('addEmailOnboarding')}
          </button>
          {t('cantReviewDocsNoEmailWarningCont')}
        </Alert>
      )}
      <div
        className='mb-3 font-bold type-headline-4 text-default'
        data-testid='onboardingDocuments'
      >
        {t('onboardingDocuments')}
      </div>
      <div>
        {noDocumentLoadingImage}
        {documentsBody()}
        {apiDocuments.some((d) => d.assignedByPosition) && (
          <OnboardingDocumentsModal
            allDocuments={apiDocuments}
            openModalButtonLabel={
              apiDocuments.length > 0
                ? t('modifyOnboardingDocuments')
                : t('assignOnboardingDocument')
            }
            modifySelectedDocuments={modifySelectedDocuments}
          />
        )}
        {apiDocuments.length > 0 && (
          <>
            <CheckboxGroup
              testId='requiredDocuments'
              multiple
              className='mb-4 ml-2 checkbox-font-normal'
              onChange={onClickDocument}
              options={checkboxGroupOptions}
            />
          </>
        )}
      </div>
      {customFieldsArray && customFieldsArray.length > 0 && (
        <div>
          <DividingLine />
          <div className='mb-3 font-bold type-headline-4 text-default'>
            {t('customFields')}
          </div>
          <div className='mb-6 text-default'>
            {t('customFieldsSubtext', { name: displayName })}
          </div>
          <div className='flex-wrap lg:flex gap-x-6 lg:items-start'>
            {customFieldsArray.map((customField, i) => (
              <div className='content-end' key={`customDocumentFields${i}`}>
                <TextInput
                  required
                  fieldName={`customDocumentFields[${i}].value`}
                  label={customField.name}
                  onChange={(value) =>
                    modifySavedCustomDocuments(customField.id, value)
                  }
                />
              </div>
            ))}
          </div>
        </div>
      )}
      <div>
        <DividingLine />
        <div className='mb-3 font-bold type-headline-4 text-default'>
          {t('additionalDocuments')}
        </div>
        <div className='mb-4 text-default'>
          {t('additionalDocumentsSubtext', { name: displayName })}
        </div>
        <div className='p-2'>
          {errorUploadFiles.length > 0 && (
            <Alert
              className='w-full'
              title=''
              variant='error'
              testId={'document-upload-fail-alert'}
            >
              {t('additionalDocumentsUploadError')}
              <ul>
                {errorUploadFiles.map((errorFile) => (
                  <li key={errorFile.file.name}>
                    &#8226; {errorFile.file.name}
                  </li>
                ))}
              </ul>
            </Alert>
          )}
        </div>
        <div className='p-2 mb-6'>
          <FileSelector
            multiple
            files={uploadedDocuments.map((f) => f.file)}
            accept='.pdf'
            dragDropText={t('dragAndDropText')}
            onChange={(changes) =>
              saveSelectedAdditionalDocuments(changes.files)
            }
          >
            {successUploadFiles.length === 0 &&
              errorUploadFiles.length === 0 &&
              uploadedDocuments.length === 0 && (
                <UnderReviewIllustration resizeToContainer />
              )}
            <FileSelectorButton helperText={t('uploadFileHelperText')} />
            <FileErrorList />
            <FileSelectionList />
          </FileSelector>
        </div>
      </div>
    </>
  )
}

export { OnboardingDocumentsCard }
