import * as React from 'react'
import { useTranslation } from '@local/translations'
import { IconButton } from '@toasttab/buffet-pui-buttons'
import { EditIcon } from '@toasttab/buffet-pui-icons'
import { StepKey } from '../../domain/steps'
import { EmployeeValues } from '../index'
import { ReadonlyText } from '@toasttab/buffet-pui-readonly'
import { ForkKnifeIcon } from '@toasttab/buffet-pui-icons'
import { DividingLine } from '@toasttab/buffet-pui-config-templates'
import { formatPay } from './helpers'
import { JobsAndPay } from '../../types'

type Props = {
  setActiveStep: (step: StepKey) => void
  employeeValues: EmployeeValues
  jobsAndPay: JobsAndPay
}

const ReviewJobsAndPay = (props: Props) => {
  const { setActiveStep, employeeValues, jobsAndPay } = props
  const { t } = useTranslation()

  const { salary, jobs, isHourly } = employeeValues

  const getLocationFromId = (locationId: string) => {
    return jobsAndPay.locations.find((loc) => loc.id === locationId)?.name
  }

  const getPositionFromId = (positionId: string | null) => {
    return jobsAndPay.customerPositions.find((cp) => cp.id === positionId)?.name
  }

  const getCheckCodeFromPositionId = (positionId: string | null) => {
    return jobsAndPay.customerPositions.find((cp) => cp.id === positionId)
      ?.checkCode.name
  }

  const getPayGroupFromId = (payGroupId: string) => {
    return jobsAndPay.payGroupsV2.find((pg) => pg.id === payGroupId)?.name
  }

  const getJobName = (locationId: string, jobId: string) => {
    const location = jobsAndPay.locations.find((loc) => loc.id === locationId)
    return location?.jobs.find((job) => job.id === jobId)?.name
  }

  const jobsPanels = jobs.map((job, index) => (
    <React.Fragment key={job.jobId}>
      <div className='flex space-x-5'>
        <div className='flex items-center'>
          <ForkKnifeIcon
            accessibility='decorative'
            className='text-secondary'
          />
        </div>
        <ReadonlyText
          label={getJobName(job.locationId, job.jobId)}
          value={
            <div>
              {getLocationFromId(job.locationId)}
              {isHourly ? (
                <div data-testid='hourlyPay'>
                  {formatPay(t('hourAbbr'), job.wage!)}
                </div>
              ) : index === 0 ? (
                <div data-testid='salary'>
                  {formatPay(t('yearAbbr'), salary!)}
                </div>
              ) : null}
              <div>
                {`${getCheckCodeFromPositionId(
                  job.customerPositionId
                )}, ${getPayGroupFromId(job.payGroupId)}`}
              </div>
              {getPositionFromId(job.customerPositionId)}
            </div>
          }
          testId={'reviewPayType'}
        />
      </div>
      {index < jobs.length - 1 ? (
        <div className={'mb-6 md:mb-8'}>
          <DividingLine />
        </div>
      ) : null}
    </React.Fragment>
  ))

  return (
    <>
      <div className={'flex justify-between mb-4 md:mb-8'}>
        <div
          data-testid='jobsAndPay'
          className='font-bold type-headline-5 text-default'
        >
          {t('jobsAndPay')}
        </div>
        <IconButton
          icon={<EditIcon aria-label={'edit jobs and pay'} />}
          onClick={() => setActiveStep('jobsAndPay')}
          cropToIcon
        />
      </div>
      <div>{jobsPanels}</div>
    </>
  )
}

export { ReviewJobsAndPay }
export type { Props }
