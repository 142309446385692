import * as React from 'react'
import { useMemo, useState } from 'react'
import { DemographicsCard } from './DemographicsCard'
import { EmployeeDetailsCard } from './EmployeeDetailsCard'
import { DividingLine } from '@toasttab/buffet-pui-config-templates'
import { useFormik, FormikProvider } from 'formik'
import { getBasicInformationValidationSchema } from './basic-info-types'
import type { BasicInfoValues } from './basic-info-types'
import { useCheckAvailabilityLazyQuery } from '@local/api'
import { useIsMobile, useTranslation } from '../../hooks'
import { useOnDraftChange } from '../../drafts'
import { useAddEmployeeContext } from '../../hooks'
import pickBy from 'lodash/pickBy'
import isEmpty from 'lodash/isEmpty'
import { formatSsn } from './helpers'
import { useSsnContext } from '../../hooks/useSsnManagement'
import { ContentFooter, PageMain } from '@toasttab/buffet-pui-wizard-templates'
import { WizardFooter } from '../../WizardParts/WizardFooter'
import FormErrorAlert from '../../components/FormErrorAlert'
import DesktopFacts, { DesktopFactsItems } from '../../components/DesktopFacts'
import { InfoDisplayIcon } from '@toasttab/buffet-pui-icons'
import { useCompanyCode, useUser } from '@toasttab/ec-session'
import LinkButton from '../../components/LinkButton'

export type Props = {
  employeeId: string | null
  hasInitialSsn: boolean
  isNewHire: boolean
  contractorAvailable: boolean
  ssnRequired: boolean
}

const BasicInformationForm = (props: Props) => {
  const {
    employeeId,
    hasInitialSsn,
    isNewHire,
    contractorAvailable,
    ssnRequired
  } = props
  const { t } = useTranslation()
  const [isLoading, setIsLoading] = useState(false)
  const { savedSsn, isLoading: ssnLoading } = useSsnContext()
  const companyCode = useCompanyCode()
  const user = useUser()
  const { isMobile } = useIsMobile()

  const validationSchema = useMemo(() => {
    return getBasicInformationValidationSchema({
      t,
      hasInitialSsn,
      isNewHire
    })
  }, [t, hasInitialSsn, isNewHire])

  const { employeeValues, onChange, onNext } = useAddEmployeeContext()
  const formik = useFormik<BasicInfoValues>({
    initialValues: employeeValues,
    validationSchema,
    onSubmit: async (data) => {
      setIsLoading(true)
      onChange(data)

      // GraphQL types return values as |undefined, so be extra safe with nulls here
      const { data: availabilityGraphQlResponse } = await checkAvailability()
      const {
        email = null,
        employeeNumber = null,
        ssn = null,
        emailTakenBy = null
      } = availabilityGraphQlResponse?.checkAvailability ?? {}

      const errors = pickBy({
        email:
          emailTakenBy === 'APPLICANT'
            ? data.willProvideEmail && !email && t('emailAlreadyInUseApplicant')
            : data.willProvideEmail && !email && t('emailAlreadyInUse'),
        employeeNumber: !employeeNumber && t('employeeNumberAlreadyInUse'),
        socialSecurityNumber:
          data.willProvideSsn &&
          ssn !== null && // null ssn means not provided so ignore
          !ssn &&
          t('socialSecurityNumberAlreadyInUse')
      })

      if (isEmpty(errors)) {
        onNext()
      } else {
        setIsLoading(false)
        formik.setErrors(errors)
      }
    }
  })

  React.useEffect(() => {
    if (savedSsn && !employeeValues.socialSecurityNumber) {
      formik.setFieldValue('socialSecurityNumber', savedSsn)
    }
  }, [savedSsn]) // eslint-disable-line react-hooks/exhaustive-deps

  useOnDraftChange({ values: formik.values, onChange })

  const [checkAvailability] = useCheckAvailabilityLazyQuery({
    variables: {
      request: {
        ssn: formatSsn(formik.values.socialSecurityNumber),
        email: formik.values.email,
        employeeNumber: parseInt(formik.values.employeeNumber),
        posAccessCode: null,
        employeeId: employeeId
      }
    }
  })

  const submittedWithErrors =
    formik.submitCount > 0 && Object.keys(formik.errors).length > 0

  const basicInfoDesktopFacts: DesktopFactsItems = [
    {
      Icon: InfoDisplayIcon,
      header: t('desktopFactWelcomeHeader'),
      description: (
        <>
          {t('desktopFactWelcomeDescription')}
          <LinkButton
            linkUrl={`https://central.toasttab.com/s/article/Toast-Payroll-New-Hire-Guide`}
            linkText={t('checkOutThisArticle')}
          />
        </>
      )
    }
  ]

  const ssnDesktopFactRequired = {
    Icon: InfoDisplayIcon,
    header: t('desktopFactRequestSsnHeader'),
    description: (
      <>
        {t('desktopFactRequireSsnDescription')}
        {user.isPureUserHRPlusOrAbove ? (
          <LinkButton
            linkUrl={`/mvc/${companyCode}/Company/NewHire/Settings`}
            linkText={t('desktopFactNewHireSettings')}
          />
        ) : (
          t('desktopFactNewHireSettingsInQuotes')
        )}
      </>
    )
  }

  const ssnDesktopFactNotRequired = {
    Icon: InfoDisplayIcon,
    header: t('desktopFactRequestSsnHeader'),
    description: (
      <>
        {t('desktopFactDontRequireSsnDescription')}
        {user.isPureUserHRPlusOrAbove ? (
          <LinkButton
            linkUrl={`/mvc/${companyCode}/Company/NewHire/Settings`}
            linkText={t('desktopFactNewHireSettings')}
          />
        ) : (
          t('desktopFactNewHireSettingsInQuotes')
        )}
      </>
    )
  }

  if (ssnRequired) {
    basicInfoDesktopFacts.splice(1, 0, ssnDesktopFactRequired)
  } else {
    basicInfoDesktopFacts.splice(1, 0, ssnDesktopFactNotRequired)
  }

  return (
    <>
      <PageMain>
        <FormikProvider value={formik}>
          <DemographicsCard
            employeeId={employeeId}
            hasInitialSsn={hasInitialSsn}
            isNewHire={isNewHire}
            ssnLoading={ssnLoading}
            contractorAvailable={contractorAvailable}
            ssnRequired={ssnRequired}
          />
          <div className='mb-4'>
            <DividingLine />
          </div>
          <EmployeeDetailsCard isNewHire={isNewHire} />
          {submittedWithErrors && (
            <FormErrorAlert isFormSubmitting={formik.isSubmitting} />
          )}
          <ContentFooter>
            <WizardFooter
              backDisabled={true}
              onNext={() => formik.handleSubmit()}
              showSubmit={false}
              isInProgress={isLoading}
              trackId='basic-information'
            />
          </ContentFooter>
        </FormikProvider>
      </PageMain>
      {!isMobile && <DesktopFacts items={basicInfoDesktopFacts} />}
    </>
  )
}

export { BasicInformationForm }
