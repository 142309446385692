import * as React from 'react'
import cx from 'classnames'
import { MAIN_CARD_MAX_WIDTH_PX } from '../../constants'

export interface ContentFooterProps {
  testId?: string | number
  className?: string
  fixedBottom?: boolean
  right?: boolean
  children?: React.ReactNode
}

export const ContentFooter = ({
  testId = 'content-footer',
  className,
  fixedBottom = true,
  right = true,
  children
}: ContentFooterProps) => {
  return (
    <div
      data-buffet-bottom-toolbar={String(fixedBottom)}
      data-testid={testId}
      className={cx(
        // elevation: z-10 for sticky headers according to our guidelines
        'fixed bottom-0 justify-evenly box-border z-10 left-0 right-0',
        {
          'lg:absolute mx-auto md:px-10 lg:px-0': fixedBottom,
          'md:static': !fixedBottom,
          'md:justify-end md:right-0': right,
          'md:justify-start': !right
        }
      )}
    >
      <div
        style={{ maxWidth: MAIN_CARD_MAX_WIDTH_PX }}
        className={cx(className, 'flex flex-row border-t lg:mx-px mx-auto')}
      >
        {children}
      </div>
    </div>
  )
}
