import { useState } from 'react'
import { format } from '@toasttab/buffet-pui-date-utilities'
import { Step, StepKey } from './domain/steps'
import { Benefit } from './types'
import {
  BasicInformationForm,
  JobsAndPayForm,
  BenefitsForm,
  DocumentsForm,
  ReviewForm
} from './pages'
import { useTracking } from '@local/track'
import { render } from './helpers'

import { useAddEmployeeContext, useTranslation } from './hooks'
import { SubmittedWithEmailModal } from './Dialogs/SubmittedWithEmailModal'
import { SubmittedWithoutEmailModal } from './Dialogs/SubmittedWithoutEmailModal'
import { RehireSuccessModal } from './Dialogs/RehireSuccessModal'
import { useEcProps } from '@toasttab/ec-banquet-props'
import { useCompanyCode } from '@toasttab/ec-session'
import {
  useSaveNewEmployeeMutation,
  useRehireEmployeeWithChangesMutation
} from '@local/api'
import { employeeValuestoGraphQL } from './helpers/saveEmployeeUtils'
import { ErrorModal } from './Dialogs/ErrorModal'
import { JobsAndPay } from './types'
import { JobsAndPayValues } from './pages/JobsAndPay/jobs-and-pay-types'
import { ApolloError } from '@apollo/client'
import { Mapping } from './AddEmployeeModalController'

type Props = {
  benefits: Benefit[]
  step: Step
  setActiveStep: (step: StepKey) => void
  onSuccess: (employeeId: string | null) => void
  onSuccessButtonLabel?: string
  initialJobsAndPayValues: JobsAndPayValues
  jobsAndPay: JobsAndPay
  employeeUuid: string | null
  hasInitialSsn: boolean
  eeoEnabled: boolean
  isNewHire: boolean
  isPOS: boolean
  ssnRequired: boolean
  numRunPayrollsInThePastYear: number
  mappings: Mapping[]
}

const AddEmployeeFormPages = (props: Props) => {
  const {
    benefits,
    setActiveStep,
    step,
    onSuccess,
    onSuccessButtonLabel,
    jobsAndPay,
    employeeUuid,
    hasInitialSsn,
    isNewHire,
    isPOS,
    eeoEnabled,
    ssnRequired,
    initialJobsAndPayValues,
    numRunPayrollsInThePastYear,
    mappings
  } = props

  const [saveEmployee, { loading }] = useSaveNewEmployeeMutation()
  const [saveRehire] = useRehireEmployeeWithChangesMutation()
  const { employeeValues, onBack } = useAddEmployeeContext()
  const [showErrorModal, setShowErrorModal] = useState<boolean>(false)
  const [employeeId, setEmployeeId] = useState<string | null>(null)
  const [hasNetworkError, setHasNetworkError] = useState<boolean>(false)
  const [error, setError] = useState<string | undefined>('')
  const [showSuccessModal, setShowSuccessModal] = useState(false)

  const { ecNavigate } = useEcProps()

  const companyCode = useCompanyCode()
  const { t } = useTranslation()
  const [isLoading, setIsLoading] = useState(false)
  const { displayName } = useAddEmployeeContext()
  const { track: trackingHook } = useTracking()

  const navigateToEmployeeProfile = () => {
    if (employeeId !== '') {
      ecNavigate(`/${companyCode}/employees/${employeeId}/profile`)
    } else {
      setShowErrorModal(true)
    }
  }
  const gqlValues = () => {
    const periodsPerYear =
      jobsAndPay.customerPositions.find(
        (cp) => cp.id === employeeValues.jobs[0].customerPositionId
      )?.checkCode.periodsPerYear || null

    return employeeValuestoGraphQL({
      employee: employeeValues,
      locations: jobsAndPay.locations,
      companyCode,
      periodsPerYear,
      defaultEarningsId: jobsAndPay.defaultEarningsIds,
      positions: jobsAndPay.customerPositions,
      mappings: mappings,
      isPOS
    })
  }

  const throwError = (apolloError: ApolloError) => {
    const networkError = [apolloError].map((value) => {
      const error = value.message.split(':')[0]
      switch (!!error) {
        case error.includes('EMAIL'):
          setHasNetworkError(true)
          return t('emailAddress')
        case error.includes('SSN'):
          setHasNetworkError(true)
          return t('ssn')
        case error.includes('ACCESS_CODE'):
          setHasNetworkError(true)
          return t('posAccessCode')
        case error.includes('EMPLOYEE_NUMBER'):
          setHasNetworkError(true)
          return t('employeeNumber')
        default:
          return ''
      }
    })
    setError(networkError[0])
    setShowErrorModal(true)
  }

  const submitNewhire = async () => {
    setIsLoading(true)
    try {
      trackingHook('add-employee-submit.hire')
      const { data } = await saveEmployee({
        variables: {
          request: gqlValues()
        }
      })
      const employeeId = data?.saveNewEmployee?.employeeId
      if (employeeId) {
        setEmployeeId(employeeId)
        setShowSuccessModal(true)
      }
    } catch (error) {
      const apolloError = error as ApolloError
      throwError(apolloError)
    } finally {
      setIsLoading(false)
    }
  }

  const submitRehire = async () => {
    if (employeeUuid != null) {
      setIsLoading(true)
      try {
        trackingHook('add-employee-submit.rehire')
        await saveRehire({
          variables: {
            employeeId: employeeUuid as string,
            // eslint-disable-next-line @toasttab/buffet/date-formats
            rehireDate: format(
              new Date(employeeValues.startDate),
              'yyyy-MM-dd'
            ),
            request: gqlValues()
          }
        })
        setShowSuccessModal(true)
      } catch (error) {
        const apolloError = error as ApolloError
        throwError(apolloError)
        setShowErrorModal(true)
      } finally {
        setIsLoading(false)
      }
    } else {
      setShowErrorModal(true)
    }
  }

  const onSubmit = () => {
    isNewHire ? submitNewhire() : submitRehire()
  }

  const displaySuccessModal = () => {
    if (isNewHire) {
      if (employeeValues.willProvideEmail) {
        return (
          <SubmittedWithEmailModal
            onClose={navigateToEmployeeProfile}
            onSubmit={() => onSuccess(employeeId)}
            firstName={displayName}
            primaryButton={onSuccessButtonLabel ?? t('returnToTeamPage')}
          />
        )
      }
      return (
        <SubmittedWithoutEmailModal
          onClose={navigateToEmployeeProfile}
          onSubmit={() => onSuccess(employeeId)}
          firstName={displayName}
          primaryButton={onSuccessButtonLabel ?? t('returnToTeamPage')}
        />
      )
    }
    return (
      <RehireSuccessModal
        onSubmit={() => onSuccess(employeeId)}
        onClose={() => onSuccess(employeeId)}
        firstName={displayName}
      />
    )
  }
  return (
    <>
      {render(() => {
        switch (step.key) {
          case 'basic':
            return (
              <BasicInformationForm
                employeeId={employeeUuid}
                hasInitialSsn={hasInitialSsn}
                isNewHire={isNewHire}
                contractorAvailable={
                  jobsAndPay.defaultEarningsIds?.contractorHourly !== null ||
                  jobsAndPay.defaultEarningsIds?.contractorSalary !== null
                }
                ssnRequired={ssnRequired}
              />
            )
          case 'jobsAndPay':
            return (
              <JobsAndPayForm
                eeoEnabled={eeoEnabled}
                initialValues={initialJobsAndPayValues}
                isNewHire={isNewHire}
                isPOS={isPOS}
                jobsAndPay={jobsAndPay}
                hourlyEnabled={
                  employeeValues.taxationType === 'W2' ||
                  jobsAndPay.defaultEarningsIds?.contractorHourly !== null
                }
                salaryEnabled={
                  employeeValues.taxationType === 'W2' ||
                  jobsAndPay.defaultEarningsIds?.contractorSalary !== null
                }
              />
            )
          case 'benefits':
            return (
              <BenefitsForm
                benefits={benefits}
                numRunPayrollsInThePastYear={numRunPayrollsInThePastYear}
              />
            )
          case 'documents':
            return <DocumentsForm setActiveStep={setActiveStep} />
          case 'review':
            return (
              <ReviewForm
                hasInitialSsn={hasInitialSsn}
                setActiveStep={setActiveStep}
                employeeValues={employeeValues}
                onBack={onBack}
                loading={loading}
                onSubmit={onSubmit}
                benefits={benefits}
                jobsAndPay={jobsAndPay}
                isLoading={isLoading}
                isNewHire={isNewHire}
              />
            )
          default:
            return <div>'{step.key}' step is not implemented...</div>
        }
      })}
      {showErrorModal && (
        <ErrorModal
          errors={hasNetworkError}
          errorMesssage={error}
          onClose={() => {
            setShowErrorModal(false)
            onSubmit()
          }}
          onSubmit={() => {
            setShowErrorModal(false)
          }}
        />
      )}
      {showSuccessModal && displaySuccessModal()}
    </>
  )
}
export type { Props }
export { AddEmployeeFormPages }
