import { CustomerPosition } from '../../types'
import { Job, JobsAndPayValues } from './jobs-and-pay-types'

export const jobPayGroupAlreadyAssigned = (jobs: Job[], selectingJob: Job) => {
  if (!selectingJob.locationId) {
    return false
  }
  const indexOfJob = jobs.findIndex((job) => job.id === selectingJob.id)
  const jobsBeforeSelectingJob = jobs.slice(0, indexOfJob)

  return jobsBeforeSelectingJob.some(
    (job) => job.payGroupId === selectingJob.payGroupId
  )
}

export const jobLocationHasPaygroupAssigned = (
  jobs: Job[],
  selectingJob: Job
) => {
  if (!selectingJob.locationId) {
    return false
  }
  const indexOfJob = jobs.findIndex((job) => job.id === selectingJob.id)
  const jobsBeforeSelectingJob = jobs.slice(0, indexOfJob)

  return jobsBeforeSelectingJob.some(
    (job) => job.locationId === selectingJob.locationId
  )
}

/*
  This function takes the list of all selected jobs and the new job
  and updates the list with the new job. However, we want to make sure
  the workTaxLocation and PayGroup parity is enforced. If a job with the
  same location appears earlier in the list of jobs, we want to update the
  WTL and PG of the "updated Job", add it to the list, and continue on with the code

  Next, we check everything in the list that appears after the updated job. This way we
  can make sure if we update the WTL and/or PG of the "updated job" then all the later
  jobs with the same location will also update their WTL and PG
*/
export const updateJobsList = (
  jobs: Job[],
  updatedJob: Job,
  positionsHash: { [key: string]: CustomerPosition[] }
) => {
  const { payGroupId, workTaxLocationId, customerPositionId, locationId, id } =
    updatedJob
  const jobDataAtLocation = {
    payGroupId,
    workTaxLocationId,
    customerPositionId
  }
  const indexOfJob = jobs.findIndex((j) => j.id === id)
  const prevPaygroups: { [key: string]: string } = {}

  return jobs.map((job, index) => {
    if (index < indexOfJob) {
      if (job.locationId === locationId) {
        jobDataAtLocation.payGroupId = job.payGroupId
        jobDataAtLocation.workTaxLocationId = job.workTaxLocationId
        jobDataAtLocation.customerPositionId = job.customerPositionId
      } else if (job.payGroupId === payGroupId) {
        jobDataAtLocation.workTaxLocationId = job.workTaxLocationId
        jobDataAtLocation.customerPositionId = job.customerPositionId
      }
      prevPaygroups[job.payGroupId] = job.customerPositionId
    } else if (job.id === id) {
      let newPosition = jobDataAtLocation.customerPositionId
      if (positionsHash[jobDataAtLocation.payGroupId]?.length === 1) {
        newPosition = positionsHash[jobDataAtLocation.payGroupId][0].id
      } else if (jobDataAtLocation.payGroupId in prevPaygroups) {
        newPosition = prevPaygroups[jobDataAtLocation.payGroupId]
      } else {
        newPosition = jobDataAtLocation.customerPositionId
      }
      return {
        ...updatedJob,
        payGroupId: jobDataAtLocation.payGroupId,
        workTaxLocationId: jobDataAtLocation.workTaxLocationId,
        customerPositionId: newPosition
      }
    } else {
      if (job.locationId === locationId) {
        job.payGroupId = jobDataAtLocation.payGroupId
        job.customerPositionId = jobDataAtLocation.customerPositionId
        job.workTaxLocationId = jobDataAtLocation.workTaxLocationId
      }
      if (job.payGroupId === payGroupId) {
        job.workTaxLocationId = workTaxLocationId
        job.customerPositionId = customerPositionId
      }
    }
    return job
  })
}

/*
  To determine if we want to show the refresh data button for rehires
  we want to know if there has been any changes in data and show the button.
  We decided to check everything except security role
*/
export const jobsAndPayValuesChanged = (
  initial: JobsAndPayValues,
  current: JobsAndPayValues
): boolean => {
  if (
    initial.isHourly !== current.isHourly ||
    initial.salary !== current.salary ||
    initial.overtimeEligible !== current.overtimeEligible ||
    initial.jobs.length !== current.jobs.length
  ) {
    return true
  }

  for (let i = 0; i < initial.jobs.length; i++) {
    const jobA = initial.jobs[i]
    const jobB = current.jobs[i]
    if (compareJobsDifferent(jobA, jobB)) {
      return true
    }
  }

  return false
}

const compareJobsDifferent = (jobA: Job, jobB: Job) => {
  return (
    jobA.jobId !== jobB.jobId ||
    jobA.locationId !== jobB.locationId ||
    jobA.payGroupId !== jobB.payGroupId ||
    jobA.workTaxLocationId !== jobB.workTaxLocationId ||
    jobA.wage !== jobB.wage ||
    jobA.customerPositionId !== jobB.customerPositionId
  )
}
