import React from 'react'
import { IconButton, IconButtonProps } from '@toasttab/buffet-pui-buttons'
import { CloseIcon } from '@toasttab/buffet-pui-icons'

const defaultElement = 'button'

export type WizardCloseProps<
  TElementType extends React.ElementType = typeof defaultElement
> = Omit<IconButtonProps<TElementType>, 'icon' | 'aria-label'> & {
  as?: TElementType
  /**
   * An accessible label assigned to the button, when the icon cannot be as
   * descriptive.
   */
  label?: string
}

export function WizardClose<
  TElementType extends React.ElementType = typeof defaultElement
>({
  testId = 'wizard-close',
  label = 'Close',
  ...restProps
}: WizardCloseProps<TElementType>) {
  return (
    <IconButton
      as={defaultElement}
      // Default, but will be overridden by `restProps`
      {...(restProps as IconButtonProps<TElementType>)}
      data-testid={testId}
      aria-label={label}
      iconColor='secondary'
      icon={<CloseIcon accessibility='decorative' />}
    />
  )
}
