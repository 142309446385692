import React from 'react'
import cx from 'classnames'
import { LinearProgressBar } from '@toasttab/buffet-pui-progress-indicators'
import { ToastLogo } from '@toasttab/buffet-pui-toast-logo'
import { WizardClose } from '../WizardClose'

// The link is used to navigate users after clicking the Toast logo
export interface LogoRelativeLink {
  link: string
  label: string
}

export interface HeaderProps {
  testId?: string | number
  containerClassName?: string
  /**
   * @deprecated Please use `title` instead.
   */
  restaurantName?: string
  title?: string
  logoRelativeLink?: LogoRelativeLink
  icon?: React.ReactNode
  progressPercent?: number
  onRequestClose?: () => void
  /**
   * Use instead of onRequestClose
   * to fully control the close behavior,
   * for example, to use react-router-dom, Link component:
   * `wizardClose={<WizardClose as={Link} to='/foo/bar' />}`
   */
  wizardClose?: React.ReactNode
}

export const Header = ({
  testId = 'page-header',
  containerClassName,
  // eslint-disable-next-line
  restaurantName,
  // eslint-disable-next-line
  title = restaurantName,
  logoRelativeLink,
  icon,
  progressPercent,
  onRequestClose,
  wizardClose = onRequestClose === undefined ? (
    <DefaultWizardClose />
  ) : (
    <WizardClose as='button' onClick={onRequestClose} />
  )
}: HeaderProps) => {
  return (
    <div
      data-testid={testId}
      className={cx(
        containerClassName,
        // elevation: z-10 for sticky headers according to our guidelines
        'fixed top-0 z-10 flex flex-col w-full'
      )}
    >
      <header className='relative flex items-center justify-between type-default'>
        <div
          className={cx(
            'flex items-center w-full truncate h-12 md:h-16 md:shadow-none',
            {
              'shadow-md': !!!progressPercent
            }
          )}
        >
          {!!logoRelativeLink && (
            <a
              href={logoRelativeLink.link}
              aria-label={logoRelativeLink.label}
              className='flex-shrink-0 block w-12 h-12 md:h-16 md:w-16'
            >
              <ToastLogo
                size='lg'
                className='object-contain h-auto'
                strokeColor='text-white'
                fillColor='bg-brand-50'
                testId='toast-logo'
              />
            </a>
          )}
          <div className='flex items-center w-full h-full bg-gray-0 md:bg-page md:border-b'>
            <div
              data-testid={`${testId}-icon`}
              className='ml-4 md:ml-10 text-secondary h-6 -mt-px'
            >
              {icon}
            </div>
            <div
              data-testid={`${testId}-restaurant-name`}
              className='ml-2 mr-24 truncate md:block text-default'
            >
              {title}
            </div>
            <div className='absolute right-0 md:mr-6'>{wizardClose}</div>
          </div>
        </div>
      </header>
      {progressPercent && (
        <LinearProgressBar
          testId='wizard-progress-bar'
          progressPercent={progressPercent}
          flush
        />
      )}
    </div>
  )
}

const DefaultWizardClose = () => (
  <WizardClose as='a' href='/restaurants/admin/home' />
)
