import React from 'react'
import { useUniqueId } from '@toasttab/buffet-utils'
import { HelperText } from '@toasttab/buffet-pui-text-base'
import { AddIcon } from '@toasttab/buffet-pui-icons'
import {
  DashBorderButtonContent,
  useDashBorderButtonStyles
} from '../DashBorderButtonContent'

import { HelperTextProps } from '@toasttab/buffet-pui-text-base'

export type Size =
  | 'lg'
  | 'base' // deprecated in favor of 'lg'
  | 'sm'
  | 'auto'

export interface DashBorderButtonProps
  extends HelperTextProps,
    React.ButtonHTMLAttributes<HTMLButtonElement> {
  /** In case you want to turn off or customize the + icon that is added by default */
  iconLeft?: React.ReactNode
  /** Sizes match the standard buffet-pui-button components */
  size?: Size
  /** Whether or not the button is in a loading state */
  loading?: boolean
  /** Optional loading label (e.g 50% done) */
  loadingLabel?: string | React.ReactNode
  /** Apply a className to the root element. For example, adding 'mt-4' will add 16px of top margin; adding 'fs-unmask' will ummask the label and button text for full story. This should not be used to change the visual design of this component. */
  containerClassName?: string
  /** Removes background color when enabled  */
  noFill?: boolean
  /** The button contents */
  children: React.ReactNode
}

export const DashBorderButton = React.forwardRef<
  HTMLButtonElement,
  DashBorderButtonProps
>(
  (
    {
      children,
      disabled,
      loading,
      loadingLabel = 'Uploading...',
      invalid,
      helperText,
      errorText,
      onClick,
      className,
      containerClassName,
      noFill,
      preserveHelpSpace,
      type = 'button',
      size = 'auto',
      testId,
      iconLeft = <AddIcon accessibility='decorative' />,
      ...props
    },
    ref
  ) => {
    testId = useUniqueId(testId, 'dash-border-button-')

    return (
      <div className={containerClassName}>
        <button
          ref={ref}
          onClick={onClick}
          className={useDashBorderButtonStyles({
            className,
            iconLeft,
            size,
            invalid,
            disabled,
            loading,
            noFill
          })}
          disabled={disabled}
          data-testid={testId}
          type={type}
          {...props}
        >
          <DashBorderButtonContent
            iconLeft={iconLeft}
            loadingLabel={loadingLabel}
            loading={loading}
            testId={testId}
          >
            {children}
          </DashBorderButtonContent>
        </button>
        <HelperText
          testId={`${testId}-helper-text`}
          disabled={disabled}
          invalid={invalid}
          errorText={errorText}
          helperText={helperText}
          preserveHelpSpace={preserveHelpSpace}
        />
      </div>
    )
  }
)
