import { CountryIsoCode } from '@toasttab/buffet-pui-country-utilities'

export type PhoneNumberFormats = (country: CountryIsoCode) => {
  placeholder?: string
  format: (num: string) => string
  phoneNumberMinLength: (num: string) => number
  makeNationalNumberIntlCompatible?: (num: string) => string
  makeNationalNumberLocal?: (num: string) => string
}

/* startsWithNumber - utility to use a regular expression to match phone number strings
 * @param str {string} - A string representing the typed phone number
 * @param regex {string} - A regular expression to evaluate the number against
 * @returns {boolean} - Boolean representing whether string starts with a value matching the regular expression
 */
function startsWithNumber(num: string, regex: RegExp) {
  return regex.test(num)
}

export const phoneNumberFormatsByCountry: PhoneNumberFormats = (country) => {
  switch (country) {
    case 'US':
      return {
        placeholder: '(123) 456-7890',
        format: (num: string) => {
          return num.length > 6 ? '(###) ###-####' : '(###) #######'
        },
        phoneNumberMinLength: () => 10
      }
    case 'IE':
      return {
        placeholder: '086 123 4567',
        format: (num: string) => {
          const defaultFormat = '### ### ####'
          if (!num) {
            return defaultFormat
          }
          if (num.startsWith('0818') || num.startsWith('18')) {
            return '#### ### ###'
          }
          if (startsWithNumber(num, /^0[1-6]|9/)) {
            // Special cases for Ireland landlines starting 0X but not 07 or 08
            if (num.startsWith('01')) {
              return '## ### ####'
            }
            if (
              num.startsWith('0402') ||
              num.startsWith('0404') ||
              num.startsWith('0504') ||
              num.startsWith('0505')
            ) {
              return '#### ######'
            }
            if (num.length === 8) {
              return '### ######'
            }
            return '### ### ####'
          }
          if (!num.startsWith('0')) {
            return '## ### ####'
          }
          return defaultFormat
        },
        phoneNumberMinLength: (num) => {
          if (num.startsWith('01')) {
            return 8
          }
          if (num.startsWith('0')) {
            return 10
          }
          return 9
        },
        makeNationalNumberIntlCompatible: (num) => {
          if (num.startsWith('0')) {
            return num.slice(1)
          }
          return num
        },
        makeNationalNumberLocal: (num) =>
          num.startsWith('0') ? num : `0${num}`
      }
    case 'GB':
      return {
        placeholder: '0777 123 4567',
        format: (num) => {
          const defaultFormat = '#### ### ####'
          if (!num.startsWith('0')) {
            return '### ### ####'
          }
          return defaultFormat
        },
        phoneNumberMinLength: (num) => {
          if (num.startsWith('0')) {
            return 10
          }
          return 9
        },
        makeNationalNumberIntlCompatible: (num) => {
          if (num.startsWith('0')) {
            return num.slice(1)
          }
          return num
        },
        makeNationalNumberLocal: (num) =>
          num.startsWith('0') ? num : `0${num}`
      }
    case 'CA':
      return {
        placeholder: '403 123 1234',
        format: () => '### ### ####',
        phoneNumberMinLength: () => 10
      }
    default:
      return {
        placeholder: undefined,
        format: () => '',
        phoneNumberMinLength: () => 4 // Global minimum
      }
  }
}
