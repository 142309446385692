import { Yup } from '../../components'
// eslint-disable-next-line @toasttab/buffet/external-date-libraries
import { differenceInCalendarMonths } from 'date-fns'
import type { GetSchemaArgs, T } from '../../types'
import map from 'lodash/map'
import { strictPhoneNumberValidation } from '@toasttab/buffet-pui-phone-input'
import { isValidEmail } from '@toasttab/email-validation'

const EMPLOYEE_NUMBER_REGEX = /^[0-9]{4,8}$/
const POS_ACCESS_CODE_REGEX = /^[0-9]{4,8}$/
// the number input where the user inputs the SSN outputs as a 10 digit number with no dashes so we don't want to check dashes in the regex
const SOCIAL_SECURITY_REGEX =
  /^(?!000|666|9|123456789|987654321)(?!.*(\d)\1{7})(\d{3}(?!00)\d{2}(?!0000)\d{4})$/
const ISO8859_1_SINGLE_BYTE_REGEX = /^[\x20-\x7E\xA0-\xFF]+$/ // also support special characters in names for the international audience
const CHOSEN_NAME_REGEX =
  /^[ a-zA-ZÀ-ÖØ-öø-ſ0-9!?@#$&()/~*%^`;:.+,"'|=_[\]<>{}\-\\]*$/

type Args = {
  t: T
  hasInitialSsn: boolean
  isNewHire: boolean
}

const TAXATION_OPTIONS = [
  { labelKey: 'employeeW2', value: 'W2' },
  { labelKey: 'contractor1099', value: '1099' }
] as const

type TaxationOptions = typeof TAXATION_OPTIONS

type TaxationOptionValue = TaxationOptions[number]['value']

const TAXATION_OPTION_VALUES: TaxationOptionValue[] = map(
  TAXATION_OPTIONS,
  'value'
)

type PhoneNumber = {
  countryCode: string
  countryIsoCode: string
  nationalNumber: string
}

type BasicInfoValues = {
  firstName: string
  lastName: string
  chosenName: string
  email: string
  phoneNumber: PhoneNumber
  socialSecurityNumber: string
  startDate: string
  employeeNumber: string
  taxationType: TaxationOptionValue
  willProvideSsn: boolean
  willProvideEmail: boolean
}

type InitialValueArgs = {
  basicInfo: BasicInfoValues
}

const getInitialBasicInfoValues = (args: InitialValueArgs): BasicInfoValues => {
  const { email, firstName, lastName } = args.basicInfo

  return {
    firstName: firstName ? firstName : '',
    lastName: lastName ? lastName : '',
    chosenName: '',
    email: email ? email : '',
    phoneNumber: {
      countryCode: '1',
      countryIsoCode: 'US',
      nationalNumber: ''
    },
    socialSecurityNumber: '',
    startDate: new Date().toString(),
    employeeNumber: '',
    taxationType: 'W2',
    willProvideSsn: true,
    willProvideEmail: true
  } as const
}

const isWithinSixMonths = ({ t }: GetSchemaArgs) => {
  return Yup.date()
    .required('Start Date is required')
    .test(
      'isWithinSixMonths',
      t('startDateSixMonths'),
      (value): value is Date => {
        if (value) {
          const withinSixMonths = differenceInCalendarMonths(
            new Date(value),
            new Date()
          )
          return withinSixMonths < 7
        }
        return false
      }
    )
}

const isEmployeeNumber = ({ t }: GetSchemaArgs) => {
  return Yup.string()
    .required(t('employeeNumberRequired'))
    .matches(EMPLOYEE_NUMBER_REGEX, t('employeeNumberRegexFailed'))
}

const getBasicInformationValidationSchema = (args: Args) => {
  const { t, hasInitialSsn, isNewHire } = args

  const ssnValidationWithInitialSsn = Yup.string().when('willProvideSsn', {
    is: true,
    then: (schema) =>
      schema
        .test(
          'no-leading-9',
          t('itinNotAccepted'),
          (value) => !value || !value.startsWith('9') // tests gets run but value can be null for rehires
        )
        .matches(SOCIAL_SECURITY_REGEX, t('socialSecurityNumberRegexFailed'))
  })

  const ssnValidationWithoutInitialSsn = Yup.string().when('willProvideSsn', {
    is: true,
    then: (schema) =>
      schema
        .required(t('socialSecurityNumberRequired'))
        .test(
          'no-leading-9',
          t('itinNotAccepted'),
          (value) => value != null && !value.startsWith('9')
        )
        .matches(SOCIAL_SECURITY_REGEX, t('socialSecurityNumberRegexFailed'))
  })

  return Yup.object({
    firstName: Yup.string()
      .ensure()
      .required(t('firstNameRequired'))
      .matches(ISO8859_1_SINGLE_BYTE_REGEX, t('firstNameRegexFailed')),
    lastName: Yup.string()
      .ensure()
      .required(t('lastNameRequired'))
      .matches(ISO8859_1_SINGLE_BYTE_REGEX, t('lastNameRegexFailed')),
    chosenName: Yup.string().matches(
      CHOSEN_NAME_REGEX,
      t('chosenNameRegexFailed')
    ),
    willProvideEmail: Yup.boolean(),
    email: isNewHire
      ? Yup.string().when('willProvideEmail', {
          is: true,
          then: (schema) =>
            schema
              .required(t('emailAddressRequired'))
              .test('isEmailValid', t('emailAddressInvalid'), (value) =>
                isValidEmail(value)
              )
              .test(
                'isNotExampleEmail',
                t('emailAddressInvalid'),
                (value) =>
                  value !== undefined && !value.includes('@example.com')
              )
        })
      : Yup.string().when('willProvideEmail', {
          is: true,
          then: (schema) =>
            schema
              .required(t('emailAddressRequired'))
              .email(t('emailAddressInvalid'))
        }),
    phoneNumber: Yup.object().when({
      is: (phoneNumber: PhoneNumber) => !!phoneNumber.nationalNumber,
      then: () => strictPhoneNumberValidation
    }),
    willProvideSsn: Yup.boolean(),
    socialSecurityNumber: hasInitialSsn
      ? ssnValidationWithInitialSsn
      : ssnValidationWithoutInitialSsn,
    startDate: isWithinSixMonths({ t }),
    employeeNumber: isNewHire
      ? isEmployeeNumber({ t })
      : Yup.string().required(t('employeeNumberRequired')),
    taxationType: Yup.string()
      .oneOf(TAXATION_OPTION_VALUES)
      .required(t('taxationTypeRequired'))
  })
}

export type { BasicInfoValues, TaxationOptionValue }

export {
  getBasicInformationValidationSchema,
  getInitialBasicInfoValues,
  TAXATION_OPTIONS,
  TAXATION_OPTION_VALUES,
  SOCIAL_SECURITY_REGEX,
  POS_ACCESS_CODE_REGEX,
  EMPLOYEE_NUMBER_REGEX,
  ISO8859_1_SINGLE_BYTE_REGEX,
  CHOSEN_NAME_REGEX
}
